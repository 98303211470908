.container-loader {
  position: absolute;
  z-index: 1;
  left: calc(50% - 131px);
  top: calc(50% - 102px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-loader h1 {
  margin-bottom: 40px;
  font-size: 50px;
  color: #fff;
}

form.container-formulario-pagamento-aluno {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-formulario-pagamento-aluno .titulo {
  width: 100%;
}

form.container-formulario-pagamento-aluno .titulo h2 {
  text-align: center;
  margin-bottom: 15px;
}

form.container-formulario-pagamento-aluno .titulo .informacoes {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

form.container-formulario-pagamento-aluno .titulo .detalhes-plano {
  margin-top: 20px;
}

form.container-formulario-pagamento-aluno .titulo .detalhes-plano p {
  margin: auto;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

form.container-formulario-pagamento-aluno .titulo div:last-child {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 8%;
  margin-top: 10px;
}

form.container-formulario-pagamento-aluno .titulo div:last-child .logo-cartao {
  height: 35px;
  width: 60px;
  margin: 3px;
}

form.container-formulario-pagamento-aluno .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

form.container-formulario-pagamento-aluno .container-inputs label {
  width: 100%;
  margin-top: 20px;
}

form.container-formulario-pagamento-aluno .container-inputs label span {
  color: red;
}

form.container-formulario-pagamento-aluno .container-inputs label input, 
form.container-formulario-pagamento-aluno .container-inputs label select {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-formulario-pagamento-aluno .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-formulario-pagamento-aluno .container-inputs .aviso-erro-pagamento {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-compra {
  animation-duration: 0.2s;
}

.alert-compra .react-confirm-alert-body {
  color: #1c2732;
}

.alert-compra button {
  font-size: 14px;
  font-weight: bold;
}

.alert-compra button.botao-compra {
  background-color: #0466db;
}

@media (max-width: 950px) {
  form.container-formulario-pagamento-aluno {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  form.container-formulario-pagamento-aluno {
    width: 98%;
  }
  form.container-formulario-pagamento-aluno .titulo p,
  form.container-formulario-pagamento-aluno .titulo div:last-child,
  form.container-formulario-pagamento-aluno .container-inputs {
    padding: 0;
  }
}