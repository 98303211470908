div.accordion-item-diferenciais {
  margin-bottom: 10px;
  text-align: start;
}

div.accordion-item-diferenciais .accordion-titulo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

div.accordion-item-diferenciais .accordion-titulo:hover {
  filter: brightness(140%);
}

div.accordion-item-diferenciais .accordion-titulo .icone-accordion {
  height: 30px;
  width: 30px;
  color: #0466db;
}

div.accordion-item-diferenciais .accordion-titulo p {
  margin-left: 10px;
  font-weight: bold;
  color: #aeb0b3;
  font-size: 16px;
  font-style: italic;
  text-align: justify;
}

div.accordion-item-diferenciais .accordion-conteudo {
  color: #aeb0b3;
  margin: 0 0 15px 40px;
}