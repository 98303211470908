div.container-quadro-trilhas-conhecimento {
  position: relative;
  margin: 0 8%;
  padding: 45px;
  background-color: #071b31;
  border: 1px solid gray;
  border-radius: 20px;
  z-index: 2;
}

div.container-quadro-trilhas-conhecimento .quadros {
  display: flex;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro {
  background-color: #222c34;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 15px;
  text-decoration: none;
  flex: 1;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro:hover {
  filter: brightness(140%);
}

div.container-quadro-trilhas-conhecimento .quadros .quadro:not(.quadro:last-child) {
  margin-right: 30px;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro .titulo-quadro {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro .titulo-quadro .icone-titulo {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  color: black;
  margin-right: 5px;
}

@media (max-width: 750px) {
  div.container-quadro-trilhas-conhecimento {
    padding: 45px 1%;
  }
  div.container-quadro-trilhas-conhecimento .quadros {
    flex-direction: column;
  }
  div.container-quadro-trilhas-conhecimento .quadros .quadro {
    width: 100%;
  }
  div.container-quadro-trilhas-conhecimento .quadros .quadro:not(.quadro:last-child) {
    margin: 0 0 10px 0;
  }
}