div.container-detalhes-assistir {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-detalhes-assistir img.logo-whatsapp {
  bottom: 40px;
}

div.container-detalhes-assistir img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 100vh;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-detalhes-assistir button:hover {
  filter: brightness(140%);
}

div.container-detalhes-assistir .container-banner-curso {
  position: relative;
  z-index: 2;
}

div.container-detalhes-assistir .container-banner-curso img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

div.container-detalhes-assistir .container-banner-curso h1 {
  position: absolute;
  top: calc(40% + 120px);
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  text-align: center;
}

div.container-detalhes-assistir .container-assine-agora {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 8% 0 8%;
  background-color: black;
  color: #fff;
  padding: 30px 20px;
  z-index: 2;
}

div.container-detalhes-assistir .container-assine-agora p {
  font-size: 18px;
}

div.container-detalhes-assistir .container-assine-agora p:first-child {
  width: 50%;
  font-weight: bold;
}

div.container-detalhes-assistir .container-assine-agora p.preco i {
  font-style: normal;
  color: #0466db;
}

div.container-detalhes-assistir .container-assine-agora button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-detalhes-assistir .container-assine-agora button:hover {
  filter: brightness(140%);
}

div.container-detalhes-assistir .container-assine-agora button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-detalhes-assistir .container-progresso {
  display: flex;
  justify-content: flex-end;
  padding: 0 8%;
  margin: 10px 0;
}

div.container-detalhes-assistir .container-progresso .quadro {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

div.container-detalhes-assistir .container-progresso .quadro p {
  font-size: 16px;
}

div.container-detalhes-assistir .container-progresso .quadro .progresso {
  height: 50px;
  width: 50px;
}

div.container-detalhes-assistir .container-progresso .quadro .progresso .CircularProgressbar-text {
  font-size: 30px;
  font-weight: bold;
  fill: #fff;
}

div.container-detalhes-assistir .container-progresso .quadro .progresso .CircularProgressbar-path {
  stroke: #008282;
}

div.container-detalhes-assistir .container-abas-aulas {
  position: relative;
  z-index: 2;
  padding: 0 8%;
  min-height: calc(100vh - 640px);
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas {
  display: flex;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas .aba-selecionada {
  background-color: #0d78f2;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas div {
  width: 200px;
  text-align: center;
  background-color: #071b31;
  border: 1px solid gray;
  padding: 10px 0;
  cursor: pointer;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas div:hover {
  text-decoration: underline;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas div p {
  font-weight: bold;
  font-size: 18px;
}

div.container-detalhes-assistir .container-conteudo-abas {
  background-color: #071b31;
  border: 1px solid gray;
  padding: 20px;
}

div.container-detalhes-assistir .container-conteudo-abas p.titulo-secao {
  font-size: 18px;
  font-weight: bold;
  margin: 30px 0 15px 0;
}

div.container-detalhes-assistir .container-conteudo-abas .container-card-descricao {
  background-color: #1d2936;
  padding: 18px 25px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 860px) {
  div.container-detalhes-assistir .container-assine-agora {
    flex-direction: column;
    text-align: center;
  }
  div.container-detalhes-assistir .container-assine-agora p:first-child {
    width: 100%;
  }
  div.container-detalhes-assistir .container-assine-agora p.preco {
    margin: 15px;
  }
}

@media (max-width: 640px) {
  div.container-detalhes-assistir .container-banner-curso h1 {
    top: calc(40% + 100px);
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  div.container-detalhes-assistir img.background {
    height: 340px;
    width: 340px;
  }
}

@media (max-width: 420px) {
  div.container-detalhes-assistir .container-assine-agora {
    margin: 40px 10px;
  }
  div.container-detalhes-assistir .container-abas-aulas {
    padding: 0 10px;
  }
  div.container-detalhes-assistir .container-conteudo-abas {
    padding: 20px 10px;
  }
}