div.container-carousel-cards-cursos {
  display: flex;
  margin: 0 3%;
}

div.container-carousel-cards-cursos .btn-controle-carousel {
  background-color: transparent;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div.container-carousel-cards-cursos .icone-controle-carousel {
  height: 30px;
  width: 30px;
  color: white;
}

div.container-carousel-cards-cursos .react-multi-carousel-list {
  flex: 1;
}

div.container-carousel-cards-cursos .react-multi-carousel-item {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}