div.header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2% 0 8%;
  height: 80px;
  z-index: 10;
  background-color: #000f20;
  border-bottom: 1px solid white;
}

div.header a {
  text-decoration: none;
  color: white
}

div.header .menu-icon {
  width: 45px;
  height: 45px;
  display: none;
}

div.header .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8%;
}

div.header .logo-container a img {
  height: 60px;
  width: 145px;
}

div.header .nav {
  display: flex;
  flex: 1;
  margin-top: 5px;
}

div.header .nav-options {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

div.header .option {
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

div.header .option:hover {
  border-bottom: 3px solid #0071f2;
}

div.header .activeLink {
  border-bottom: 6px solid #0071f2;
}

div.header .container-submenu {
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

div.header .container-submenu .btn-submenu {
  display: flex;
  align-items: center;
}

div.header .container-submenu .btn-submenu img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

div.header .container-submenu .btn-submenu p {
  margin-left: 5px;
}

div.header .icone-submenu {
  height: 25px;
  width: 25px;
  color: #0071f2;
}

div.header .container-submenu .submenu.fechado {
  display: none;
}

div.header .container-submenu .submenu.aberto {
  position: absolute;
  top: 65px;
  background-color: #000f20;
  border: 1px solid gray;
  border-radius: 5px;
}

div.header .container-submenu .submenu.aberto .opcao-submenu {
  display: flex;
  align-items: center;
  padding: 10px;
}

div.header .container-submenu .submenu.aberto .opcao-submenu:hover {
  background-color: #0071f28e;
}

div.header .container-submenu .submenu.aberto .opcao-submenu .icone-opcoes-submenu {
  height: 22px;
  width: 22px;
  margin-right: 5px;
  color: #fff;
}

div.header .container-submenu .submenu.aberto hr.divisoria-submenu {
  width: 90%;
  margin: 0 auto;
  color: gray;
}

div.header .opcao-desconectar {
  color: red;
}

@media (max-width: 980px) {
  div.header {
    padding: 0px 10px;
  }
  div.header .logo-container {
    margin-right: 2%;
  }
}

@media (max-width: 920px) {
  div.header .logo {
    width: 45px;
    height: 45px;
  }
  div.header .nav-options {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 230px;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
  }
  div.header .nav-options.active {
    background-color: #071b31;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }
  div.header .menu-icon {
    width: 45px;
    height: 45px;
    display: block;
    color: white;
  }
  div.header .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  div.header .activeLink {
    border-bottom: 3px solid #0071f2;
  }
  div.header .container-submenu .btn-submenu {
    margin-bottom: 10px;
  }
  div.header .container-submenu .submenu.aberto {
    top: 230px;
    border-top: none;
    border-radius: 0 0 5px 5px;
  }
}