div.container-cards-cursos {
  position: relative;
  border-radius: 10px;
  height: 270px;
  width: 220px;
  overflow: hidden;
}

div.container-cards-cursos img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

div.container-cards-cursos .numero-trilha-conhecimento {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 0 10px 10px 10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #0071f2;
}

div.container-cards-cursos .tag-curso {
  position: absolute;
  top: 40px;
  right: -50px;
  width: 100%;
  padding: 5px 0;
  opacity: 0.9;
  transform: rotate(45deg);
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

div.container-cards-cursos .acoes {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50% 12%;
  opacity: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.363);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
}

div.container-cards-cursos .acoes:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

div.container-cards-cursos .acoes:hover+.nome-curso {
  opacity: 0;
}

div.container-cards-cursos .acoes .btn-detalhes {
  width: 100%;
  background-color: white;
  border: 2px solid #0071f2;
  border-radius: 10px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  color: #0071f2;
}

div.container-cards-cursos .acoes .btn-detalhes:hover {
  filter: brightness(140%);
}

div.container-cards-cursos .nome-curso {
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: #fff;
  color: #0d78f2;
  font-weight: bold;
  padding: 8px 12px;
  max-width: 95%;
  box-shadow: -3px 3px 5px rgba(50, 50, 50, 0.77);
}

.modal-detalhes-curso {
  position: absolute;
  top: 40px;
  left: 100px;
  right: 100px;
  bottom: 40px;
  border-radius: 10px;
  background-color: #071b31;
  padding: 20px;
  color: #fff;
  overflow: auto;
  z-index: 99;
}

.modal-detalhes-curso .fechar-modal {
  display: flex;
  justify-content: flex-end;
}

.modal-detalhes-curso .fechar-modal button {
  height: 20px;
  width: 20px;
  border: none;
  background-color: #071b31;
  color: #fff;
}

.modal-detalhes-curso .fechar-modal button:hover {
  color: #d3d3d3;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .titulo-curso {
  text-align: center;
  margin-bottom: 30px;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes {
  margin-bottom: 30px;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes .titulo-secao {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes .container-card-descricao {
  background-color: #1d2936;
  padding: 18px 25px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes .container-card-descricao .carga-horaria {
  text-align: center;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .container-botao-inscreva {
  display: flex;
  justify-content: center;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .container-botao-inscreva .botao-inscreva-se {
  height: 50px;
  width: 250px;
  margin-bottom: 30px;
  padding: 10px 25px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .container-botao-inscreva .botao-inscreva-se:hover {
  filter: brightness(140%);
}

.modal-detalhes-curso::-webkit-scrollbar {
  width: 10px;
}

.modal-detalhes-curso::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
}

.modal-detalhes-curso::-webkit-scrollbar-thumb {
  background: #3f4c5a; 
}

.modal-detalhes-curso::-webkit-scrollbar-thumb:hover {
  background: #343c46; 
}

.overlay-modal-detalhes-curso {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.75);
  z-index: 99;
}

@media (max-width: 750px) {
  .modal-detalhes-curso {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-radius: 10px;
    background-color: #071b31;
    padding: 20px;
    color: #fff;
    z-index: 99;
  }
}