div.container-forum-geral {
  position: relative;
  background-color: #000f20;
  padding-top: 40px;
}

div.container-forum-geral img.background-1 {
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  top: 10%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

div.container-forum-geral img.background-2 {
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  bottom: 2%;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

div.container-forum-geral .quadro-forum-geral {
  position: relative;
  background-color: #1C2B3B;
  border: 1px solid gray;
  z-index: 5;
  margin: 0 4%;
}