form.container-formulario-cadastro-aluno {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-formulario-cadastro-aluno .titulo {
  width: 100%;
}

form.container-formulario-cadastro-aluno .titulo .topo {
  position: relative;
  margin-bottom: 15px;
}

form.container-formulario-cadastro-aluno .titulo .topo button {
  position: absolute;
  top: -10px;
  background-color: transparent;
  border: none;
}

form.container-formulario-cadastro-aluno .titulo .topo button .icone-voltar {
  height: 40px;
  width: 40px;
  color: #0466db;
}

form.container-formulario-cadastro-aluno .titulo .topo h2 {
  text-align: center;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login {
  padding: 0 8%;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  font-weight: bold;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button.btn-facebook {
  background-color: #1877f2;
  color: white;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button.btn-google {
  background-color: white;
  color: gray;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button.btn-linkedin {
  background-color: #0a66c2;
  color: white;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button .icone-btn-login {
  margin: 0 0 0 10px;
  width: 16px;
  height: 16px;
}

form.container-formulario-cadastro-aluno .titulo p {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

form.container-formulario-cadastro-aluno .titulo p.msg-continuar-cadastro {
  margin-top: 10px;
}

form.container-formulario-cadastro-aluno .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

form.container-formulario-cadastro-aluno .container-inputs label {
  width: 100%;
  margin-top: 20px;
}

form.container-formulario-cadastro-aluno .container-inputs label span {
  color: red;
}

form.container-formulario-cadastro-aluno .container-inputs label input {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-formulario-cadastro-aluno .container-inputs label input.senha-check {
  background-color: rgb(184, 255, 228);
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox {
  display: block;
  position: relative;
  padding: 3px 0 0 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox a {
  color: white;
  text-decoration: underline;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #c5ddff;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox input:checked ~ .checkmark {
  background-color: #0466db;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox input:checked ~ .checkmark:after {
  display: block;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

form.container-formulario-cadastro-aluno .container-inputs .captcha {
  margin-top: 20px;
}

form.container-formulario-cadastro-aluno .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-formulario-cadastro-aluno .container-inputs button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

form.container-formulario-cadastro-aluno .container-inputs .aviso-erro-login {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-erro-cadastro {
  animation-duration: 0.2s;
}

.alert-erro-cadastro .react-confirm-alert-body {
  color: #1c2732;
}

.alert-erro-cadastro button {
  font-size: 14px;
  font-weight: bold;
}

.alert-erro-cadastro button.botao-erro {
  background-color: #0466db;
}

@media (max-width: 950px) {
  form.container-formulario-cadastro-aluno {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  form.container-formulario-cadastro-aluno {
    width: 98%;
  }
  form.container-formulario-cadastro-aluno .titulo .botoes-login, 
  form.container-formulario-cadastro-aluno .titulo p,
  form.container-formulario-cadastro-aluno .container-inputs {
    padding: 0;
  }
}