div.container-visualizar-topico {
  background-color: #ebf3ff;
}

div.container-visualizar-topico .container-botao-criar-topico {
  background-color: #1C2B3B;
  padding: 20px 10px;
}

div.container-visualizar-topico .container-botao-criar-topico .botao-criar-topico {
  display: flex;
  align-items: center;
  background-color: #0d78f2;
  padding: 8px 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

div.container-visualizar-topico .container-botao-criar-topico .botao-criar-topico:hover {
  filter: brightness(120%);
}

div.container-visualizar-topico .container-botao-criar-topico .botao-criar-topico .icone {
  height: 28px;
  width: 28px;
  margin-right: 10px;
  color: black;
}

div.container-visualizar-topico .container-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 3%;
  background-color: #0060B1;
}

div.container-visualizar-topico .container-titulo p {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

div.container-visualizar-topico .container-detalhes-topico {
  padding-bottom: 20px;
}

div.container-visualizar-topico .container-detalhes-topico .banner {
  width: 100%;
  height: 230px;
  object-fit: cover;
}

div.container-visualizar-topico .container-detalhes-topico .banner-vazio {
  width: 100%;
  height: 230px;
  background-color: #00244d;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto {
  padding: 30px 3%;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .titulo {
  font-size: 24px;
  font-weight: bold;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .data {
  margin: 15px 0 40px 0;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .subtitulo {
  font-weight: bold;
  margin-bottom: 20px;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .texto {
  margin-top: 20px;
}

div.container-visualizar-topico .container-detalhes-topico hr {
  margin: 80px 0 40px 0;
  background-color: #c5ddff;
  padding: 3px;
  border: none;
}

div.container-visualizar-topico .container-detalhes-topico .btn-voltar {
  display: block;
  margin: auto;
  background-color: #fff;
  padding: 12px 100px;
  border: 2px solid #00244d;
  border-radius: 5px;
  color: #00244d;
  font-weight: bold;
  font-size: 16px;
}

div.container-visualizar-topico .container-detalhes-topico .btn-voltar:hover {
  filter: brightness(120%);
}

div.container-visualizar-topico .container-comentarios {
  padding: 0 3%;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios p {
  font-weight: bold;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios .acoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios .acoes button .icone {
  height: 18px;
  width: 18px;
  color: gray;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios .acoes button:hover {
  filter: brightness(105%);
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario {
  display: flex;
  border-radius: 8px;
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario .editor-texto-comentario {
  flex: 1;
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario .editor-texto-comentario .editor {
  margin-top: 20px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario .foto-perfil {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 10px 0 -20px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios {
  margin-top: 30px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .btn-responder-comentario {
  margin: 10px 0 0 0;
  font-size: 12px;
  text-decoration: underline;
  color: #00244d;
  cursor: pointer;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .btn-responder-comentario:hover {
  filter: brightness(120%);
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario {
  display: flex;
  background-color: #c5ddff;
  border-radius: 8px;
  padding: 20px 15px 8px 15px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario img {
  max-width: 100%;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .foto-perfil {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  margin: 0 10px 0 -40px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto {
  flex: 1;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor .nome {
  font-weight: bold;
  margin-bottom: 10px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor .data {
  font-size: 10px;
  color: gray;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div {
  display: flex;
  align-items: center;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div p {
  margin: 0;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  padding: 5px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes:hover {
  filter: brightness(120%);
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes {
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px 0 5px 5px;
  padding: 5px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p {
  border: none;
  font-size: 12px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p:hover {
  text-decoration: underline;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p:last-of-type {
  margin-top: 5px;
  color: red;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-resposta-comentario {
  padding-left: 5px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-resposta-comentario .resposta-comentario {
  margin-top: 5px;
  margin-left: 10px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta {
  display: flex;
  margin-top: 10px;
  border-radius: 8px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .editor-texto-comentario {
  flex: 1;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .editor-texto-comentario .editor {
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .foto-perfil {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 10px 0 -20px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  margin-left: 5px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta .icone {
  height: 18px;
  width: 18px;
  color: gray;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta:hover {
  filter: brightness(105%);
}

div.container-visualizar-topico .container-paginacao-comentarios {
  height: 30px;
  padding: 0 3% 10px 3%;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a {
  background-color: #0d78f2;
  color: black;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > span, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a:hover, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > span:hover, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a:focus, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > span:focus {
  background-color: #0d78f2;
  border-color: #fff;
  outline: none;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > span {
  background-color: #a3b9d8;
  color: black;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > a:hover {
  filter: brightness(120%);
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:first-child > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:first-child > span, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:last-child > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:last-child > span {
  border-radius: 5px
}

.alert-visualizar-topico {
  animation-duration: 0.2s;
}

.alert-visualizar-topico .react-confirm-alert-body {
  color: #1c2732;
}

.alert-visualizar-topico button {
  font-size: 14px;
  font-weight: bold;
}

.alert-visualizar-topico button.botao-alert-visualizar-topico {
  background-color: #0466db;
}

.alert-visualizar-topico button.botao-confirmar {
  background-color: #cc3300;
}
