div.container-cards-cursos-detalhes {
  position: relative;
  border-radius: 10px;
  height: 350px;
  width: 300px;
  overflow: hidden;
}

div.container-cards-cursos-detalhes img {
  height: 100%;
  width: 100%;
  border: 1px solid white;
  border-radius: 10px;
  object-fit: cover;
}

div.container-cards-cursos-detalhes .numero-trilha-conhecimento {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 0 10px 10px 10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #0071f2;
}

div.container-cards-cursos-detalhes .tag-curso {
  position: absolute;
  bottom: 270px;
  left: 80px;
  width: 100%;
  padding: 5px 0;
  opacity: 0.9;
  transform: rotate(45deg);
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

div.container-cards-cursos-detalhes .nome-curso {
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: #fff;
  color: #0d78f2;
  font-weight: bold;
  padding: 8px 12px;
  max-width: 95%;
  box-shadow: -3px 3px 5px rgba(50, 50, 50, 0.77);
}

div.container-cards-cursos-detalhes .acoes {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50% 12%;
  background-color: transparent;
  opacity: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.425);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
}

div.container-cards-cursos-detalhes .acoes:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

div.container-cards-cursos-detalhes .acoes:hover+.nome-curso {
  opacity: 0;
}

div.container-cards-cursos-detalhes .acoes .btn-acoes {
  text-decoration: none;
  text-align: center;
  color: inherit;
  width: 100%;
  background-color: white;
  border: 2px solid #0071f2;
  border-radius: 10px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #0071f2;
}

div.container-cards-cursos-detalhes .acoes .btn-acoes:hover {
  filter: brightness(140%);
}