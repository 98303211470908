div.container-meu-perfil {
  position: relative;
  width: 60%;
  margin: 50px auto;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: #071b31;
  z-index: 1;
  padding-bottom: 30px;
}

div.container-meu-perfil .container-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: #0466db;
  border-radius: 19px;
  padding: 0 20px;
}

div.container-meu-perfil .container-topo p {
  flex: 1;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

div.container-meu-perfil .container-topo .container-icone-notificacao {
  position: relative;
  display: flex;
  cursor: pointer;
}

div.container-meu-perfil .container-topo .container-icone-notificacao:hover {
  color: red;
}

div.container-meu-perfil .container-topo .container-icone-notificacao .icone-notificacao {
  height: 40px;
  width: 40px;
  color: black;
}

div.container-meu-perfil .container-topo .container-icone-notificacao div {
  position: absolute;
  top: -5px;
  left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: black;
  font-size: 12px;
  font-weight: bold;
}

div.container-meu-perfil .container-topo .container-notificacao-completa {
  display: none;
}

div.container-meu-perfil .container-topo .container-notificacao-completa-aberta {
  position: absolute;
  right: -180px;
  top: -20px;
  width: 200px;
  background-color: #c5ddff;
  color: #0466db;
  padding: 8px;
  border-radius: 10px;
}

div.container-meu-perfil .container-topo .container-notificacao-completa-aberta p {
  font-size: 12px;
}

div.container-meu-perfil .container-informacoes {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 40px 10px 40px;
}

div.container-meu-perfil .container-informacoes .foto-perfil img {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -60px;
}

div.container-meu-perfil .container-informacoes .foto-perfil, 
div.container-meu-perfil .container-informacoes .dados, 
div.container-meu-perfil .container-informacoes .icone-acoes {
  flex: 1;
}

div.container-meu-perfil .container-informacoes .dados {
  padding-right: 5px;
  font-size: 16px;
}

div.container-meu-perfil .container-informacoes .dados p {
  font-weight: bold;
  margin: 20px 0;
}

div.container-meu-perfil .container-informacoes .dados p:first-of-type {
  margin-top: 0;
}

div.container-meu-perfil .container-informacoes .icones-acoes {
  display: flex;
  width: 160px;
}

div.container-meu-perfil .container-informacoes .icones-acoes a {
  height: 30px;
}

div.container-meu-perfil .container-informacoes .icones-acoes .icone {
  height: 30px;
  width: 30px;
  margin: 0 2px;
  color: white;
}

div.container-meu-perfil .container-informacoes .icones-acoes .icone:hover {
  cursor: pointer;
  color: #0466db;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro {
  position: relative;
  display: flex;
  height: 30px;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro:hover .msg-atencao{
  text-decoration: underline;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro div {
  position: absolute;
  top: -5px;
  left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: red;
  font-size: 12px;
  font-weight: bold;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro .msg-atencao {
  position: absolute;
  top: -15px;
  left: 40px;
  height: auto;
  padding: 8px 10px;
  background-color: #c5ddff;
  white-space: nowrap;
  border-radius: 10px;
  color: #0466db;
  font-weight: bold;
  text-decoration: none;
}

div.container-meu-perfil  .assinatura {
  display: inline-flex;
  align-items: center;
  padding-left: 40px;
  color: #0466db;
}

div.container-meu-perfil  .assinatura .icone-cartao {
  height: 35px;
  width: 35px;
  margin-right: 5px;
  color: white;
}

div.container-meu-perfil  .assinatura p {
  font-size: 16px;
  font-weight: bold;
}

div.container-meu-perfil  .assinatura:hover {
  cursor: pointer;
  color: white;
}

@media (max-width: 980px) {
  div.container-meu-perfil .container-informacoes {
    flex-direction: column;
    align-items: center;
  }
  div.container-meu-perfil .container-topo .container-notificacao-completa-aberta {
    right: 0;
    top: 65px;
  }
  div.container-meu-perfil .container-informacoes .foto-perfil img {
    margin-top: -20px;
  }
  div.container-meu-perfil .container-informacoes .dados {
    margin-top: 10px;
    padding-right: 0;
    text-align: center;
  }
  div.container-meu-perfil .container-informacoes .icones-acoes {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  div.container-meu-perfil {
    width: 80%;
  }
}

@media (max-width: 450px) {
  div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro .msg-atencao {
    white-space: normal;
  }
  div.container-meu-perfil  .assinatura {
    margin-top: 20px;
    padding-left: 10px;
  }
}