form.container-completar-cadastro {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}


form.container-completar-cadastro .titulo h2 {
  text-align: center;
}


form.container-completar-cadastro .titulo p {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

form.container-completar-cadastro .titulo p.msg-continuar-cadastro {
  margin-top: 10px;
}

form.container-completar-cadastro .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

form.container-completar-cadastro .container-inputs label {
  width: 100%;
  margin-top: 20px;
}

form.container-completar-cadastro .container-inputs label span {
  color: red;
}

form.container-completar-cadastro .container-inputs label input, 
form.container-completar-cadastro .container-inputs label select {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-completar-cadastro .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-completar-cadastro .container-inputs .aviso-erro-cadastro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-erro-completar-cadastro {
  animation-duration: 0.2s;
}

.alert-erro-completar-cadastro .react-confirm-alert-body {
  color: #1c2732;
}

.alert-erro-completar-cadastro button {
  font-size: 14px;
  font-weight: bold;
}

.alert-erro-completar-cadastro button.botao-erro {
  background-color: #0466db;
}

@media (max-width: 950px) {
  form.container-completar-cadastro {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  form.container-completar-cadastro {
    width: 98%;
  }
  form.container-completar-cadastro .titulo p,
  form.container-completar-cadastro .container-inputs {
    padding: 0;
  }
}