body, html {
  min-height: 100vh;
}

div.container-manter-aluno-plataforma {
  position: relative;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  padding-bottom: 50px;
  min-height: 110vh;
}

div.container-manter-aluno-plataforma img.background-1 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 200px;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-manter-aluno-plataforma img.background-2 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-manter-aluno-plataforma img.logo-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-manter-aluno-plataforma button:hover {
  filter: brightness(140%);
}

div.container-manter-aluno-plataforma header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1.5%;
  background-color: transparent;
}

div.container-manter-aluno-plataforma .container-logo a img {
  height: 60px;
  width: 145px;
}

div.container-manter-aluno-plataforma header button {
  border: 3px solid #0466db;
  border-radius: 10px;
  padding: 8px 50px;
  font-weight: bold;
  font-size: 16px;
  color: #0466db;
  background-color: transparent;
}

div.container-manter-aluno-plataforma header button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

@media (max-width: 810px) {
  div.container-manter-aluno-plataforma img.background-1 {
    height: 300px;
    width: 300px;
  }
  div.container-manter-aluno-plataforma img.background-2 {
    height: 300px;
    width: 300px;
  }
}

@media (max-width: 730px) {
  body, html {
    height: auto;
    overflow: auto;
  }
  div.container-manter-aluno-plataforma {
    height: auto;
  }
}

@media (max-width: 420px) {
  div.container-manter-aluno-plataforma img.logo-whatsapp {
    bottom: 10px;
  }
  div.container-manter-aluno-plataforma img.background-1 {
    height: 150px;
    width: 150px;
  }
  div.container-manter-aluno-plataforma img.background-2 {
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 340px) {
  div.container-manter-aluno-plataforma header {
    flex-direction: column;
    align-items: center;
    padding: 0 1.5% 5px 1.5%;
  }
  div.container-manter-aluno-plataforma header button {
    margin-top: 10px;
  }
}