div.container-pagina-planos {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
  padding-top: 80px;
}

div.container-pagina-planos img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-planos img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 60;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-pagina-planos .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin: 50px 8%;
}

div.container-pagina-planos .container-titulo h1 {
  white-space: nowrap;
}

div.container-pagina-planos .container-titulo i {
  font-style: normal;
  color: #0466db;
}

div.container-pagina-planos .container-titulo hr {
  height: 2px;
  width: 100%;
  border-color: #0466db;
  margin-left: 20px;
}

div.container-pagina-planos .container-cards-planos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: calc(100vh - 520px);
}

@media (max-width: 480px) {
  div.container-pagina-planos img.background {
    height: 350px;
    width: 350px;
  }
}

@media (max-width: 360px) {
  div.container-pagina-planos {
    padding: 20px 1%;
  }
  div.container-pagina-planos .container-titulo {
    margin: 70px 8% 50px 8%;
  }
  div.container-pagina-planos .container-titulo h1 {
    white-space: normal;
  }
}