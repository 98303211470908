div.accordion-disciplinas {
  margin-bottom: 10px;
  text-align: start;
}

div.accordion-disciplinas .accordion-titulo {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background-color: #052c58;
  padding: 5px 25px;
  border-radius: 10px;
  z-index: 2;
}

div.accordion-disciplinas .accordion-titulo:hover {
  filter: brightness(140%);
}

div.accordion-disciplinas .accordion-titulo .icone-accordion {
  height: 40px;
  width: 40px;
  color: #fff;
}

div.accordion-disciplinas .accordion-titulo p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

div.accordion-disciplinas .accordion-conteudo {
  padding: 20px 20px 5px 20px;
  background-color: #052c58;
  margin-top: -5px;
  border-radius: 0 0 10px 10px;
}