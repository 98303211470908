div.container-pagina-inicial {
  background: linear-gradient(rgba(0, 8, 17, 255) 70%, transparent);
  background-color: #464c52;
  position: relative;
}

div.container-pagina-inicial button:hover {
  filter: brightness(140%);
}

div.rodape-fixo-rolagem button a, div.container-cursos-destaque .conteudo-cursos-destaque .descricao button a {
  text-decoration: none;
  color: inherit;
}

/* Estilização do container de cursos em destaque */
div.container-cursos-destaque {
  position: relative;
  padding-top: 80px;
}

div.container-cursos-destaque img.background {
  height: 450px;
  width: 450px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 20px;
  left: -50px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-cursos-destaque .conteudo-cursos-destaque {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  color: white;
  z-index: 1;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao {
  position: relative;
  padding-top: 150px;
  z-index: 2;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao h1 {
  font-size: 40px;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao p {
  margin: 15px 0;
  font-size: 16px;
  max-width: 80%;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao p:last-child {
  margin: 5px 0;
  font-size: 14px;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-cursos-destaque .conteudo-cursos-destaque .cursos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  padding-top: 60px;
}

/* Estilização das seções do site */
div.container-secoes {
  margin-top: 70px;
  color: white;
}

div.container-secoes h1 {
  padding: 0 8%;
  margin-bottom: 20px;
}

div.container-secoes i {
  color: #0466db;
  font-style: normal;
}

/* Estilização dos motivos de escolher a CSA */
div.container-secoes .container-motivos-csa {
  padding: 0 8%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: 100px;
}

div.container-secoes .container-motivos-csa .card-motivo {
  background-color: #1c262f;
  padding: 20px 20px 30px 20px;
  border-radius: 10px;
}

div.container-secoes .container-motivos-csa .card-motivo h3 {
  margin-bottom: 15px;
}

div.container-secoes .container-motivos-csa .card-motivo p {
  text-align: justify;
}

/* Estilização de como irá aprender */
div.container-secoes .container-videos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  padding: 0 8%;
}

div.container-secoes .container-videos .container-video-aberto h2 {
  margin-bottom: 15px;
}

div.container-secoes .container-videos .container-video-aberto h3 {
  margin: 1px 0 20px;
}

div.container-secoes .container-videos .container-video-aberto iframe {
  height: 395px;
  width: 100%;
  border-radius: 10px;
}

div.container-secoes .container-videos .container-videos-lateral {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral {
  display: flex;
  cursor: pointer;
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral:hover {
  filter: brightness(140%);
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral iframe {
  height: 130px;
  max-width: 150px;
  border-radius: 10px;
  pointer-events: none;
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral h3 {
  margin: 10px 0 0 15px;
}

/* Estilização de diferenciais CSA */
div.container-secoes .accordion-diferenciais-csa {
  padding: 0 8%;
}

/* Estilização das declaraçõe do alunos da CSA */
div.container-secoes .container-depoimentos-alunos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #202c3c;
  margin: 0 8%;
  padding: 20px 0;
  border-radius: 10px;
}

div.container-secoes .container-depoimentos-alunos hr {
  margin: 20px 0;
  width: 70%;
  border-color: #4a6079;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item {
  display: flex;
  text-align: center;
  max-width: 500px;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item .foto-nome img {
  height: 90px;
  width: 90px;
  border: 1px solid #fff;
  border-radius: 50%;
  object-fit: cover;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item .foto-nome p {
  font-weight: bold;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item .texto {
  margin-left: 15px;
}

/* Estilização do container de perguntas frequentes */
div.accordion-perguntas-frequetes {
  padding: 0 8%;
}

div.accordion-perguntas-frequetes h2 {
  color: #b7ceed;
}

div.accordion-perguntas-frequetes h2:not(:first-child) {
  margin-top: 30px;
}

/* Estilização do  rodapé fixo na rolage de assine agora */
div.rodape-fixo-rolagem {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 8%;
  background-color: #17181c;
  color: white;
}

div.rodape-fixo-rolagem button {
  padding: 12px 80px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  margin-left: 25px;
}


@media (max-width: 1100px) {
  div.container-secoes .container-videos .container-video-aberto video {
    height: 250px;
    max-width: 100%;
  }
}

@media (max-width: 860px) {
  div.container-cursos-destaque .conteudo-cursos-destaque {
    flex-direction: column;
    text-align: center;
  }
  div.container-cursos-destaque .conteudo-cursos-destaque .descricao p {
    max-width: 100%;
  }
  div.container-cursos-destaque .conteudo-cursos-destaque .cursos {
    margin: auto;
  }
  div.container-secoes {
    text-align: center;
  } 
  div.container-secoes .container-motivos-csa {
    grid-column-gap: 50px;
  }
  div.container-secoes .container-videos {
    grid-template-columns: 1fr;
  }
  div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral {
    text-align: start;
  }
}

@media (max-width: 730px) {
  div.container-cursos-destaque .conteudo-cursos-destaque {
    padding: 0 2%;
  }
  div.container-secoes .container-motivos-csa .card-motivo {
    padding-bottom: 20px;
  }
}

@media (max-width: 510px) {
  div.container-cursos-destaque img.background {
    height: 350px;
    width: 350px;
  }
  div.container-cursos-destaque .conteudo-cursos-destaque .cursos {
    grid-template-columns: 1fr;
  }
  div.container-secoes .container-motivos-csa {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 350px) {
  div.container-pagina-inicial img.logo-whatsapp {
    bottom: 120px;
  }
  div.rodape-fixo-rolagem {
    flex-direction: column;
  }
  div.rodape-fixo-rolagem p {
    margin-bottom: 20px;
  }
}