div.container-planos-cadastro {
  padding: 20px 8%;
}

div.container-planos-cadastro .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

div.container-planos-cadastro .container-titulo h1 {
  white-space: nowrap;
}

div.container-planos-cadastro .container-titulo i {
  font-style: normal;
  color: #0466db;
}

div.container-planos-cadastro .container-titulo hr {
  height: 2px;
  width: 100%;
  border-color: #0466db;
  margin-left: 20px;
}

div.container-planos-cadastro .container-cards-planos {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

@media (max-width: 360px) {
  div.container-planos-cadastro {
    padding: 20px 1%;
  }
  div.container-planos-cadastro .container-titulo h1 {
    white-space: normal;
  }
}