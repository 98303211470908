div.container-listar-topicos .titulo-quadro {
  padding: 20px 10px 10px 10px;
}

div.container-listar-topicos .titulo-quadro .botao-criar-topico {
  display: flex;
  align-items: center;
  background-color: #0d78f2;
  padding: 8px 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

div.container-listar-topicos .titulo-quadro .botao-criar-topico:hover {
  filter: brightness(120%);
}

div.container-listar-topicos .titulo-quadro .botao-criar-topico .icone {
  height: 28px;
  width: 28px;
  margin-right: 10px;
  color: black;
}

div.container-listar-topicos .container-paginacao {
  display: flex;
  align-items: center;
}

div.container-listar-topicos .container-paginacao .quantidade {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
  flex: 1;
  text-align: right;
}

div.container-listar-topicos .container-paginacao .pagination {
  margin: 10px auto;
  display: flex;
  list-style: none;
  outline: none;
}

div.container-listar-topicos .container-paginacao .pagination > .active > a {
  background-color: #0d78f2;
  color: black;
}

div.container-listar-topicos .container-paginacao .pagination > li > a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

div.container-listar-topicos .container-paginacao .pagination > .active > a, 
div.container-listar-topicos .container-paginacao .pagination > .active > span, 
div.container-listar-topicos .container-paginacao .pagination > .active > a:hover, 
div.container-listar-topicos .container-paginacao .pagination > .active > span:hover, 
div.container-listar-topicos .container-paginacao .pagination > .active > a:focus, 
div.container-listar-topicos .container-paginacao .pagination > .active > span:focus {
  background-color: #0d78f2;
  border-color: #fff;
  outline: none;
}

div.container-listar-topicos .container-paginacao .pagination > li > a, 
div.container-listar-topicos .container-paginacao .pagination > li > span {
  background-color: #a3b9d8;
  color: black;
}

div.container-listar-topicos .container-paginacao .pagination > li > a:hover {
  filter: brightness(120%);
}

div.container-listar-topicos .container-paginacao .pagination > li:first-child > a, 
div.container-listar-topicos .container-paginacao .pagination > li:first-child > span, 
div.container-listar-topicos .container-paginacao .pagination > li:last-child > a, 
div.container-listar-topicos .container-paginacao .pagination > li:last-child > span {
  border-radius: 5px
}

div.container-listar-topicos .titulo-topico {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 3%;
  background-color: #0060B1;
}

div.container-listar-topicos .titulo-topico p {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

div.container-listar-topicos .titulo-topico .btn-search {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 45px;
  overflow: hidden;
  width: auto;
  max-width: 45px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

div.container-listar-topicos .titulo-topico .btn-search:hover, 
div.container-listar-topicos .titulo-topico .btn-search.open {
  max-width: 300px;
}

div.container-listar-topicos .titulo-topico .btn-search .icon {
  font-size: 25px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-listar-topicos .titulo-topico .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-listar-topicos .topico {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 3%;
  background-color: #EBF3FF;
  text-decoration: none;
  margin-bottom: 4px;
}

div.container-listar-topicos .topico:hover {
  filter: brightness(105%);
}

div.container-listar-topicos .icone-topico {
  height: 50px;
  width: 50px;
  color: black;
  margin-right: 15px;
}

div.container-listar-topicos .topico p {
  color: #1C2B3B;
  text-align: left;
}

div.container-listar-topicos .topico p:first-of-type {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}

div.container-listar-topicos .container-paginacao-rodape {
  margin: 10px 10px 30px 0;
}