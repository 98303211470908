.menu-superior-pagina-inicial {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 1.5%;
  background-color: rgba(0, 8, 17);
  z-index: 10;
}

.menu-superior-pagina-inicial button:hover {
  filter: brightness(140%);
}

.menu-superior-pagina-inicial .nav {
  display: flex;
  flex: 1;
}

.menu-superior-pagina-inicial .nav-options {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.menu-superior-pagina-inicial .option {
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.menu-superior-pagina-inicial .container-logo a, .menu-superior-pagina-inicial .nav .container-acoes, 
.menu-superior-pagina-inicial .nav .container-acoes .assinante {
  display: flex;
  align-items: center;
  color: white;
}

.menu-superior-pagina-inicial .container-logo a img {
  height: 60px;
  width: 145px;
}

.menu-superior-pagina-inicial .nav .container-acoes div a {
  text-decoration: none;
  color: inherit;
}

.menu-superior-pagina-inicial .nav .container-acoes div a button {
  border-radius: 10px;
  padding: 6px 15px;
  border: 3px solid #b0c6e5;
  font-weight: bold;
  font-size: 16px;
  margin-right: 15px;
  color: #b0c6e5;
  border-color: #b0c6e5;
  background-color: transparent;
}

.menu-superior-pagina-inicial .nav .container-acoes div a:first-of-type button {
  color: #0466db;
  border-color: #0466db;
}

.menu-superior-pagina-inicial .nav .container-acoes .linha-vertical {
  height: 35px;
  border-left: 3px solid;
  border-color: #b0c6e5;
  margin-right: 15px;
}

.menu-superior-pagina-inicial .nav .container-acoes a {
  text-decoration: none;
  font-size: 16px;
}

.menu-superior-pagina-inicial .nav .container-acoes a .icone-usuario {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  color: #0466db;
}

.menu-superior-pagina-inicial .nav .container-acoes a:nth-child(3):hover {
  filter: brightness(140%);
  text-decoration: underline;
}


@media (max-width: 730px) {
  .menu-superior-pagina-inicial {
    align-items: center;
    padding: 0 1.5% 5px 1.5%;
    background-color: rgba(0, 8, 17);
  }
  .menu-superior-pagina-inicial .nav-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    top: 60px;
    left: -100%;
    opacity: 0;
    z-index: 20;
    transition: all 0.5s ease;
  }
  .menu-superior-pagina-inicial .nav-options.active {
    background-color: #000811;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }
  .menu-superior-pagina-inicial .menu-icon {
    width: 45px;
    height: 45px;
    display: block;
    color: white;
  }
  .menu-superior-pagina-inicial .nav .container-acoes {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
  }
  .menu-superior-pagina-inicial .nav .container-acoes div {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: -70px;
  }
  .menu-superior-pagina-inicial .nav .container-acoes div button {
    padding: 15px;
    margin-bottom: 20px;
  }
  .menu-superior-pagina-inicial .nav .container-acoes div button a {
    display: block;
  }
  .menu-superior-pagina-inicial .nav .container-acoes .linha-vertical {
    display: none;
  }
}

@media (max-width: 510px) {
  .menu-superior-pagina-inicial {
    padding: 0 1.5%;
  }
}