div.container-pagina-inicio img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-inicio .container-banners {
  position: relative;
}

div.container-pagina-inicio .container-banners .img-banner {
  height: 100vh;
  object-fit: cover;
}

div.container-pagina-inicio .container-banners .informacoes {
  position: absolute;
  top: 35%;
  width: min-content;
  margin-left: 8%;
  color: white;
  border-radius: 10px;
  padding: 15px;
  background: rgba(0, 17, 37, 0.507);
  box-shadow: 0 0px 10px 0 rgba(56, 56, 56, 0.5);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
  border: 1px solid rgba( 255, 255, 255, 0.18);
}

div.container-pagina-inicio .container-banners .informacoes h1 {
  font-size: 40px;
  white-space: nowrap;
}

div.container-pagina-inicio .container-banners .informacoes p {
  font-size: 18px;
  width: 60%;
  text-align: justify;
  margin-top: 10px;
}

div.container-pagina-inicio .container-banners .informacoes div {
  margin-top: 20px;
  display: flex;
}

div.container-pagina-inicio .container-banners .informacoes div .btn {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  background-color: #0071f2;
  border: 3px solid #0071f2;
  border-radius: 5px;
  padding: 12px 40px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

div.container-pagina-inicio .container-banners .informacoes div .btn.detalhes {
  background-color: transparent;
  border-color: white;
  margin-left: 20px;
}

div.container-pagina-inicio .container-banners .informacoes div .btn.assistir .icone-botao-informacao {
  font-size: 20px;
  margin-right: 5px;
}

div.container-pagina-inicio .container-banners .informacoes div .btn.assistir:hover {
  filter: brightness(140%);
}

div.container-pagina-inicio .container-banners .informacoes div .btn.detalhes:last-child:hover {
  background-color: rgba(0, 113, 242, 0.623);
}

div.container-pagina-inicio .container-banners .btn-search {
  position: absolute;
  top: 100px;
  right: 100px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 50px;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

div.container-pagina-inicio .container-banners .btn-search:hover, 
div.container-pagina-inicio .container-banners .btn-search.open {
  max-width: 300px;
}

div.container-pagina-inicio .container-banners .btn-search .icon {
  font-size: 30px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-pagina-inicio .container-banners .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-pagina-inicio .container-cursos {
  position: relative;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-pagina-inicio .container-cursos img.background-1 {
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  top: 8%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

div.container-pagina-inicio .container-cursos img.background-2 {
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  top: 40%;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

/* Estilização das seções do site */
div.container-pagina-inicio .container-cursos .container-secoes-inicio {
  padding-top: 70px;
  color: white;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;
  margin-bottom: 20px;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo h1 {
  margin-right: 10px;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo i {
  color: #0466db;
  font-style: normal;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 16px;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo a .icone-ver-todos {
  height: 20px;
  width: 20px;
  color: #0466db;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo a:hover {
  text-decoration: underline;
}

@media (max-width: 920px) {
  div.container-pagina-inicio .container-banners .btn-search {
    right: 10px;
  }
}

@media (max-width: 730px) {
  div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo {
    padding: 0 2%;
  }  
}

@media (max-width: 660px) {
  div.container-pagina-inicio .container-banners .informacoes h1 {
    white-space: normal;
  }
}

@media (max-width: 510px) { 
  div.container-pagina-inicio .container-cursos img.background-1 {
    height: 300px;
    width: 300px;
    top: 1150px;
  }
  
  div.container-pagina-inicio .container-cursos img.background-2 {
    height: 200px;
    width: 200px;
    top: 1600px;
  }
}

@media (max-width: 420px) {
  div.container-pagina-inicio img.logo-whatsapp {
    bottom: 10px;
  }  
  div.container-pagina-inicio .container-banners .informacoes {
    padding: 0px 10px;
    text-align: center;
  }
  div.container-pagina-inicio .container-banners .informacoes p {
    width: 100%;
  }
  div.container-pagina-inicio .container-banners .informacoes div {
    flex-direction: column;
    align-items: center;
  }
  div.container-pagina-inicio .container-banners .informacoes div button {
    width: 200px;
    justify-content: center;
  }
  div.container-pagina-inicio .container-banners .informacoes div button:last-child {
    margin: 20px 0 0 0;
  }
}