div.container-carousel-cards-aulas {
  position: relative;
  z-index: 1;
  cursor: grab;
}

div.container-carousel-cards-aulas .react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

div.container-carousel-cards-aulas .container-card-curso {
  max-width: 300px;
}

div.container-carousel-cards-aulas .container-card-curso .titulo-progresso {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}