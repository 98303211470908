div.container-detalhes-disciplina {
  display: flex;
}

div.container-detalhes-disciplina .container-conteudos-disciplina {
  flex: 1;
}

div.container-detalhes-disciplina .btn-forum {
  height: 50px;
  width: 250px;
  margin: 20px 0 0 15px;
  padding: 10px 25px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

div.container-detalhes-disciplina .btn-forum:hover {
  filter: brightness(120%);
}

div.container-detalhes-disciplina .btn-forum a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

@media (max-width: 790px) {
  div.container-detalhes-disciplina {
    flex-direction: column;
    align-items: center;
  }
  div.container-detalhes-disciplina .btn-forum {
    margin: 20px 0 0;
  }
}