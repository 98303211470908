div.container-detalhes-curso .container-horario-mensagem {
  display: flex;
  justify-content: space-between;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum {
  display: flex;
  flex-direction: column;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum button {
  height: 50px;
  width: 250px;
  margin-top: 20px;
  padding: 10px 25px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum button.btn-inscreva:disabled:hover {
  filter: none;
  cursor: inherit;
}

div.container-detalhes-curso .container-horario-mensagem .carga-horaria {
  width: 50%;
}

div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno {
  background-color: #00244d;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 20px 25px;
  text-align: center;
}

div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno p {
  font-weight: bold;
}

div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno p:last-of-type {
  color: red;
}

@media (max-width: 790px) {
  div.container-detalhes-curso .container-horario-mensagem {
    flex-direction: column;
  }
  div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno {
    order: -1;
  }
  div.container-detalhes-curso .container-horario-mensagem .carga-horaria {
    width: 100%;
  }
}