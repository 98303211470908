form.container-acessar-conta-aluno {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-acessar-conta-aluno .titulo {
  width: 100%;
}

form.container-acessar-conta-aluno .titulo {
  display: flex;
  flex-direction: column;
  text-align: center;
}

form.container-acessar-conta-aluno .titulo h2 {
  margin-bottom: 10px;
}

form.container-acessar-conta-aluno .titulo .botoes-login {
  padding: 0 8%;
}

form.container-acessar-conta-aluno .titulo .botoes-login button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  font-weight: bold;
}

form.container-acessar-conta-aluno .titulo .botoes-login button p {
  margin: auto;
  padding-right: 30px;
  text-align: center;
}

form.container-acessar-conta-aluno .titulo .botoes-login button.btn-facebook {
  background-color: #1877f2;
  color: white;
}

form.container-acessar-conta-aluno .titulo .botoes-login button.btn-google {
  background-color: white;
  color: gray;
}

form.container-acessar-conta-aluno .titulo .botoes-login button.btn-linkedin {
  background-color: #0a66c2;
  color: white;
}

form.container-acessar-conta-aluno .titulo .botoes-login button .icone-btn-login {
  margin: 0 0 0 10px;
  width: 16px;
  height: 16px;
}

form.container-acessar-conta-aluno .titulo p.msg-credenciais {
  margin-top: 10px;
}

form.container-acessar-conta-aluno .container-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8%;
  margin-top: 10px;
}

form.container-acessar-conta-aluno .container-inputs label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

form.container-acessar-conta-aluno .container-inputs label input {
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-acessar-conta-aluno .container-inputs .esqueci-senha {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin: 5px 0;
  text-decoration: none;
}

form.container-acessar-conta-aluno .container-inputs .esqueci-senha:hover {
  text-decoration: underline;
}

form.container-acessar-conta-aluno .container-inputs .captcha {
  margin-top: 20px;
}

form.container-acessar-conta-aluno .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-acessar-conta-aluno .container-inputs a {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 15px 0;
}

form.container-acessar-conta-aluno .container-inputs .aviso-erro-login {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

@media (max-width: 950px) {
  form.container-acessar-conta-aluno {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 730px) {
  form.container-acessar-conta-aluno {
    height: 100vh;
  }
}

@media (max-width: 550px) {
  form.container-acessar-conta-aluno {
    width: 98%;
  }
  form.container-acessar-conta-aluno .titulo .botoes-login, form.container-acessar-conta-aluno .container-inputs {
    padding: 0;
  }
}