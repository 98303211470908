div.container-meu-progresso {
  margin-bottom: 70px;
  min-height: calc(100vh - 400px);
}

div.container-meu-progresso .container-topo {
  display: flex;
  align-items: center;
  padding: 60px 5%;
  background-color: #1c2732;
}

div.container-meu-progresso .container-topo div {
  background-color: white;
  height: 40px;
  width: 40px;
  padding: 2px;
  border-radius: 3px;
  margin-right: 25px;
}

div.container-meu-progresso .container-topo div .icone-meu-progresso {
  height: 40px;
  width: 35px;
  color: black;
}

div.container-meu-progresso .container-topo p {
  font-size: 24px;
  font-weight: bold;
}

div.container-meu-progresso .titulo-topico {
  margin: 40px 0;
  text-align: center;
}

div.container-meu-progresso .container-aulas {
  margin-bottom: 30px;
  padding: 0 6%;
}

div.container-meu-progresso hr {
  border-color: gray;
}

@media (max-width: 980px) {
  div.container-meu-progresso .container-topo {
    padding: 60px 2%;
  }
  div.container-meu-progresso .container-aulas {
    padding: 0 2%;
  }
}