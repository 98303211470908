div.container-criar-topico .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 3%;
  background-color: #0060B1;
}

div.container-criar-topico .titulo p {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

div.container-criar-topico .container-conteudo-topico {
  background-color: #ebf3ff;
  padding: 50px 3%;
}

div.container-criar-topico .container-conteudo-topico .texto-titulo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
}

div.container-criar-topico .container-conteudo-topico label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #1C2B3B;
  margin-bottom: 40px;
}

div.container-criar-topico .container-conteudo-topico label input {
  width: 50%;
  padding: 15px 10px;
  border: 1px solid gray;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 5px;
}

div.container-criar-topico .container-conteudo-topico label span {
  color: red;
}

div.container-criar-topico .container-conteudo-topico label .editor-texto {
  margin-top: 5px;
}

div.container-criar-topico .container-conteudo-topico .container-botoes {
  display: flex;
  justify-content: center;
}

div.container-criar-topico .container-conteudo-topico .container-botoes button {
  background-color: #00244d;
  padding: 12px 30px;
  border: 2px solid #00244d;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

div.container-criar-topico .container-conteudo-topico .container-botoes button:hover {
  filter: brightness(120%);
}

div.container-criar-topico .container-conteudo-topico .container-botoes button:first-of-type {
  margin-right: 15px;
  background-color: #fff;
  border: 2px solid #00244d;
  color: #00244d;
}

div.container-criar-topico .container-conteudo-topico .aviso-erro-topico {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-criar-topico {
  animation-duration: 0.2s;
}

.alert-criar-topico .react-confirm-alert-body {
  color: #1c2732;
}

.alert-criar-topico button {
  font-size: 14px;
  font-weight: bold;
}

.alert-criar-topico button.botao-alert-criar-topico {
  background-color: #0466db;
}


@media (max-width: 770px) {
  div.container-criar-topico .container-conteudo-topico label input {
    width: 100%;
  }
}

@media (max-width: 470px) {
  div.container-criar-topico .container-conteudo-topico .container-botoes {
    flex-direction: column;
  }
  div.container-criar-topico .container-conteudo-topico .container-botoes button {
    margin: 0 0 10px 0;
  }
  div.container-criar-topico .container-conteudo-topico .container-botoes button:first-of-type {
    margin-right: 0;
  }
}