div.container-carousel-cards-cursos-detalhes {
  position: relative;
  display: flex;
  margin: 0 7%;
  z-index: 1;
  cursor: grab;
}

div.container-carousel-cards-cursos-detalhes .react-multi-carousel-list {
  flex: 1;
  padding-bottom: 2px;
}

div.container-carousel-cards-cursos-detalhes .react-multi-carousel-item {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: grab;
}

@media (max-width: 730px) {
  div.container-carousel-cards-cursos-detalhes {
    margin: 0 2%;
  }  
}