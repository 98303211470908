div.container-sugestao-critica .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 8%;
  background-color: #1c2732;
}

div.container-sugestao-critica .container-titulo .titulo {
  display: flex;
  align-items: center;
}

div.container-sugestao-critica .container-titulo .titulo .icone-titulo {
  height: 45px;
  width: 45px;
  margin-right: 10px;
}

div.container-sugestao-critica .container-titulo .titulo p {
  font-size: 26px;
  font-weight: bold;
}

div.container-sugestao-critica .container-titulo .subtitulo {
  font-size: 16px;
}

div.container-sugestao-critica .container-quadro-texto {
  position: relative;
  padding: 0 8%;
  z-index: 5;
}

div.container-sugestao-critica .container-quadro-texto .titulo-quadro {
  display: flex;
  align-items: center;
  margin: 50px 0;
}

div.container-sugestao-critica .container-quadro-texto .titulo-quadro .icone {
  height: 60px;
  width: 60px;
  margin-right: 15px;
}

div.container-sugestao-critica .container-quadro-texto .titulo-quadro p {
  font-size: 24px;
  font-weight: bold;
}

div.container-sugestao-critica .container-quadro-texto .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.container-sugestao-critica .container-quadro-texto .quadro textarea {
  width: 100%;
  background-color: #C5DDFF;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  resize: none;
}

div.container-sugestao-critica .container-quadro-texto .quadro textarea::placeholder {
  color: #1c2732;
}

div.container-sugestao-critica .container-quadro-texto .quadro button {
  margin-top: 40px;
  width: 30%;
  padding: 10px 0;
  border: none;
  border-radius: 10px;
  background-color: #0466db;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

div.container-sugestao-critica .container-quadro-texto .quadro .aviso-erro-envio {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

div.container-sugestao-critica .container-quadro-texto .quadro button:hover {
  filter: brightness(140%);
}

.alert-sucesso-enviar-sugestao {
  animation-duration: 0.2s;
}

.alert-sucesso-enviar-sugestao .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-enviar-sugestao button {
  font-size: 14px;
  font-weight: bold;
}

.alert-sucesso-enviar-sugestao button.botao-confirmar {
  background-color: #0466db;
}

@media (max-width: 980px) {
  div.container-sugestao-critica .container-titulo {
    padding: 50px 10px;
  }
  div.container-sugestao-critica .container-quadro-texto {
    padding: 0 10px;
  }
}

@media (max-width: 645px) {
  div.container-sugestao-critica .container-titulo p {
    width: 80%;
  }
}

@media (max-width: 420px) {
  .alert-sucesso-enviar-sugestao .react-confirm-alert-body {
    width: 300px;
  }
}