div.container-trilhas-de-conhecimento {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
  padding-top: 80px;
}

div.container-trilhas-de-conhecimento img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 60;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-trilhas-de-conhecimento .container-titulo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 8%;
  background-color: #1c2732;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search {
  display: inline-flex;
  align-items: center;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 50px;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  cursor: pointer;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search:hover, 
div.container-trilhas-de-conhecimento .container-titulo .btn-search.open {
  max-width: 300px;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search .icon {
  font-size: 30px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-trilhas-de-conhecimento .container-titulo-trilha {
  position: relative;
  z-index: 2;
  background-color: #071b31;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 15px 8%;
}

div.container-trilhas-de-conhecimento .container-titulo-trilha p {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

div.container-trilhas-de-conhecimento .container-cursos {
  padding: 40px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: auto;
  align-self: center;
  justify-items: center;
  min-height: calc(100vh - 580px);
}

div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 8%;
  background-color: #17181c;
  color: white;
}

div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem button {
  padding: 12px 80px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  margin-left: 25px;
}

div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem button:hover {
  filter: brightness(140%);
}

@media (max-width: 1100px) {
  div.container-trilhas-de-conhecimento .container-cursos {
    padding: 40px 10px;
  }
}

@media (max-width: 980px) {
  div.container-trilhas-de-conhecimento .container-titulo {
    padding: 50px 10px;
  }
  div.container-trilhas-de-conhecimento .container-titulo-trilha {
    padding: 15px 10px;
  }
}

@media (max-width: 960px) {
  div.container-trilhas-de-conhecimento .container-cursos {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 645px) {
  div.container-trilhas-de-conhecimento .container-titulo p {
    width: 80%;
  }
}

@media (max-width: 520px) {
  div.container-trilhas-de-conhecimento .container-cursos {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  div.container-trilhas-de-conhecimento img.background {
    height: 300px;
    width: 300px;
  }
}

@media (max-width: 350px) {
  div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem {
    flex-direction: column;
  }
  div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem p {
    margin-bottom: 20px;
  }
}