div.accordion-item-perguntas-frequentes  {
  margin: 10px 0;
  text-align: start;
  background-color: #262f39;
  border-radius: 10px;
}

div.accordion-item-perguntas-frequentes .accordion-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  cursor: pointer;
}

div.accordion-item-perguntas-frequentes .accordion-titulo:hover {
  filter: brightness(140%);
}

div.accordion-item-perguntas-frequentes .accordion-titulo .icone-accordion {
  height: 25px;
  width: 25px;
  color: #fff;
}

div.accordion-item-perguntas-frequentes .accordion-titulo p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  text-align: justify;
}

div.accordion-item-perguntas-frequentes .accordion-conteudo {
  color: #fff;
  padding: 0 10px 5px 20px;
}