div.container-assinatura {
  position: relative;
  width: 60%;
  margin: 50px auto 30px auto;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: #071b31;
  z-index: 1;
}

div.container-assinatura .container-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: #0466db;
  border-radius: 19px;
  padding: 0 40px
}

div.container-assinatura .container-topo p {
  flex: 1;
  font-size: 24px;
  font-weight: bold;
}

div.container-assinatura .container-topo p:last-of-type {
  margin-left: 20px;
}

div.container-assinatura .container-topo button {
  display: block;
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #0466db;
  font-size: 18px;
  font-weight: bold;
}

div.container-assinatura .container-topo button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-assinatura .container-topo .icone-topo {
  height: 70px;
  width: 70px;
  color: black;
}

div.container-assinatura .container-dados-assinatura {
  padding: 30px 40px
}

div.container-assinatura .container-dados-assinatura h3 {
  margin-bottom: 5px;
}

div.container-assinatura .container-dados-assinatura p {
  color: #b7cff0;
}

div.container-assinatura .container-dados-assinatura p.subtitulo {
  font-weight: bold;
}

div.container-assinatura .container-dados-assinatura hr {
  width: 12%;
  margin: 25px 0;
  border-color: #0466db;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  justify-content: space-between;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 div {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 div p {
  color: white;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 p:not(p:first-of-type) {
  font-weight: bold;
  color: white;
}

div.container-cancelar-renovacao {
  width: 60%;
  margin: 0 auto;
}

div.container-cancelar-renovacao div {
  display: flex;
  align-items: center;
  width: 150px;
  cursor: pointer;
  padding: 5px;
  border: 2px solid #0466db;
  border-radius: 5px;
  color: red;
  font-size: 12px;
}

div.container-assinatura .container-topo button:hover, div.container-cancelar-renovacao div:hover,
div.react-confirm-alert-button-group button:hover {
  filter: brightness(140%);
}

div.container-cancelar-renovacao .icone-cancelar-renovacao {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.alert-sucesso-cancelar-renovacao {
  animation-duration: 0.2s;
}

.alert-sucesso-cancelar-renovacao .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-cancelar-renovacao button {
  font-size: 14px;
  font-weight: bold;
  background-color: #0466db;
}

@media (max-width: 850px) {
  div.container-assinatura, div.container-cancelar-renovacao {
    width: 80%;
  }
}

@media (max-width: 625px) {
  div.container-assinatura {
    width: 80%;
  }
}

@media (max-width: 525px) {
  div.container-assinatura .container-dados-assinatura .container-dados .col-3 {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    justify-content: space-between;
  }
}