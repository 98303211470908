div.container-pagina-curso-avulso {
  position: relative;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  padding: 130px 0 50px;
}

div.container-pagina-curso-avulso img.background-1 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 200px;
  left: -20px;
  opacity: 0.07;
}

div.container-pagina-curso-avulso img.background-2 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.07;
}

div.container-pagina-curso-avulso img.logo-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-pagina-curso-avulso button:hover {
  filter: brightness(140%);
}

div.container-pagina-curso-avulso header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 1.5%;
  background-color: transparent;
}

div.container-pagina-curso-avulso .container-logo a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

div.container-pagina-curso-avulso .container-logo a p {
  margin-left: 10px;
  font-weight: bold;
  font-size: 20px;
}

div.container-pagina-curso-avulso .container-logo a img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

div.container-pagina-curso-avulso header button {
  border: 3px solid #0466db;
  border-radius: 10px;
  padding: 8px 50px;
  font-weight: bold;
  font-size: 16px;
  color: #0466db;
  background-color: transparent;
}

div.container-pagina-curso-avulso header button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-pagina-curso-avulso form {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

div.container-pagina-curso-avulso form .titulo {
  width: 100%;
}

div.container-pagina-curso-avulso form .titulo div {
  position: relative;
  margin-bottom: 15px;
}

div.container-pagina-curso-avulso form .titulo div button {
  position: absolute;
  top: -10px;
  background-color: transparent;
  border: none;
}

div.container-pagina-curso-avulso form .titulo div button .icone-voltar {
  height: 40px;
  width: 40px;
  color: #0466db;
}

div.container-pagina-curso-avulso form .titulo div h2 {
  text-align: center;
}

div.container-pagina-curso-avulso form .titulo p.subtitulo {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

div.container-pagina-curso-avulso form .titulo .dados-compra {
  padding: 0 8%;
  margin-top: 15px;
}

div.container-pagina-curso-avulso form .titulo .dados-compra p {
  margin-bottom: 5px;
}

div.container-pagina-curso-avulso form .titulo .dados-compra div {
  display: flex;
  justify-content: space-between;
}

div.container-pagina-curso-avulso form .titulo .dados-compra div p {
  color: #0466db;
}

div.container-pagina-curso-avulso form .titulo .cartoes {
  padding: 0 8%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

div.container-pagina-curso-avulso form .titulo .cartoes .logo-cartao {
  height: 35px;
  width: 60px;
  margin: 0 3px;
}

div.container-pagina-curso-avulso form .titulo .cartoes .logo-cartao-visa {
  height: 70px;
  width: 70px;
  margin: 0 3px;
}

div.container-pagina-curso-avulso form .titulo .cartoes .logo-cartao-elo {
  height: 45px;
  width: 45px;
  margin: 0 3px;
}

div.container-pagina-curso-avulso form .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

div.container-pagina-curso-avulso form .container-inputs label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

div.container-pagina-curso-avulso form .container-inputs label input, 
div.container-pagina-curso-avulso form .container-inputs label select {
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

div.container-pagina-curso-avulso form .container-inputs label input[type="date"] { 
  padding: 13px 10px;
}

div.container-pagina-curso-avulso form .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

div.container-pagina-curso-avulso form .container-inputs button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-pagina-curso-avulso form .container-inputs a {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 15px 0;
}

div.container-pagina-curso-avulso form .container-inputs p {
  padding: 0 10%;
  text-align: center;
  color: #0466db;
}

@media (max-width: 950px) {
  div.container-pagina-curso-avulso form {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  div.container-pagina-curso-avulso form {
    width: 90%;
  }
  div.container-pagina-curso-avulso form .titulo .cartoes {
    padding: 0 2%;
  }
}

@media (max-width: 420px) {
  div.container-pagina-curso-avulso img.logo-whatsapp {
    bottom: 10px;
  }
}

@media (max-width: 340px) {
  div.container-pagina-curso-avulso img.background-1 {
    height: 150px;
    width: 150px;
  }
  div.container-pagina-curso-avulso img.background-2 {
    height: 150px;
    width: 150px;
  }
  div.container-pagina-curso-avulso header {
    flex-direction: column;
    align-items: center;
    padding: 0 1.5% 5px 1.5%;
  }
  div.container-pagina-curso-avulso header button {
    margin-top: 10px;
  }
}