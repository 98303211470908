.container-loading-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
}

.container-loading-login img.background-1 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 8%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

.container-loading-login img.background-2 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

.container-loading-login h1 {
  color: #fff;
  font-size: 45px;
  margin-bottom: 40px;
}


@media (max-width: 690px) {
  .container-loading-login img.background-1 {
    height: 400px;
    width: 400px;
  }
  .container-loading-login img.background-2 {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 400px) {
  .container-loading-login img.background-1 {
    height: 320px;
    width: 320px;
  }
  .container-loading-login img.background-2 {
    height: 200px;
    width: 200px;
  }
}