div.container-pagina-gerenciar-perfil-aluno {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
  padding-top: 80px;
}

div.container-pagina-gerenciar-perfil-aluno img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-gerenciar-perfil-aluno img.background {
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  top: 150px;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

@media (max-width: 480px) {
  div.container-pagina-gerenciar-perfil-aluno img.background {
    height: 300px;
    width: 300px;
  }
}