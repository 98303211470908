div.container-rodape img.logo-whatsapp {
  position: fixed;
  bottom: 90px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-rodape button:hover,  div.container-rodape .btn-whatsapp:hover{
  filter: brightness(140%);
}

div.container-rodape .container-assine-agora-fixo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 8% 0 8%;
  background-color: black;
  color: #fff;
  padding: 30px 20px;
}

div.container-rodape .container-assine-agora-fixo p.preco {
  font-size: 18px;
}

div.container-rodape .container-assine-agora-fixo p.preco i {
  font-style: normal;
  color: #0466db;
}

div.container-rodape .container-assine-agora-fixo div {
  width: 60%;
}

div.container-rodape .container-assine-agora-fixo div p {
  font-size: 18px;
  font-weight: bold;
}

div.container-rodape .container-assine-agora-fixo button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-rodape .container-assine-agora-fixo button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-rodape footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222c34;
  margin-top: 50px;
  padding-top: 25px;
  color: white;
  z-index: 1;
}

div.container-rodape footer .btn-whatsapp {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 45%;
  background-color: green;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-decoration: none;
  color: inherit;
}

div.container-rodape footer .btn-whatsapp img {
  height: 40px;
  width: 40px;
}

div.container-rodape footer h4 {
  margin-bottom: 10px;
}

div.container-rodape footer p {
  color: #aeb0b3;
  font-size: 12px;
  margin: 3px;
}

div.container-rodape footer div {
  width: 100%;
  background-color: #1e2830;
  margin-top: 15px;
  padding: 10px 30px;
}

div.container-rodape footer div p {
  font-size: 10px;
  float:right;
}

@media (max-width: 860px) {
  div.container-rodape .container-assine-agora-fixo {
    flex-direction: column;
    text-align: center;
  }
  div.container-rodape .container-assine-agora-fixo p.preco {
    margin: 15px;
  }
  div.container-rodape .container-assine-agora-fixo div {
    width: 100%;
  }
}

@media (max-width: 730px) {
  div.container-rodape footer .btn-whatsapp {
    width: 80%;
    text-align: center;
  }
}