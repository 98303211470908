@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -webkit-appearance: textfield;
          appearance: textfield;
}
div.container-pagina-inicial {
  background: linear-gradient(rgba(0, 8, 17, 255) 70%, transparent);
  background-color: #464c52;
  position: relative;
}

div.container-pagina-inicial button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.rodape-fixo-rolagem button a, div.container-cursos-destaque .conteudo-cursos-destaque .descricao button a {
  text-decoration: none;
  color: inherit;
}

/* Estilização do container de cursos em destaque */
div.container-cursos-destaque {
  position: relative;
  padding-top: 80px;
}

div.container-cursos-destaque img.background {
  height: 450px;
  width: 450px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 20px;
  left: -50px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-cursos-destaque .conteudo-cursos-destaque {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  color: white;
  z-index: 1;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao {
  position: relative;
  padding-top: 150px;
  z-index: 2;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao h1 {
  font-size: 40px;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao p {
  margin: 15px 0;
  font-size: 16px;
  max-width: 80%;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao p:last-child {
  margin: 5px 0;
  font-size: 14px;
}

div.container-cursos-destaque .conteudo-cursos-destaque .descricao button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-cursos-destaque .conteudo-cursos-destaque .cursos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  padding-top: 60px;
}

/* Estilização das seções do site */
div.container-secoes {
  margin-top: 70px;
  color: white;
}

div.container-secoes h1 {
  padding: 0 8%;
  margin-bottom: 20px;
}

div.container-secoes i {
  color: #0466db;
  font-style: normal;
}

/* Estilização dos motivos de escolher a CSA */
div.container-secoes .container-motivos-csa {
  padding: 0 8%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: 100px;
}

div.container-secoes .container-motivos-csa .card-motivo {
  background-color: #1c262f;
  padding: 20px 20px 30px 20px;
  border-radius: 10px;
}

div.container-secoes .container-motivos-csa .card-motivo h3 {
  margin-bottom: 15px;
}

div.container-secoes .container-motivos-csa .card-motivo p {
  text-align: justify;
}

/* Estilização de como irá aprender */
div.container-secoes .container-videos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  padding: 0 8%;
}

div.container-secoes .container-videos .container-video-aberto h2 {
  margin-bottom: 15px;
}

div.container-secoes .container-videos .container-video-aberto h3 {
  margin: 1px 0 20px;
}

div.container-secoes .container-videos .container-video-aberto iframe {
  height: 395px;
  width: 100%;
  border-radius: 10px;
}

div.container-secoes .container-videos .container-videos-lateral {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral {
  display: flex;
  cursor: pointer;
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral iframe {
  height: 130px;
  max-width: 150px;
  border-radius: 10px;
  pointer-events: none;
}

div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral h3 {
  margin: 10px 0 0 15px;
}

/* Estilização de diferenciais CSA */
div.container-secoes .accordion-diferenciais-csa {
  padding: 0 8%;
}

/* Estilização das declaraçõe do alunos da CSA */
div.container-secoes .container-depoimentos-alunos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #202c3c;
  margin: 0 8%;
  padding: 20px 0;
  border-radius: 10px;
}

div.container-secoes .container-depoimentos-alunos hr {
  margin: 20px 0;
  width: 70%;
  border-color: #4a6079;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item {
  display: flex;
  text-align: center;
  max-width: 500px;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item .foto-nome img {
  height: 90px;
  width: 90px;
  border: 1px solid #fff;
  border-radius: 50%;
  object-fit: cover;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item .foto-nome p {
  font-weight: bold;
}

div.container-secoes .container-depoimentos-alunos .depoimento-item .texto {
  margin-left: 15px;
}

/* Estilização do container de perguntas frequentes */
div.accordion-perguntas-frequetes {
  padding: 0 8%;
}

div.accordion-perguntas-frequetes h2 {
  color: #b7ceed;
}

div.accordion-perguntas-frequetes h2:not(:first-child) {
  margin-top: 30px;
}

/* Estilização do  rodapé fixo na rolage de assine agora */
div.rodape-fixo-rolagem {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 8%;
  background-color: #17181c;
  color: white;
}

div.rodape-fixo-rolagem button {
  padding: 12px 80px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  margin-left: 25px;
}


@media (max-width: 1100px) {
  div.container-secoes .container-videos .container-video-aberto video {
    height: 250px;
    max-width: 100%;
  }
}

@media (max-width: 860px) {
  div.container-cursos-destaque .conteudo-cursos-destaque {
    flex-direction: column;
    text-align: center;
  }
  div.container-cursos-destaque .conteudo-cursos-destaque .descricao p {
    max-width: 100%;
  }
  div.container-cursos-destaque .conteudo-cursos-destaque .cursos {
    margin: auto;
  }
  div.container-secoes {
    text-align: center;
  } 
  div.container-secoes .container-motivos-csa {
    grid-column-gap: 50px;
  }
  div.container-secoes .container-videos {
    grid-template-columns: 1fr;
  }
  div.container-secoes .container-videos .container-videos-lateral .detalhes-video-lateral {
    text-align: start;
  }
}

@media (max-width: 730px) {
  div.container-cursos-destaque .conteudo-cursos-destaque {
    padding: 0 2%;
  }
  div.container-secoes .container-motivos-csa .card-motivo {
    padding-bottom: 20px;
  }
}

@media (max-width: 510px) {
  div.container-cursos-destaque img.background {
    height: 350px;
    width: 350px;
  }
  div.container-cursos-destaque .conteudo-cursos-destaque .cursos {
    grid-template-columns: 1fr;
  }
  div.container-secoes .container-motivos-csa {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 350px) {
  div.container-pagina-inicial img.logo-whatsapp {
    bottom: 120px;
  }
  div.rodape-fixo-rolagem {
    flex-direction: column;
  }
  div.rodape-fixo-rolagem p {
    margin-bottom: 20px;
  }
}
div.container-cards-cursos {
  position: relative;
  border-radius: 10px;
  height: 270px;
  width: 220px;
  overflow: hidden;
}

div.container-cards-cursos img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

div.container-cards-cursos .numero-trilha-conhecimento {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 0 10px 10px 10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #0071f2;
}

div.container-cards-cursos .tag-curso {
  position: absolute;
  top: 40px;
  right: -50px;
  width: 100%;
  padding: 5px 0;
  opacity: 0.9;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

div.container-cards-cursos .acoes {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50% 12%;
  opacity: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.363);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
}

div.container-cards-cursos .acoes:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

div.container-cards-cursos .acoes:hover+.nome-curso {
  opacity: 0;
}

div.container-cards-cursos .acoes .btn-detalhes {
  width: 100%;
  background-color: white;
  border: 2px solid #0071f2;
  border-radius: 10px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  color: #0071f2;
}

div.container-cards-cursos .acoes .btn-detalhes:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-cards-cursos .nome-curso {
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: #fff;
  color: #0d78f2;
  font-weight: bold;
  padding: 8px 12px;
  max-width: 95%;
  box-shadow: -3px 3px 5px rgba(50, 50, 50, 0.77);
}

.modal-detalhes-curso {
  position: absolute;
  top: 40px;
  left: 100px;
  right: 100px;
  bottom: 40px;
  border-radius: 10px;
  background-color: #071b31;
  padding: 20px;
  color: #fff;
  overflow: auto;
  z-index: 99;
}

.modal-detalhes-curso .fechar-modal {
  display: flex;
  justify-content: flex-end;
}

.modal-detalhes-curso .fechar-modal button {
  height: 20px;
  width: 20px;
  border: none;
  background-color: #071b31;
  color: #fff;
}

.modal-detalhes-curso .fechar-modal button:hover {
  color: #d3d3d3;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .titulo-curso {
  text-align: center;
  margin-bottom: 30px;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes {
  margin-bottom: 30px;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes .titulo-secao {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes .container-card-descricao {
  background-color: #1d2936;
  padding: 18px 25px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .informacoes .container-card-descricao .carga-horaria {
  text-align: center;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .container-botao-inscreva {
  display: flex;
  justify-content: center;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .container-botao-inscreva .botao-inscreva-se {
  height: 50px;
  width: 250px;
  margin-bottom: 30px;
  padding: 10px 25px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.modal-detalhes-curso .conteudo-modal-detalhes-curso .container-botao-inscreva .botao-inscreva-se:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

.modal-detalhes-curso::-webkit-scrollbar {
  width: 10px;
}

.modal-detalhes-curso::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
}

.modal-detalhes-curso::-webkit-scrollbar-thumb {
  background: #3f4c5a; 
}

.modal-detalhes-curso::-webkit-scrollbar-thumb:hover {
  background: #343c46; 
}

.overlay-modal-detalhes-curso {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(40, 41, 44, 0.75);
  z-index: 99;
}

@media (max-width: 750px) {
  .modal-detalhes-curso {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border-radius: 10px;
    background-color: #071b31;
    padding: 20px;
    color: #fff;
    z-index: 99;
  }
}
div.container-carousel-cards-cursos {
  display: flex;
  margin: 0 3%;
}

div.container-carousel-cards-cursos .btn-controle-carousel {
  background-color: transparent;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div.container-carousel-cards-cursos .icone-controle-carousel {
  height: 30px;
  width: 30px;
  color: white;
}

div.container-carousel-cards-cursos .react-multi-carousel-list {
  flex: 1 1;
}

div.container-carousel-cards-cursos .react-multi-carousel-item {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}
div.accordion-item-diferenciais {
  margin-bottom: 10px;
  text-align: start;
}

div.accordion-item-diferenciais .accordion-titulo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

div.accordion-item-diferenciais .accordion-titulo:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.accordion-item-diferenciais .accordion-titulo .icone-accordion {
  height: 30px;
  width: 30px;
  color: #0466db;
}

div.accordion-item-diferenciais .accordion-titulo p {
  margin-left: 10px;
  font-weight: bold;
  color: #aeb0b3;
  font-size: 16px;
  font-style: italic;
  text-align: justify;
}

div.accordion-item-diferenciais .accordion-conteudo {
  color: #aeb0b3;
  margin: 0 0 15px 40px;
}
div.accordion-item-perguntas-frequentes  {
  margin: 10px 0;
  text-align: start;
  background-color: #262f39;
  border-radius: 10px;
}

div.accordion-item-perguntas-frequentes .accordion-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  cursor: pointer;
}

div.accordion-item-perguntas-frequentes .accordion-titulo:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.accordion-item-perguntas-frequentes .accordion-titulo .icone-accordion {
  height: 25px;
  width: 25px;
  color: #fff;
}

div.accordion-item-perguntas-frequentes .accordion-titulo p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  text-align: justify;
}

div.accordion-item-perguntas-frequentes .accordion-conteudo {
  color: #fff;
  padding: 0 10px 5px 20px;
}
div.container-quadro-trilhas-conhecimento {
  position: relative;
  margin: 0 8%;
  padding: 45px;
  background-color: #071b31;
  border: 1px solid gray;
  border-radius: 20px;
  z-index: 2;
}

div.container-quadro-trilhas-conhecimento .quadros {
  display: flex;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro {
  background-color: #222c34;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 15px;
  text-decoration: none;
  flex: 1 1;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-quadro-trilhas-conhecimento .quadros .quadro:not(.quadro:last-child) {
  margin-right: 30px;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro .titulo-quadro {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}

div.container-quadro-trilhas-conhecimento .quadros .quadro .titulo-quadro .icone-titulo {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  color: black;
  margin-right: 5px;
}

@media (max-width: 750px) {
  div.container-quadro-trilhas-conhecimento {
    padding: 45px 1%;
  }
  div.container-quadro-trilhas-conhecimento .quadros {
    flex-direction: column;
  }
  div.container-quadro-trilhas-conhecimento .quadros .quadro {
    width: 100%;
  }
  div.container-quadro-trilhas-conhecimento .quadros .quadro:not(.quadro:last-child) {
    margin: 0 0 10px 0;
  }
}
div.container-rodape img.logo-whatsapp {
  position: fixed;
  bottom: 90px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-rodape button:hover,  div.container-rodape .btn-whatsapp:hover{
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-rodape .container-assine-agora-fixo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 8% 0 8%;
  background-color: black;
  color: #fff;
  padding: 30px 20px;
}

div.container-rodape .container-assine-agora-fixo p.preco {
  font-size: 18px;
}

div.container-rodape .container-assine-agora-fixo p.preco i {
  font-style: normal;
  color: #0466db;
}

div.container-rodape .container-assine-agora-fixo div {
  width: 60%;
}

div.container-rodape .container-assine-agora-fixo div p {
  font-size: 18px;
  font-weight: bold;
}

div.container-rodape .container-assine-agora-fixo button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-rodape .container-assine-agora-fixo button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-rodape footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222c34;
  margin-top: 50px;
  padding-top: 25px;
  color: white;
  z-index: 1;
}

div.container-rodape footer .btn-whatsapp {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 45%;
  background-color: green;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  text-decoration: none;
  color: inherit;
}

div.container-rodape footer .btn-whatsapp img {
  height: 40px;
  width: 40px;
}

div.container-rodape footer h4 {
  margin-bottom: 10px;
}

div.container-rodape footer p {
  color: #aeb0b3;
  font-size: 12px;
  margin: 3px;
}

div.container-rodape footer div {
  width: 100%;
  background-color: #1e2830;
  margin-top: 15px;
  padding: 10px 30px;
}

div.container-rodape footer div p {
  font-size: 10px;
  float:right;
}

@media (max-width: 860px) {
  div.container-rodape .container-assine-agora-fixo {
    flex-direction: column;
    text-align: center;
  }
  div.container-rodape .container-assine-agora-fixo p.preco {
    margin: 15px;
  }
  div.container-rodape .container-assine-agora-fixo div {
    width: 100%;
  }
}

@media (max-width: 730px) {
  div.container-rodape footer .btn-whatsapp {
    width: 80%;
    text-align: center;
  }
}
div.container-trilhas-de-conhecimento {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
  padding-top: 80px;
}

div.container-trilhas-de-conhecimento img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 60;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-trilhas-de-conhecimento .container-titulo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 8%;
  background-color: #1c2732;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search {
  display: inline-flex;
  align-items: center;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 50px;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  cursor: pointer;
  transition: max-width 0.5s;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search:hover, 
div.container-trilhas-de-conhecimento .container-titulo .btn-search.open {
  max-width: 300px;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search .icon {
  font-size: 30px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-trilhas-de-conhecimento .container-titulo .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-trilhas-de-conhecimento .container-titulo-trilha {
  position: relative;
  z-index: 2;
  background-color: #071b31;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 15px 8%;
}

div.container-trilhas-de-conhecimento .container-titulo-trilha p {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

div.container-trilhas-de-conhecimento .container-cursos {
  padding: 40px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: auto;
  align-self: center;
  justify-items: center;
  min-height: calc(100vh - 580px);
}

div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 8%;
  background-color: #17181c;
  color: white;
}

div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem button {
  padding: 12px 80px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  margin-left: 25px;
}

div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

@media (max-width: 1100px) {
  div.container-trilhas-de-conhecimento .container-cursos {
    padding: 40px 10px;
  }
}

@media (max-width: 980px) {
  div.container-trilhas-de-conhecimento .container-titulo {
    padding: 50px 10px;
  }
  div.container-trilhas-de-conhecimento .container-titulo-trilha {
    padding: 15px 10px;
  }
}

@media (max-width: 960px) {
  div.container-trilhas-de-conhecimento .container-cursos {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 645px) {
  div.container-trilhas-de-conhecimento .container-titulo p {
    width: 80%;
  }
}

@media (max-width: 520px) {
  div.container-trilhas-de-conhecimento .container-cursos {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  div.container-trilhas-de-conhecimento img.background {
    height: 300px;
    width: 300px;
  }
}

@media (max-width: 350px) {
  div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem {
    flex-direction: column;
  }
  div.container-trilhas-de-conhecimento div.rodape-fixo-rolagem p {
    margin-bottom: 20px;
  }
}
body, html {
  min-height: 100vh;
}

div.container-manter-aluno-plataforma {
  position: relative;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  padding-bottom: 50px;
  min-height: 110vh;
}

div.container-manter-aluno-plataforma img.background-1 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 200px;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-manter-aluno-plataforma img.background-2 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-manter-aluno-plataforma img.logo-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-manter-aluno-plataforma button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-manter-aluno-plataforma header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 1.5%;
  background-color: transparent;
}

div.container-manter-aluno-plataforma .container-logo a img {
  height: 60px;
  width: 145px;
}

div.container-manter-aluno-plataforma header button {
  border: 3px solid #0466db;
  border-radius: 10px;
  padding: 8px 50px;
  font-weight: bold;
  font-size: 16px;
  color: #0466db;
  background-color: transparent;
}

div.container-manter-aluno-plataforma header button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

@media (max-width: 810px) {
  div.container-manter-aluno-plataforma img.background-1 {
    height: 300px;
    width: 300px;
  }
  div.container-manter-aluno-plataforma img.background-2 {
    height: 300px;
    width: 300px;
  }
}

@media (max-width: 730px) {
  body, html {
    height: auto;
    overflow: auto;
  }
  div.container-manter-aluno-plataforma {
    height: auto;
  }
}

@media (max-width: 420px) {
  div.container-manter-aluno-plataforma img.logo-whatsapp {
    bottom: 10px;
  }
  div.container-manter-aluno-plataforma img.background-1 {
    height: 150px;
    width: 150px;
  }
  div.container-manter-aluno-plataforma img.background-2 {
    height: 150px;
    width: 150px;
  }
}

@media (max-width: 340px) {
  div.container-manter-aluno-plataforma header {
    flex-direction: column;
    align-items: center;
    padding: 0 1.5% 5px 1.5%;
  }
  div.container-manter-aluno-plataforma header button {
    margin-top: 10px;
  }
}
form.container-formulario-cadastro-aluno {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-formulario-cadastro-aluno .titulo {
  width: 100%;
}

form.container-formulario-cadastro-aluno .titulo .topo {
  position: relative;
  margin-bottom: 15px;
}

form.container-formulario-cadastro-aluno .titulo .topo button {
  position: absolute;
  top: -10px;
  background-color: transparent;
  border: none;
}

form.container-formulario-cadastro-aluno .titulo .topo button .icone-voltar {
  height: 40px;
  width: 40px;
  color: #0466db;
}

form.container-formulario-cadastro-aluno .titulo .topo h2 {
  text-align: center;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login {
  padding: 0 8%;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  font-weight: bold;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button.btn-facebook {
  background-color: #1877f2;
  color: white;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button.btn-google {
  background-color: white;
  color: gray;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button.btn-linkedin {
  background-color: #0a66c2;
  color: white;
}

form.container-formulario-cadastro-aluno .titulo .botoes-login button .icone-btn-login {
  margin: 0 0 0 10px;
  width: 16px;
  height: 16px;
}

form.container-formulario-cadastro-aluno .titulo p {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

form.container-formulario-cadastro-aluno .titulo p.msg-continuar-cadastro {
  margin-top: 10px;
}

form.container-formulario-cadastro-aluno .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

form.container-formulario-cadastro-aluno .container-inputs label {
  width: 100%;
  margin-top: 20px;
}

form.container-formulario-cadastro-aluno .container-inputs label span {
  color: red;
}

form.container-formulario-cadastro-aluno .container-inputs label input {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-formulario-cadastro-aluno .container-inputs label input.senha-check {
  background-color: rgb(184, 255, 228);
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox {
  display: block;
  position: relative;
  padding: 3px 0 0 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox a {
  color: white;
  text-decoration: underline;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #c5ddff;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox input:checked ~ .checkmark {
  background-color: #0466db;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox input:checked ~ .checkmark:after {
  display: block;
}

form.container-formulario-cadastro-aluno .container-inputs label.labelCheckBox .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

form.container-formulario-cadastro-aluno .container-inputs .captcha {
  margin-top: 20px;
}

form.container-formulario-cadastro-aluno .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-formulario-cadastro-aluno .container-inputs button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

form.container-formulario-cadastro-aluno .container-inputs .aviso-erro-login {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-erro-cadastro {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-erro-cadastro .react-confirm-alert-body {
  color: #1c2732;
}

.alert-erro-cadastro button {
  font-size: 14px;
  font-weight: bold;
}

.alert-erro-cadastro button.botao-erro {
  background-color: #0466db;
}

@media (max-width: 950px) {
  form.container-formulario-cadastro-aluno {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  form.container-formulario-cadastro-aluno {
    width: 98%;
  }
  form.container-formulario-cadastro-aluno .titulo .botoes-login, 
  form.container-formulario-cadastro-aluno .titulo p,
  form.container-formulario-cadastro-aluno .container-inputs {
    padding: 0;
  }
}
.container-loader {
  position: absolute;
  z-index: 1;
  left: calc(50% - 131px);
  top: calc(50% - 102px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-loader h1 {
  margin-bottom: 40px;
  font-size: 50px;
  color: #fff;
}

form.container-formulario-pagamento-aluno {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-formulario-pagamento-aluno .titulo {
  width: 100%;
}

form.container-formulario-pagamento-aluno .titulo h2 {
  text-align: center;
  margin-bottom: 15px;
}

form.container-formulario-pagamento-aluno .titulo .informacoes {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

form.container-formulario-pagamento-aluno .titulo .detalhes-plano {
  margin-top: 20px;
}

form.container-formulario-pagamento-aluno .titulo .detalhes-plano p {
  margin: auto;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

form.container-formulario-pagamento-aluno .titulo div:last-child {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 8%;
  margin-top: 10px;
}

form.container-formulario-pagamento-aluno .titulo div:last-child .logo-cartao {
  height: 35px;
  width: 60px;
  margin: 3px;
}

form.container-formulario-pagamento-aluno .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

form.container-formulario-pagamento-aluno .container-inputs label {
  width: 100%;
  margin-top: 20px;
}

form.container-formulario-pagamento-aluno .container-inputs label span {
  color: red;
}

form.container-formulario-pagamento-aluno .container-inputs label input, 
form.container-formulario-pagamento-aluno .container-inputs label select {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-formulario-pagamento-aluno .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-formulario-pagamento-aluno .container-inputs .aviso-erro-pagamento {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-compra {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-compra .react-confirm-alert-body {
  color: #1c2732;
}

.alert-compra button {
  font-size: 14px;
  font-weight: bold;
}

.alert-compra button.botao-compra {
  background-color: #0466db;
}

@media (max-width: 950px) {
  form.container-formulario-pagamento-aluno {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  form.container-formulario-pagamento-aluno {
    width: 98%;
  }
  form.container-formulario-pagamento-aluno .titulo p,
  form.container-formulario-pagamento-aluno .titulo div:last-child,
  form.container-formulario-pagamento-aluno .container-inputs {
    padding: 0;
  }
}
div.container-planos-cadastro {
  padding: 20px 8%;
}

div.container-planos-cadastro .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

div.container-planos-cadastro .container-titulo h1 {
  white-space: nowrap;
}

div.container-planos-cadastro .container-titulo i {
  font-style: normal;
  color: #0466db;
}

div.container-planos-cadastro .container-titulo hr {
  height: 2px;
  width: 100%;
  border-color: #0466db;
  margin-left: 20px;
}

div.container-planos-cadastro .container-cards-planos {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

@media (max-width: 360px) {
  div.container-planos-cadastro {
    padding: 20px 1%;
  }
  div.container-planos-cadastro .container-titulo h1 {
    white-space: normal;
  }
}
div.container-card {
  position: relative;
  width: 300px;
  border: 1px solid #0466db;
  border-radius: 10px;
  background: linear-gradient(rgba(149, 154, 162, 255) 50%, transparent);
  background-color: #dcdee0;
  margin: 15px;
  z-index: 2;
}

div.container-card .titulo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0466db;
  border-radius: 9px 9px 0 0;
  text-align: center;
  padding: 10px 0;
  color: white;
}

div.container-card .titulo .icone-titulo {
  height: 30px;
  width: 30px;
  color: black;
  margin-right: 15px;
  margin-left: -45px;
}

div.container-card .separador {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px;
}

div.container-card .separador hr {
  flex: 1 1;
  background-color: #333;
  border: 1px solid #333;
}

div.container-card .separador p {
  margin: 0px 10px;
  font-weight: bold;
  font-size: 18px;
}

div.container-card .descricao-inclusao {
  margin: 0 30px;
}

div.container-card .descricao-inclusao div {
  display: flex;
  align-items: center;
  margin: 25px 0;
}

div.container-card .descricao-inclusao div .icone-checkbox {
  height: 20px;
  width: 20px;
  color: #0466db;
  margin-right: 5px;
}

div.container-card .preco {
  text-align: center;
  margin: 25px 0;
}

div.container-card .preco h1 {
  color: #0466db;
}

div.container-card .preco p {
  color: #00244d;
}

div.container-card .preco p.msg-continuar-gratis {
  color: red;
  font-weight: bold;
  margin-top: 5px;
}

div.container-card .botao {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

div.container-card .botao button {
  padding: 12px 45px;
  border-radius: 5px;
  border: none;
  background-color: #00244d;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.alert-sucesso-assinar-plano {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-sucesso-assinar-plano .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-assinar-plano button {
  font-size: 14px;
  font-weight: bold;
  background-color: #0466db;
}
div.container-sucesso-cadastro {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  text-align: center;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

div.container-sucesso-cadastro img {
  height: 100px;
  width: 100px;
  margin: 30px 0;
}

div.container-sucesso-cadastro p {
  font-size: 18px;
  width: 70%;
}

div.container-sucesso-cadastro p.aviso-participante {
  margin: 20px 0;
}

div.container-sucesso-cadastro p.aviso-participante i {
  font-style: normal;
  color: #ff0000;
}

div.container-sucesso-cadastro p.aviso-participante a {
  color:#0466db;
  text-decoration: underline;
}

div.container-sucesso-cadastro a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-sucesso-cadastro a button {
  /* width: 70%; */
  width: 200px;
  margin: 70px 0 30px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 950px) {
  div.container-sucesso-cadastro {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  div.container-sucesso-cadastro {
    width: 98%;
  }
  div.container-sucesso-cadastro p, div.container-sucesso-cadastro button {
    width: 90%;
  }
}
form.container-acessar-conta-aluno {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-acessar-conta-aluno .titulo {
  width: 100%;
}

form.container-acessar-conta-aluno .titulo {
  display: flex;
  flex-direction: column;
  text-align: center;
}

form.container-acessar-conta-aluno .titulo h2 {
  margin-bottom: 10px;
}

form.container-acessar-conta-aluno .titulo .botoes-login {
  padding: 0 8%;
}

form.container-acessar-conta-aluno .titulo .botoes-login button {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  font-weight: bold;
}

form.container-acessar-conta-aluno .titulo .botoes-login button p {
  margin: auto;
  padding-right: 30px;
  text-align: center;
}

form.container-acessar-conta-aluno .titulo .botoes-login button.btn-facebook {
  background-color: #1877f2;
  color: white;
}

form.container-acessar-conta-aluno .titulo .botoes-login button.btn-google {
  background-color: white;
  color: gray;
}

form.container-acessar-conta-aluno .titulo .botoes-login button.btn-linkedin {
  background-color: #0a66c2;
  color: white;
}

form.container-acessar-conta-aluno .titulo .botoes-login button .icone-btn-login {
  margin: 0 0 0 10px;
  width: 16px;
  height: 16px;
}

form.container-acessar-conta-aluno .titulo p.msg-credenciais {
  margin-top: 10px;
}

form.container-acessar-conta-aluno .container-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8%;
  margin-top: 10px;
}

form.container-acessar-conta-aluno .container-inputs label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

form.container-acessar-conta-aluno .container-inputs label input {
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-acessar-conta-aluno .container-inputs .esqueci-senha {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin: 5px 0;
  text-decoration: none;
}

form.container-acessar-conta-aluno .container-inputs .esqueci-senha:hover {
  text-decoration: underline;
}

form.container-acessar-conta-aluno .container-inputs .captcha {
  margin-top: 20px;
}

form.container-acessar-conta-aluno .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-acessar-conta-aluno .container-inputs a {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 15px 0;
}

form.container-acessar-conta-aluno .container-inputs .aviso-erro-login {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

@media (max-width: 950px) {
  form.container-acessar-conta-aluno {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 730px) {
  form.container-acessar-conta-aluno {
    height: 100vh;
  }
}

@media (max-width: 550px) {
  form.container-acessar-conta-aluno {
    width: 98%;
  }
  form.container-acessar-conta-aluno .titulo .botoes-login, form.container-acessar-conta-aluno .container-inputs {
    padding: 0;
  }
}
form.container-esqueci-senha {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  width: 40%;
  margin: 10% auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-esqueci-senha .titulo {
  width: 100%;
}

form.container-esqueci-senha .titulo {
  display: flex;
  flex-direction: column;
  text-align: center;
}

form.container-esqueci-senha .titulo h2 {
  margin-bottom: 10px;
}

form.container-esqueci-senha .titulo p.msg-credenciais {
  margin-top: 10px;
}

form.container-esqueci-senha .container-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8%;
  margin-top: 10px;
}

form.container-esqueci-senha .container-inputs label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

form.container-esqueci-senha .container-inputs label input {
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-esqueci-senha .container-inputs .aviso-erro-cadastro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

form.container-esqueci-senha .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

.alert-erro-enviar-email {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-erro-enviar-email .react-confirm-alert-body {
  color: #1c2732;
}

.alert-erro-enviar-email button {
  font-size: 14px;
  font-weight: bold;
}

.alert-erro-enviar-email button.botao-erro {
  background-color: #0466db;
}

.container-loading {
  height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.container-loading h1 {
  color: #fff;
  font-size: 45px;
  margin-bottom: 40px;
}

@media (max-width: 950px) {
  form.container-esqueci-senha {
    width: 70%;
  }
}

@media (max-width: 550px) {
  form.container-esqueci-senha {
    width: 98%;
    margin: 30% auto;
  }
  form.container-esqueci-senha .titulo .botoes-login, form.container-esqueci-senha .container-inputs {
    padding: 0;
  }
}
form.container-completar-cadastro {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}


form.container-completar-cadastro .titulo h2 {
  text-align: center;
}


form.container-completar-cadastro .titulo p {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

form.container-completar-cadastro .titulo p.msg-continuar-cadastro {
  margin-top: 10px;
}

form.container-completar-cadastro .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

form.container-completar-cadastro .container-inputs label {
  width: 100%;
  margin-top: 20px;
}

form.container-completar-cadastro .container-inputs label span {
  color: red;
}

form.container-completar-cadastro .container-inputs label input, 
form.container-completar-cadastro .container-inputs label select {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-completar-cadastro .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

form.container-completar-cadastro .container-inputs .aviso-erro-cadastro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-erro-completar-cadastro {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-erro-completar-cadastro .react-confirm-alert-body {
  color: #1c2732;
}

.alert-erro-completar-cadastro button {
  font-size: 14px;
  font-weight: bold;
}

.alert-erro-completar-cadastro button.botao-erro {
  background-color: #0466db;
}

@media (max-width: 950px) {
  form.container-completar-cadastro {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  form.container-completar-cadastro {
    width: 98%;
  }
  form.container-completar-cadastro .titulo p,
  form.container-completar-cadastro .container-inputs {
    padding: 0;
  }
}
.container-loading-login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
}

.container-loading-login img.background-1 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 8%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

.container-loading-login img.background-2 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

.container-loading-login h1 {
  color: #fff;
  font-size: 45px;
  margin-bottom: 40px;
}


@media (max-width: 690px) {
  .container-loading-login img.background-1 {
    height: 400px;
    width: 400px;
  }
  .container-loading-login img.background-2 {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 400px) {
  .container-loading-login img.background-1 {
    height: 320px;
    width: 320px;
  }
  .container-loading-login img.background-2 {
    height: 200px;
    width: 200px;
  }
}
div.container-erro-login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
}

div.container-erro-login img.background-1 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 8%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

div.container-erro-login img.background-2 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

div.container-erro-login img.logo-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-erro-login .quadro-erro {
  position: relative;
  z-index: 1;
  color: white;
  width: 50%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

div.container-erro-login .quadro-erro .detalhes-plano {
  width: 300px;
  border: 1px solid #0466db;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 10px;
}

div.container-erro-login .quadro-erro .detalhes-plano .titulo {
  font-weight: bold;
  font-size: 20px;
  margin: -1px -1px 5px 0;
  padding: 8px 0;
  background-color: #0466db;
  border-radius: 10px 10px 0 0;
}

div.container-erro-login .quadro-erro .detalhes-plano .subtitulo {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0 5px 0;
}

div.container-erro-login .quadro-erro .detalhes-plano .itens {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.container-erro-login .quadro-erro .detalhes-plano .itens .icone-checkbox {
  height: 18px;
  width: 18px;
  color: #0466db;
  margin-right: 5px;
}

div.container-erro-login .quadro-erro div h1 {
  text-align: center;
}

div.container-erro-login .quadro-erro div .mensagem {
  margin: 20px 0 30px 0;
  text-align: center;
}

div.container-erro-login .quadro-erro div .botoes {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}

div.container-erro-login .quadro-erro div .botoes button {
  width: 180px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

div.container-erro-login .quadro-erro div .botoes button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

@media (max-width: 830px) {
  div.container-erro-login .quadro-erro {
    width: 98%;
  }
}

@media (max-width: 690px) {
  div.container-erro-login img.background-1 {
    height: 400px;
    width: 400px;
  }
  div.container-erro-login img.background-2 {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 420px) {
  div.container-erro-login .quadro-erro div .botoes {
    flex-direction: column;
  }
  
  div.container-erro-login .quadro-erro div .botoes button {
    width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 400px) {
  div.container-erro-login img.background-1 {
    height: 320px;
    width: 320px;
  }
  div.container-erro-login img.background-2 {
    height: 200px;
    width: 200px;
  }
  div.container-erro-login img.logo-whatsapp {
    bottom: 10px;
  }
}
.menu-superior-pagina-inicial {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px 1.5%;
  background-color: rgba(0, 8, 17);
  z-index: 10;
}

.menu-superior-pagina-inicial button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

.menu-superior-pagina-inicial .nav {
  display: flex;
  flex: 1 1;
}

.menu-superior-pagina-inicial .nav-options {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.menu-superior-pagina-inicial .option {
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.menu-superior-pagina-inicial .container-logo a, .menu-superior-pagina-inicial .nav .container-acoes, 
.menu-superior-pagina-inicial .nav .container-acoes .assinante {
  display: flex;
  align-items: center;
  color: white;
}

.menu-superior-pagina-inicial .container-logo a img {
  height: 60px;
  width: 145px;
}

.menu-superior-pagina-inicial .nav .container-acoes div a {
  text-decoration: none;
  color: inherit;
}

.menu-superior-pagina-inicial .nav .container-acoes div a button {
  border-radius: 10px;
  padding: 6px 15px;
  border: 3px solid #b0c6e5;
  font-weight: bold;
  font-size: 16px;
  margin-right: 15px;
  color: #b0c6e5;
  border-color: #b0c6e5;
  background-color: transparent;
}

.menu-superior-pagina-inicial .nav .container-acoes div a:first-of-type button {
  color: #0466db;
  border-color: #0466db;
}

.menu-superior-pagina-inicial .nav .container-acoes .linha-vertical {
  height: 35px;
  border-left: 3px solid;
  border-color: #b0c6e5;
  margin-right: 15px;
}

.menu-superior-pagina-inicial .nav .container-acoes a {
  text-decoration: none;
  font-size: 16px;
}

.menu-superior-pagina-inicial .nav .container-acoes a .icone-usuario {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  color: #0466db;
}

.menu-superior-pagina-inicial .nav .container-acoes a:nth-child(3):hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
  text-decoration: underline;
}


@media (max-width: 730px) {
  .menu-superior-pagina-inicial {
    align-items: center;
    padding: 0 1.5% 5px 1.5%;
    background-color: rgba(0, 8, 17);
  }
  .menu-superior-pagina-inicial .nav-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    top: 60px;
    left: -100%;
    opacity: 0;
    z-index: 20;
    transition: all 0.5s ease;
  }
  .menu-superior-pagina-inicial .nav-options.active {
    background-color: #000811;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }
  .menu-superior-pagina-inicial .menu-icon {
    width: 45px;
    height: 45px;
    display: block;
    color: white;
  }
  .menu-superior-pagina-inicial .nav .container-acoes {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
  }
  .menu-superior-pagina-inicial .nav .container-acoes div {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: -70px;
  }
  .menu-superior-pagina-inicial .nav .container-acoes div button {
    padding: 15px;
    margin-bottom: 20px;
  }
  .menu-superior-pagina-inicial .nav .container-acoes div button a {
    display: block;
  }
  .menu-superior-pagina-inicial .nav .container-acoes .linha-vertical {
    display: none;
  }
}

@media (max-width: 510px) {
  .menu-superior-pagina-inicial {
    padding: 0 1.5%;
  }
}
div.header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2% 0 8%;
  height: 80px;
  z-index: 10;
  background-color: #000f20;
  border-bottom: 1px solid white;
}

div.header a {
  text-decoration: none;
  color: white
}

div.header .menu-icon {
  width: 45px;
  height: 45px;
  display: none;
}

div.header .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8%;
}

div.header .logo-container a img {
  height: 60px;
  width: 145px;
}

div.header .nav {
  display: flex;
  flex: 1 1;
  margin-top: 5px;
}

div.header .nav-options {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
}

div.header .option {
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

div.header .option:hover {
  border-bottom: 3px solid #0071f2;
}

div.header .activeLink {
  border-bottom: 6px solid #0071f2;
}

div.header .container-submenu {
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

div.header .container-submenu .btn-submenu {
  display: flex;
  align-items: center;
}

div.header .container-submenu .btn-submenu img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

div.header .container-submenu .btn-submenu p {
  margin-left: 5px;
}

div.header .icone-submenu {
  height: 25px;
  width: 25px;
  color: #0071f2;
}

div.header .container-submenu .submenu.fechado {
  display: none;
}

div.header .container-submenu .submenu.aberto {
  position: absolute;
  top: 65px;
  background-color: #000f20;
  border: 1px solid gray;
  border-radius: 5px;
}

div.header .container-submenu .submenu.aberto .opcao-submenu {
  display: flex;
  align-items: center;
  padding: 10px;
}

div.header .container-submenu .submenu.aberto .opcao-submenu:hover {
  background-color: #0071f28e;
}

div.header .container-submenu .submenu.aberto .opcao-submenu .icone-opcoes-submenu {
  height: 22px;
  width: 22px;
  margin-right: 5px;
  color: #fff;
}

div.header .container-submenu .submenu.aberto hr.divisoria-submenu {
  width: 90%;
  margin: 0 auto;
  color: gray;
}

div.header .opcao-desconectar {
  color: red;
}

@media (max-width: 980px) {
  div.header {
    padding: 0px 10px;
  }
  div.header .logo-container {
    margin-right: 2%;
  }
}

@media (max-width: 920px) {
  div.header .logo {
    width: 45px;
    height: 45px;
  }
  div.header .nav-options {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 230px;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
  }
  div.header .nav-options.active {
    background-color: #071b31;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }
  div.header .menu-icon {
    width: 45px;
    height: 45px;
    display: block;
    color: white;
  }
  div.header .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }
  div.header .activeLink {
    border-bottom: 3px solid #0071f2;
  }
  div.header .container-submenu .btn-submenu {
    margin-bottom: 10px;
  }
  div.header .container-submenu .submenu.aberto {
    top: 230px;
    border-top: none;
    border-radius: 0 0 5px 5px;
  }
}
div.container-pagina-inicio img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-inicio .container-banners {
  position: relative;
}

div.container-pagina-inicio .container-banners .img-banner {
  height: 100vh;
  object-fit: cover;
}

div.container-pagina-inicio .container-banners .informacoes {
  position: absolute;
  top: 35%;
  width: -webkit-min-content;
  width: min-content;
  margin-left: 8%;
  color: white;
  border-radius: 10px;
  padding: 15px;
  background: rgba(0, 17, 37, 0.507);
  box-shadow: 0 0px 10px 0 rgba(56, 56, 56, 0.5);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
  border: 1px solid rgba( 255, 255, 255, 0.18);
}

div.container-pagina-inicio .container-banners .informacoes h1 {
  font-size: 40px;
  white-space: nowrap;
}

div.container-pagina-inicio .container-banners .informacoes p {
  font-size: 18px;
  width: 60%;
  text-align: justify;
  margin-top: 10px;
}

div.container-pagina-inicio .container-banners .informacoes div {
  margin-top: 20px;
  display: flex;
}

div.container-pagina-inicio .container-banners .informacoes div .btn {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  background-color: #0071f2;
  border: 3px solid #0071f2;
  border-radius: 5px;
  padding: 12px 40px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

div.container-pagina-inicio .container-banners .informacoes div .btn.detalhes {
  background-color: transparent;
  border-color: white;
  margin-left: 20px;
}

div.container-pagina-inicio .container-banners .informacoes div .btn.assistir .icone-botao-informacao {
  font-size: 20px;
  margin-right: 5px;
}

div.container-pagina-inicio .container-banners .informacoes div .btn.assistir:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-pagina-inicio .container-banners .informacoes div .btn.detalhes:last-child:hover {
  background-color: rgba(0, 113, 242, 0.623);
}

div.container-pagina-inicio .container-banners .btn-search {
  position: absolute;
  top: 100px;
  right: 100px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 50px;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  transition: max-width 0.5s;
}

div.container-pagina-inicio .container-banners .btn-search:hover, 
div.container-pagina-inicio .container-banners .btn-search.open {
  max-width: 300px;
}

div.container-pagina-inicio .container-banners .btn-search .icon {
  font-size: 30px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-pagina-inicio .container-banners .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-pagina-inicio .container-cursos {
  position: relative;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-pagina-inicio .container-cursos img.background-1 {
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  top: 8%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

div.container-pagina-inicio .container-cursos img.background-2 {
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  top: 40%;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

/* Estilização das seções do site */
div.container-pagina-inicio .container-cursos .container-secoes-inicio {
  padding-top: 70px;
  color: white;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;
  margin-bottom: 20px;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo h1 {
  margin-right: 10px;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo i {
  color: #0466db;
  font-style: normal;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 16px;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo a .icone-ver-todos {
  height: 20px;
  width: 20px;
  color: #0466db;
}

div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo a:hover {
  text-decoration: underline;
}

@media (max-width: 920px) {
  div.container-pagina-inicio .container-banners .btn-search {
    right: 10px;
  }
}

@media (max-width: 730px) {
  div.container-pagina-inicio .container-cursos .container-secoes-inicio .titulo {
    padding: 0 2%;
  }  
}

@media (max-width: 660px) {
  div.container-pagina-inicio .container-banners .informacoes h1 {
    white-space: normal;
  }
}

@media (max-width: 510px) { 
  div.container-pagina-inicio .container-cursos img.background-1 {
    height: 300px;
    width: 300px;
    top: 1150px;
  }
  
  div.container-pagina-inicio .container-cursos img.background-2 {
    height: 200px;
    width: 200px;
    top: 1600px;
  }
}

@media (max-width: 420px) {
  div.container-pagina-inicio img.logo-whatsapp {
    bottom: 10px;
  }  
  div.container-pagina-inicio .container-banners .informacoes {
    padding: 0px 10px;
    text-align: center;
  }
  div.container-pagina-inicio .container-banners .informacoes p {
    width: 100%;
  }
  div.container-pagina-inicio .container-banners .informacoes div {
    flex-direction: column;
    align-items: center;
  }
  div.container-pagina-inicio .container-banners .informacoes div button {
    width: 200px;
    justify-content: center;
  }
  div.container-pagina-inicio .container-banners .informacoes div button:last-child {
    margin: 20px 0 0 0;
  }
}
div.container-carousel-cards-cursos-detalhes {
  position: relative;
  display: flex;
  margin: 0 7%;
  z-index: 1;
  cursor: grab;
}

div.container-carousel-cards-cursos-detalhes .react-multi-carousel-list {
  flex: 1 1;
  padding-bottom: 2px;
}

div.container-carousel-cards-cursos-detalhes .react-multi-carousel-item {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: grab;
}

@media (max-width: 730px) {
  div.container-carousel-cards-cursos-detalhes {
    margin: 0 2%;
  }  
}
div.container-cards-cursos-detalhes {
  position: relative;
  border-radius: 10px;
  height: 350px;
  width: 300px;
  overflow: hidden;
}

div.container-cards-cursos-detalhes img {
  height: 100%;
  width: 100%;
  border: 1px solid white;
  border-radius: 10px;
  object-fit: cover;
}

div.container-cards-cursos-detalhes .numero-trilha-conhecimento {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 0 10px 10px 10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #0071f2;
}

div.container-cards-cursos-detalhes .tag-curso {
  position: absolute;
  bottom: 270px;
  left: 80px;
  width: 100%;
  padding: 5px 0;
  opacity: 0.9;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

div.container-cards-cursos-detalhes .nome-curso {
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: #fff;
  color: #0d78f2;
  font-weight: bold;
  padding: 8px 12px;
  max-width: 95%;
  box-shadow: -3px 3px 5px rgba(50, 50, 50, 0.77);
}

div.container-cards-cursos-detalhes .acoes {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50% 12%;
  background-color: transparent;
  opacity: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.425);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
}

div.container-cards-cursos-detalhes .acoes:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

div.container-cards-cursos-detalhes .acoes:hover+.nome-curso {
  opacity: 0;
}

div.container-cards-cursos-detalhes .acoes .btn-acoes {
  text-decoration: none;
  text-align: center;
  color: inherit;
  width: 100%;
  background-color: white;
  border: 2px solid #0071f2;
  border-radius: 10px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #0071f2;
}

div.container-cards-cursos-detalhes .acoes .btn-acoes:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}
div.container-pagina-cursos {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-pagina-cursos img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-cursos img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 60;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-pagina-cursos .container-titulo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 8%;
  background-color: #1c2732;
}

div.container-pagina-cursos .container-titulo .btn-search {
  display: inline-flex;
  align-items: center;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 50px;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  cursor: pointer;
  transition: max-width 0.5s;
}

div.container-pagina-cursos .container-titulo .btn-search:hover, 
div.container-pagina-cursos .container-titulo .btn-search.open {
  max-width: 300px;
}

div.container-pagina-cursos .container-titulo .btn-search .icon {
  font-size: 30px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-pagina-cursos .container-titulo .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-pagina-cursos .container-abas {
  position: relative;
  z-index: 2;
  background-color: #071b31;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 15px 8%;
}

div.container-pagina-cursos .container-abas .opcoes-abas {
  display: flex;
}

div.container-pagina-cursos .container-abas .opcoes-abas p {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

div.container-pagina-cursos .container-abas .opcoes-abas p:hover {
  text-decoration: underline;
}

div.container-pagina-cursos .container-abas .opcoes-abas p.aba-selecionada {
  color: #0466db;
}

div.container-pagina-cursos .container-abas .opcoes-abas hr {
  height: 20px;
  margin: 0 10px;
}

div.container-pagina-cursos .container-cursos {
  padding: 40px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: auto;
  align-self: center;
  justify-items: center;
  min-height: calc(100vh - 585px);
}

div.container-pagina-cursos .container-trilhas-de-conhecimento h1 {
  padding: 0 8%;
  margin: 30px 0;
}

div.container-pagina-cursos .container-trilhas-de-conhecimento h1 i {
  color: #0466db;
  font-style: normal;
}

div.container-pagina-cursos .container-assine-agora-fixo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 8% 0 8%;
  background-color: black;
  color: #fff;
  padding: 30px 20px;
}

div.container-pagina-cursos .container-assine-agora-fixo p.preco {
  font-size: 18px;
}

div.container-pagina-cursos .container-assine-agora-fixo p.preco i {
  font-style: normal;
  color: #0466db;
}

div.container-pagina-cursos .container-assine-agora-fixo div {
  width: 60%;
}

div.container-pagina-cursos .container-assine-agora-fixo div p {
  font-size: 18px;
  font-weight: bold;
}

div.container-pagina-cursos .container-assine-agora-fixo button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-pagina-cursos .container-assine-agora-fixo button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-pagina-cursos .container-assine-agora-fixo button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

@media (max-width: 1100px) {
  div.container-pagina-cursos .container-cursos {
    padding: 40px 10px;
  }
}

@media (max-width: 980px) {
  div.container-pagina-cursos .container-titulo {
    padding: 50px 10px;
  }
  div.container-pagina-cursos .container-abas {
    padding: 15px 10px;
  }
}

@media (max-width: 860px) {
  div.container-pagina-cursos .container-assine-agora-fixo {
    flex-direction: column;
    text-align: center;
  }
  div.container-pagina-cursos .container-assine-agora-fixo p.preco {
    margin: 15px;
  }
  div.container-pagina-cursos .container-assine-agora-fixo div {
    width: 100%;
  }
}

@media (max-width: 960px) {
  div.container-pagina-cursos .container-cursos {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 645px) {
  div.container-pagina-cursos .container-titulo p {
    width: 80%;
  }
  div.container-pagina-cursos .container-cursos {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  div.container-pagina-cursos img.background {
    height: 350px;
    width: 350px;
  }
}
div.container-cards-turma-pos {
  position: relative;
  border-radius: 10px;
  height: 350px;
  width: 300px;
  overflow: hidden;
}

div.container-cards-turma-pos img {
  height: 100%;
  width: 100%;
  border: 1px solid white;
  border-radius: 10px;
  object-fit: cover;
}

div.container-cards-turma-pos .nome-turma {
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: #fff;
  color: #0d78f2;
  font-weight: bold;
  padding: 8px 12px;
  max-width: 95%;
  box-shadow: -3px 3px 5px rgba(50, 50, 50, 0.77);
}

div.container-cards-turma-pos .acoes {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50% 12%;
  background-color: transparent;
  opacity: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.425);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
}

div.container-cards-turma-pos .acoes:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

div.container-cards-turma-pos .acoes:hover+.nome-turma {
  opacity: 0;
}

div.container-cards-turma-pos .acoes .btn-acoes {
  text-decoration: none;
  text-align: center;
  color: inherit;
  width: 100%;
  background-color: white;
  border: 2px solid #0071f2;
  border-radius: 10px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #0071f2;
  cursor: pointer;
}

div.container-cards-turma-pos .acoes .btn-acoes:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}
div.container-pagina-planos {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
  padding-top: 80px;
}

div.container-pagina-planos img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-planos img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 60;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-pagina-planos .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin: 50px 8%;
}

div.container-pagina-planos .container-titulo h1 {
  white-space: nowrap;
}

div.container-pagina-planos .container-titulo i {
  font-style: normal;
  color: #0466db;
}

div.container-pagina-planos .container-titulo hr {
  height: 2px;
  width: 100%;
  border-color: #0466db;
  margin-left: 20px;
}

div.container-pagina-planos .container-cards-planos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: calc(100vh - 520px);
}

@media (max-width: 480px) {
  div.container-pagina-planos img.background {
    height: 350px;
    width: 350px;
  }
}

@media (max-width: 360px) {
  div.container-pagina-planos {
    padding: 20px 1%;
  }
  div.container-pagina-planos .container-titulo {
    margin: 70px 8% 50px 8%;
  }
  div.container-pagina-planos .container-titulo h1 {
    white-space: normal;
  }
}
div.container-forum-geral {
  position: relative;
  background-color: #000f20;
  padding-top: 40px;
}

div.container-forum-geral img.background-1 {
  position: absolute;
  height: 500px;
  width: 500px;
  border-radius: 50%;
  top: 10%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

div.container-forum-geral img.background-2 {
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  bottom: 2%;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
  z-index: 0;
}

div.container-forum-geral .quadro-forum-geral {
  position: relative;
  background-color: #1C2B3B;
  border: 1px solid gray;
  z-index: 5;
  margin: 0 4%;
}
div.container-listar-topicos .titulo-quadro {
  padding: 20px 10px 10px 10px;
}

div.container-listar-topicos .titulo-quadro .botao-criar-topico {
  display: flex;
  align-items: center;
  background-color: #0d78f2;
  padding: 8px 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

div.container-listar-topicos .titulo-quadro .botao-criar-topico:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-listar-topicos .titulo-quadro .botao-criar-topico .icone {
  height: 28px;
  width: 28px;
  margin-right: 10px;
  color: black;
}

div.container-listar-topicos .container-paginacao {
  display: flex;
  align-items: center;
}

div.container-listar-topicos .container-paginacao .quantidade {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
  flex: 1 1;
  text-align: right;
}

div.container-listar-topicos .container-paginacao .pagination {
  margin: 10px auto;
  display: flex;
  list-style: none;
  outline: none;
}

div.container-listar-topicos .container-paginacao .pagination > .active > a {
  background-color: #0d78f2;
  color: black;
}

div.container-listar-topicos .container-paginacao .pagination > li > a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

div.container-listar-topicos .container-paginacao .pagination > .active > a, 
div.container-listar-topicos .container-paginacao .pagination > .active > span, 
div.container-listar-topicos .container-paginacao .pagination > .active > a:hover, 
div.container-listar-topicos .container-paginacao .pagination > .active > span:hover, 
div.container-listar-topicos .container-paginacao .pagination > .active > a:focus, 
div.container-listar-topicos .container-paginacao .pagination > .active > span:focus {
  background-color: #0d78f2;
  border-color: #fff;
  outline: none;
}

div.container-listar-topicos .container-paginacao .pagination > li > a, 
div.container-listar-topicos .container-paginacao .pagination > li > span {
  background-color: #a3b9d8;
  color: black;
}

div.container-listar-topicos .container-paginacao .pagination > li > a:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-listar-topicos .container-paginacao .pagination > li:first-child > a, 
div.container-listar-topicos .container-paginacao .pagination > li:first-child > span, 
div.container-listar-topicos .container-paginacao .pagination > li:last-child > a, 
div.container-listar-topicos .container-paginacao .pagination > li:last-child > span {
  border-radius: 5px
}

div.container-listar-topicos .titulo-topico {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 3%;
  background-color: #0060B1;
}

div.container-listar-topicos .titulo-topico p {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

div.container-listar-topicos .titulo-topico .btn-search {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 45px;
  overflow: hidden;
  width: auto;
  max-width: 45px;
  transition: max-width 0.5s;
}

div.container-listar-topicos .titulo-topico .btn-search:hover, 
div.container-listar-topicos .titulo-topico .btn-search.open {
  max-width: 300px;
}

div.container-listar-topicos .titulo-topico .btn-search .icon {
  font-size: 25px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-listar-topicos .titulo-topico .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-listar-topicos .topico {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 3%;
  background-color: #EBF3FF;
  text-decoration: none;
  margin-bottom: 4px;
}

div.container-listar-topicos .topico:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
}

div.container-listar-topicos .icone-topico {
  height: 50px;
  width: 50px;
  color: black;
  margin-right: 15px;
}

div.container-listar-topicos .topico p {
  color: #1C2B3B;
  text-align: left;
}

div.container-listar-topicos .topico p:first-of-type {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}

div.container-listar-topicos .container-paginacao-rodape {
  margin: 10px 10px 30px 0;
}
div.container-criar-topico .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 3%;
  background-color: #0060B1;
}

div.container-criar-topico .titulo p {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

div.container-criar-topico .container-conteudo-topico {
  background-color: #ebf3ff;
  padding: 50px 3%;
}

div.container-criar-topico .container-conteudo-topico .texto-titulo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 40px;
}

div.container-criar-topico .container-conteudo-topico label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #1C2B3B;
  margin-bottom: 40px;
}

div.container-criar-topico .container-conteudo-topico label input {
  width: 50%;
  padding: 15px 10px;
  border: 1px solid gray;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 5px;
}

div.container-criar-topico .container-conteudo-topico label span {
  color: red;
}

div.container-criar-topico .container-conteudo-topico label .editor-texto {
  margin-top: 5px;
}

div.container-criar-topico .container-conteudo-topico .container-botoes {
  display: flex;
  justify-content: center;
}

div.container-criar-topico .container-conteudo-topico .container-botoes button {
  background-color: #00244d;
  padding: 12px 30px;
  border: 2px solid #00244d;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

div.container-criar-topico .container-conteudo-topico .container-botoes button:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-criar-topico .container-conteudo-topico .container-botoes button:first-of-type {
  margin-right: 15px;
  background-color: #fff;
  border: 2px solid #00244d;
  color: #00244d;
}

div.container-criar-topico .container-conteudo-topico .aviso-erro-topico {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.alert-criar-topico {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-criar-topico .react-confirm-alert-body {
  color: #1c2732;
}

.alert-criar-topico button {
  font-size: 14px;
  font-weight: bold;
}

.alert-criar-topico button.botao-alert-criar-topico {
  background-color: #0466db;
}


@media (max-width: 770px) {
  div.container-criar-topico .container-conteudo-topico label input {
    width: 100%;
  }
}

@media (max-width: 470px) {
  div.container-criar-topico .container-conteudo-topico .container-botoes {
    flex-direction: column;
  }
  div.container-criar-topico .container-conteudo-topico .container-botoes button {
    margin: 0 0 10px 0;
  }
  div.container-criar-topico .container-conteudo-topico .container-botoes button:first-of-type {
    margin-right: 0;
  }
}
div.container-visualizar-topico {
  background-color: #ebf3ff;
}

div.container-visualizar-topico .container-botao-criar-topico {
  background-color: #1C2B3B;
  padding: 20px 10px;
}

div.container-visualizar-topico .container-botao-criar-topico .botao-criar-topico {
  display: flex;
  align-items: center;
  background-color: #0d78f2;
  padding: 8px 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

div.container-visualizar-topico .container-botao-criar-topico .botao-criar-topico:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-visualizar-topico .container-botao-criar-topico .botao-criar-topico .icone {
  height: 28px;
  width: 28px;
  margin-right: 10px;
  color: black;
}

div.container-visualizar-topico .container-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 3%;
  background-color: #0060B1;
}

div.container-visualizar-topico .container-titulo p {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

div.container-visualizar-topico .container-detalhes-topico {
  padding-bottom: 20px;
}

div.container-visualizar-topico .container-detalhes-topico .banner {
  width: 100%;
  height: 230px;
  object-fit: cover;
}

div.container-visualizar-topico .container-detalhes-topico .banner-vazio {
  width: 100%;
  height: 230px;
  background-color: #00244d;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto {
  padding: 30px 3%;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .titulo {
  font-size: 24px;
  font-weight: bold;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .data {
  margin: 15px 0 40px 0;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .subtitulo {
  font-weight: bold;
  margin-bottom: 20px;
}

div.container-visualizar-topico .container-detalhes-topico .container-texto .texto {
  margin-top: 20px;
}

div.container-visualizar-topico .container-detalhes-topico hr {
  margin: 80px 0 40px 0;
  background-color: #c5ddff;
  padding: 3px;
  border: none;
}

div.container-visualizar-topico .container-detalhes-topico .btn-voltar {
  display: block;
  margin: auto;
  background-color: #fff;
  padding: 12px 100px;
  border: 2px solid #00244d;
  border-radius: 5px;
  color: #00244d;
  font-weight: bold;
  font-size: 16px;
}

div.container-visualizar-topico .container-detalhes-topico .btn-voltar:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-visualizar-topico .container-comentarios {
  padding: 0 3%;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios p {
  font-weight: bold;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios .acoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios .acoes button .icone {
  height: 18px;
  width: 18px;
  color: gray;
}

div.container-visualizar-topico .container-comentarios .titulo-comentarios .acoes button:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario {
  display: flex;
  border-radius: 8px;
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario .editor-texto-comentario {
  flex: 1 1;
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario .editor-texto-comentario .editor {
  margin-top: 20px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-novo-comentario .foto-perfil {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 10px 0 -20px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios {
  margin-top: 30px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .btn-responder-comentario {
  margin: 10px 0 0 0;
  font-size: 12px;
  text-decoration: underline;
  color: #00244d;
  cursor: pointer;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .btn-responder-comentario:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario {
  display: flex;
  background-color: #c5ddff;
  border-radius: 8px;
  padding: 20px 15px 8px 15px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario img {
  max-width: 100%;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .foto-perfil {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  margin: 0 10px 0 -40px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto {
  flex: 1 1;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor .nome {
  font-weight: bold;
  margin-bottom: 10px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor .data {
  font-size: 10px;
  color: gray;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div {
  display: flex;
  align-items: center;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div p {
  margin: 0;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  padding: 5px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes {
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px 0 5px 5px;
  padding: 5px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p {
  border: none;
  font-size: 12px;
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p:hover {
  text-decoration: underline;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p:last-of-type {
  margin-top: 5px;
  color: red;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-resposta-comentario {
  padding-left: 5px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-resposta-comentario .resposta-comentario {
  margin-top: 5px;
  margin-left: 10px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta {
  display: flex;
  margin-top: 10px;
  border-radius: 8px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .editor-texto-comentario {
  flex: 1 1;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .editor-texto-comentario .editor {
  background-color: #eaeaea;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .foto-perfil {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 10px 0 -20px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  margin-left: 5px;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta .icone {
  height: 18px;
  width: 18px;
  color: gray;
}

div.container-visualizar-topico .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
}

div.container-visualizar-topico .container-paginacao-comentarios {
  height: 30px;
  padding: 0 3% 10px 3%;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a {
  background-color: #0d78f2;
  color: black;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > span, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a:hover, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > span:hover, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > a:focus, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > .active > span:focus {
  background-color: #0d78f2;
  border-color: #fff;
  outline: none;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > span {
  background-color: #a3b9d8;
  color: black;
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li > a:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:first-child > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:first-child > span, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:last-child > a, 
div.container-visualizar-topico .container-paginacao-comentarios .pagination > li:last-child > span {
  border-radius: 5px
}

.alert-visualizar-topico {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-visualizar-topico .react-confirm-alert-body {
  color: #1c2732;
}

.alert-visualizar-topico button {
  font-size: 14px;
  font-weight: bold;
}

.alert-visualizar-topico button.botao-alert-visualizar-topico {
  background-color: #0466db;
}

.alert-visualizar-topico button.botao-confirmar {
  background-color: #cc3300;
}

div.container-detalhes-assistir {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-detalhes-assistir img.logo-whatsapp {
  bottom: 40px;
}

div.container-detalhes-assistir img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 100vh;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-detalhes-assistir button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-detalhes-assistir .container-banner-curso {
  position: relative;
  z-index: 2;
}

div.container-detalhes-assistir .container-banner-curso img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

div.container-detalhes-assistir .container-banner-curso h1 {
  position: absolute;
  top: calc(40% + 120px);
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 60px;
  text-align: center;
}

div.container-detalhes-assistir .container-assine-agora {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 8% 0 8%;
  background-color: black;
  color: #fff;
  padding: 30px 20px;
  z-index: 2;
}

div.container-detalhes-assistir .container-assine-agora p {
  font-size: 18px;
}

div.container-detalhes-assistir .container-assine-agora p:first-child {
  width: 50%;
  font-weight: bold;
}

div.container-detalhes-assistir .container-assine-agora p.preco i {
  font-style: normal;
  color: #0466db;
}

div.container-detalhes-assistir .container-assine-agora button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-detalhes-assistir .container-assine-agora button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-detalhes-assistir .container-assine-agora button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-detalhes-assistir .container-progresso {
  display: flex;
  justify-content: flex-end;
  padding: 0 8%;
  margin: 10px 0;
}

div.container-detalhes-assistir .container-progresso .quadro {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

div.container-detalhes-assistir .container-progresso .quadro p {
  font-size: 16px;
}

div.container-detalhes-assistir .container-progresso .quadro .progresso {
  height: 50px;
  width: 50px;
}

div.container-detalhes-assistir .container-progresso .quadro .progresso .CircularProgressbar-text {
  font-size: 30px;
  font-weight: bold;
  fill: #fff;
}

div.container-detalhes-assistir .container-progresso .quadro .progresso .CircularProgressbar-path {
  stroke: #008282;
}

div.container-detalhes-assistir .container-abas-aulas {
  position: relative;
  z-index: 2;
  padding: 0 8%;
  min-height: calc(100vh - 640px);
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas {
  display: flex;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas .aba-selecionada {
  background-color: #0d78f2;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas div {
  width: 200px;
  text-align: center;
  background-color: #071b31;
  border: 1px solid gray;
  padding: 10px 0;
  cursor: pointer;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas div:hover {
  text-decoration: underline;
}

div.container-detalhes-assistir .container-abas-aulas .opcoes-abas div p {
  font-weight: bold;
  font-size: 18px;
}

div.container-detalhes-assistir .container-conteudo-abas {
  background-color: #071b31;
  border: 1px solid gray;
  padding: 20px;
}

div.container-detalhes-assistir .container-conteudo-abas p.titulo-secao {
  font-size: 18px;
  font-weight: bold;
  margin: 30px 0 15px 0;
}

div.container-detalhes-assistir .container-conteudo-abas .container-card-descricao {
  background-color: #1d2936;
  padding: 18px 25px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 860px) {
  div.container-detalhes-assistir .container-assine-agora {
    flex-direction: column;
    text-align: center;
  }
  div.container-detalhes-assistir .container-assine-agora p:first-child {
    width: 100%;
  }
  div.container-detalhes-assistir .container-assine-agora p.preco {
    margin: 15px;
  }
}

@media (max-width: 640px) {
  div.container-detalhes-assistir .container-banner-curso h1 {
    top: calc(40% + 100px);
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  div.container-detalhes-assistir img.background {
    height: 340px;
    width: 340px;
  }
}

@media (max-width: 420px) {
  div.container-detalhes-assistir .container-assine-agora {
    margin: 40px 10px;
  }
  div.container-detalhes-assistir .container-abas-aulas {
    padding: 0 10px;
  }
  div.container-detalhes-assistir .container-conteudo-abas {
    padding: 20px 10px;
  }
}
div.container-detalhes-curso .container-horario-mensagem {
  display: flex;
  justify-content: space-between;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum {
  display: flex;
  flex-direction: column;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum button {
  height: 50px;
  width: 250px;
  margin-top: 20px;
  padding: 10px 25px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-detalhes-curso .container-horario-mensagem .container-botoes-increva-forum button.btn-inscreva:disabled:hover {
  -webkit-filter: none;
          filter: none;
  cursor: inherit;
}

div.container-detalhes-curso .container-horario-mensagem .carga-horaria {
  width: 50%;
}

div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno {
  background-color: #00244d;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 20px 25px;
  text-align: center;
}

div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno p {
  font-weight: bold;
}

div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno p:last-of-type {
  color: red;
}

@media (max-width: 790px) {
  div.container-detalhes-curso .container-horario-mensagem {
    flex-direction: column;
  }
  div.container-detalhes-curso .container-horario-mensagem .mensagem-aluno {
    order: -1;
  }
  div.container-detalhes-curso .container-horario-mensagem .carga-horaria {
    width: 100%;
  }
}
div.accordion-disciplinas {
  margin-bottom: 10px;
  text-align: start;
}

div.accordion-disciplinas .accordion-titulo {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background-color: #052c58;
  padding: 5px 25px;
  border-radius: 10px;
  z-index: 2;
}

div.accordion-disciplinas .accordion-titulo:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.accordion-disciplinas .accordion-titulo .icone-accordion {
  height: 40px;
  width: 40px;
  color: #fff;
}

div.accordion-disciplinas .accordion-titulo p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

div.accordion-disciplinas .accordion-conteudo {
  padding: 20px 20px 5px 20px;
  background-color: #052c58;
  margin-top: -5px;
  border-radius: 0 0 10px 10px;
}
div.accordion-assistir-aulas {
  margin-bottom: 10px;
  text-align: start;
}

div.accordion-assistir-aulas .accordion-titulo {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background-color: #0466db;
  padding: 5px 25px;
  border-radius: 10px;
  z-index: 2;
}

div.accordion-assistir-aulas .accordion-titulo:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.accordion-assistir-aulas .accordion-titulo .icone-accordion {
  height: 40px;
  width: 40px;
  color: #fff;
}

div.accordion-assistir-aulas .accordion-titulo p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

div.accordion-assistir-aulas .accordion-conteudo {
  padding: 10px 20px;
  background-color: #052c58;
  margin-top: -5px;
  border-radius: 0 0 10px 10px;
}

div.accordion-assistir-aulas .accordion-conteudo hr {
  color: gray;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0;
  padding: 0 20px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento {
  align-self: flex-end;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento.incompleto {
  visibility: hidden;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento .icone-andamento {
  height: 20px;
  width: 20px;
  margin-right: 3px;
  color: greenyellow;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento p {
  color: #0466db;
  font-weight: bold;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula {
  display: flex;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video {
  position: relative;
  overflow: hidden;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .vimeo {
  height: 100%;
  width: 280px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .vimeo .player {
  height: 100%;
  width: 100%;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .vimeo .player iframe {
  height: 100%;
  width: 100%;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .status-aula {
  position: absolute;
  bottom: 80px;
  left: 100px;
  width: 250px;
  padding: 5px 0;
  background-color: rgba(0, 15, 32, 0.900);
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-weight: bold;
  font-size: 16px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .status-aula p {
  color: green;
  text-align: center;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento {
  display: flex;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento button,
div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rebeccapurple;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: 110px;
  cursor: pointer;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-documento:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-documento .icone-documento {
  height: 50px;
  width: 50px;
  color: #fff;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-pdf {
  display: flex;
  margin-left: 110px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-pdf .icone-pdf {
  height: 70px;
  width: 70px;
  color: red;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula {
  width: 50%;
  text-align: center;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula .descricao {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-artigo {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  overflow: auto;
  z-index: 99;
}

.modal-artigo .fechar-modal {
  position: fixed;
  right: 60px;
}

.modal-artigo .fechar-modal button {
  height: 20px;
  width: 20px;
  border: none;
  background-color: #fff;
  color: gray;
}

.modal-artigo .fechar-modal button:hover {
  color: black;
}

.modal-artigo .titulo {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.modal-artigo .conteudo-modal {
  margin-top: 40px;
}

.modal-artigo::-webkit-scrollbar {
  width: 10px;
}

.modal-artigo::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
}

.modal-artigo::-webkit-scrollbar-thumb {
  background: #3f4c5a; 
}

.modal-artigo::-webkit-scrollbar-thumb:hover {
  background: #343c46; 
}

.overlay-modal-artigo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.596);
  z-index: 99;
}

@media (max-width: 990px) {
  div.accordion-assistir-aulas .accordion-conteudo {
    padding: 5px 0;
  }
}

@media (max-width: 845px) {
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula {
    flex-direction: column;
    align-items: center;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .andamento {
    align-self: auto;
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 750px) {
  .modal-artigo {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}

@media (max-width: 715px) {
  div.accordion-assistir-aulas .accordion-conteudo .container-aula {
    flex-direction: column;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula {
    width: 100%;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula p:first-child {
    margin: 10px 0;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-documento, 
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-pdf {
    margin-left: 0;
  }
}

@media (max-width: 350px) {
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video {
    height: 170px;
    width: 250px;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .player {
    height: 170px;
    width: 250px;
  } 
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .status-aula {
    bottom: 90px;
    left: 50px;
  }
}
div.container-detalhes-assistir-pos {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-detalhes-assistir-pos img.logo-whatsapp {
  bottom: 40px;
}

div.container-detalhes-assistir-pos img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 100vh;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-detalhes-assistir-pos button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-detalhes-assistir-pos .container-banner-curso {
  position: relative;
  z-index: 2;
}

div.container-detalhes-assistir-pos .container-banner-curso img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

div.container-detalhes-assistir-pos .container-banner-curso h1 {
  position: absolute;
  top: calc(40% + 120px);
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 60px;
  text-align: center;
}

div.container-detalhes-assistir-pos .container-nota-progresso {
  display: flex;
  justify-content: flex-end;
  padding: 0 8%;
  margin: 10px 0;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro:last-of-type {
  margin-left: 25px;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro .icone {
  height: 45px;
  width: 45px;
  margin-bottom: 5px;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro p {
  font-size: 16px;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro p span.aprovado {
  color: #40ad2b;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro p span.reprovado {
  color: red;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro .progresso {
  height: 50px;
  width: 50px;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro .progresso .CircularProgressbar-text {
  font-size: 30px;
  font-weight: bold;
  fill: #fff;
}

div.container-detalhes-assistir-pos .container-nota-progresso .quadro .progresso .CircularProgressbar-path {
  stroke: #008282;
}

div.container-detalhes-assistir-pos .container-abas-aulas {
  position: relative;
  z-index: 2;
  padding: 0 8%;
  min-height: calc(100vh - 640px);
}

div.container-detalhes-assistir-pos .container-abas-aulas .opcoes-abas {
  display: flex;
}

div.container-detalhes-assistir-pos .container-abas-aulas .opcoes-abas .aba-selecionada {
  background-color: #0d78f2;
}

div.container-detalhes-assistir-pos .container-abas-aulas .opcoes-abas div {
  width: 200px;
  text-align: center;
  background-color: #071b31;
  border: 1px solid gray;
  padding: 10px 0;
  cursor: pointer;
}

div.container-detalhes-assistir-pos .container-abas-aulas .opcoes-abas div:hover {
  text-decoration: underline;
}

div.container-detalhes-assistir-pos .container-abas-aulas .opcoes-abas div p {
  font-weight: bold;
  font-size: 18px;
}

div.container-detalhes-assistir-pos .container-conteudo-abas {
  background-color: #071b31;
  border: 1px solid gray;
  padding: 20px;
}

div.container-detalhes-assistir-pos .container-conteudo-abas p.titulo-secao {
  font-size: 18px;
  font-weight: bold;
  margin: 30px 0 15px 0;
}

div.container-detalhes-assistir-pos .container-conteudo-abas .container-card-descricao {
  background-color: #1d2936;
  padding: 18px 25px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 640px) {
  div.container-detalhes-assistir-pos .container-banner-curso h1 {
    top: calc(40% + 100px);
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  div.container-detalhes-assistir-pos img.background {
    height: 340px;
    width: 340px;
  }
}

@media (max-width: 420px) {
  div.container-detalhes-assistir-pos .container-abas-aulas {
    padding: 0 10px;
  }
  div.container-detalhes-assistir-pos .container-conteudo-abas {
    padding: 20px 10px;
  }
}
div.container-detalhes-disciplina {
  display: flex;
}

div.container-detalhes-disciplina .container-conteudos-disciplina {
  flex: 1 1;
}

div.container-detalhes-disciplina .btn-forum {
  height: 50px;
  width: 250px;
  margin: 20px 0 0 15px;
  padding: 10px 25px;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

div.container-detalhes-disciplina .btn-forum:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-detalhes-disciplina .btn-forum a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

@media (max-width: 790px) {
  div.container-detalhes-disciplina {
    flex-direction: column;
    align-items: center;
  }
  div.container-detalhes-disciplina .btn-forum {
    margin: 20px 0 0;
  }
}
div.accordion-assistir-aulas-pos {
  margin-bottom: 10px;
  text-align: start;
}

div.accordion-assistir-aulas-pos .accordion-titulo {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background-color: #0466db;
  padding: 5px 25px;
  border-radius: 10px;
  z-index: 2;
}

div.accordion-assistir-aulas-pos .accordion-titulo:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.accordion-assistir-aulas-pos .accordion-titulo .icone-accordion {
  height: 40px;
  width: 40px;
  color: #fff;
}

div.accordion-assistir-aulas-pos .accordion-titulo p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

div.accordion-assistir-aulas-pos .accordion-conteudo {
  padding: 10px 20px;
  background-color: #052c58;
  margin-top: -5px;
  border-radius: 0 0 10px 10px;
}

div.accordion-assistir-aulas-pos .accordion-conteudo hr {
  color: gray;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0;
  padding: 0 20px;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .andamento {
  align-self: flex-end;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .andamento.incompleto {
  visibility: hidden;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .andamento .icone-andamento {
  height: 20px;
  width: 20px;
  margin-right: 3px;
  color: greenyellow;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .andamento p {
  color: #0466db;
  font-weight: bold;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula {
  display: flex;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .video {
  position: relative;
  overflow: hidden;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .video .vimeo {
  height: 100%;
  width: 280px;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .video .vimeo .player {
  height: 100%;
  width: 100%;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .video .vimeo .player iframe {
  height: 100%;
  width: 100%;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento {
  display: flex;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento button,
div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rebeccapurple;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: 110px;
  cursor: pointer;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento .icone-documento {
  height: 50px;
  width: 50px;
  color: #fff;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-pdf {
  display: flex;
  margin-left: 110px;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-pdf .icone-pdf {
  height: 70px;
  width: 70px;
  color: red;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-conteudo-aula {
  width: 50%;
  text-align: center;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-conteudo-aula .descricao {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-artigo {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  overflow: auto;
  z-index: 99;
}

.modal-artigo .fechar-modal {
  position: fixed;
  right: 60px;
}

.modal-artigo .fechar-modal button {
  height: 20px;
  width: 20px;
  border: none;
  background-color: #fff;
  color: gray;
}

.modal-artigo .fechar-modal button:hover {
  color: black;
}

.modal-artigo .titulo {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.modal-artigo .conteudo-modal {
  margin-top: 40px;
}

.modal-artigo::-webkit-scrollbar {
  width: 10px;
}

.modal-artigo::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
}

.modal-artigo::-webkit-scrollbar-thumb {
  background: #3f4c5a; 
}

.modal-artigo::-webkit-scrollbar-thumb:hover {
  background: #343c46; 
}

.overlay-modal-artigo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.596);
  z-index: 99;
}

@media (max-width: 990px) {
  div.accordion-assistir-aulas-pos .accordion-conteudo {
    padding: 5px 0;
  }
}

@media (max-width: 845px) {
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula {
    flex-direction: column;
    align-items: center;
  }
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .andamento {
    align-self: auto;
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 750px) {
  .modal-artigo {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}

@media (max-width: 715px) {
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula {
    flex-direction: column;
  }
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-conteudo-aula {
    width: 100%;
  }
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-conteudo-aula p:first-child {
    margin: 10px 0;
  }
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento, 
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-pdf {
    margin-left: 0;
  }
}

@media (max-width: 350px) {
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .video {
    height: 170px;
    width: 250px;
  }
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .video .player {
    height: 170px;
    width: 250px;
  } 
  div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-video-aula .video .status-aula {
    bottom: 90px;
    left: 50px;
  }
}

div.container-pagina-turma-pos {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-pagina-turma-pos img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-turma-pos img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 60;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-pagina-turma-pos .container-titulo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 8%;
  background-color: #1c2732;
}

div.container-pagina-turma-pos .container-titulo .btn-search {
  display: inline-flex;
  align-items: center;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 50px;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  cursor: pointer;
  transition: max-width 0.5s;
}

div.container-pagina-turma-pos .container-titulo .btn-search:hover, 
div.container-pagina-turma-pos .container-titulo .btn-search.open {
  max-width: 300px;
}

div.container-pagina-turma-pos .container-titulo .btn-search .icon {
  font-size: 30px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-pagina-turma-pos .container-titulo .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-pagina-turma-pos .container-abas {
  position: relative;
  z-index: 2;
  background-color: #071b31;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 15px 8%;
}

div.container-pagina-turma-pos .container-abas .opcoes-abas {
  display: flex;
}

div.container-pagina-turma-pos .container-abas .opcoes-abas p {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

div.container-pagina-turma-pos .container-abas .opcoes-abas p:hover {
  text-decoration: underline;
}

div.container-pagina-turma-pos .container-abas .opcoes-abas p.aba-selecionada {
  color: #0466db;
}

div.container-pagina-turma-pos .container-abas .opcoes-abas hr {
  height: 20px;
  margin: 0 10px;
}

div.container-pagina-turma-pos .container-titulo-turma {
  padding: 0 8%;
}

div.container-pagina-turma-pos .container-titulo-turma h1 {
  margin: 30px 0;
  text-align: center;
}

div.container-pagina-turma-pos .container-titulo-turma .disciplinas-titulo {
  position: relative;
  background-color: #071b31;
  padding: 15px 0;
  z-index: 2;
}

div.container-pagina-turma-pos .container-titulo-turma .disciplinas-titulo p {
  font-size: 22px;
  font-weight: bold;
}

div.container-pagina-turma-pos .container-disciplinas {
  padding: 40px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: auto;
  align-self: center;
  justify-items: center;
  min-height: calc(100vh - 585px);
}

div.container-pagina-turma-pos .container-assine-agora-fixo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 8% 0 8%;
  background-color: black;
  color: #fff;
  padding: 30px 20px;
}

div.container-pagina-turma-pos .container-assine-agora-fixo p.preco {
  font-size: 18px;
}

div.container-pagina-turma-pos .container-assine-agora-fixo p.preco i {
  font-style: normal;
  color: #0466db;
}

div.container-pagina-turma-pos .container-assine-agora-fixo div {
  width: 60%;
}

div.container-pagina-turma-pos .container-assine-agora-fixo div p {
  font-size: 18px;
  font-weight: bold;
}

div.container-pagina-turma-pos .container-assine-agora-fixo button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-pagina-turma-pos .container-assine-agora-fixo button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-pagina-turma-pos .container-assine-agora-fixo button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

@media (max-width: 1100px) {
  div.container-pagina-turma-pos .container-disciplinas {
    padding: 40px 10px;
  }
}

@media (max-width: 980px) {
  div.container-pagina-turma-pos .container-titulo {
    padding: 50px 10px;
  }
  div.container-pagina-turma-pos .container-abas {
    padding: 15px 10px;
  }
}

@media (max-width: 860px) {
  div.container-pagina-turma-pos .container-assine-agora-fixo {
    flex-direction: column;
    text-align: center;
  }
  div.container-pagina-turma-pos .container-assine-agora-fixo p.preco {
    margin: 15px;
  }
  div.container-pagina-turma-pos .container-assine-agora-fixo div {
    width: 100%;
  }
}

@media (max-width: 960px) {
  div.container-pagina-turma-pos .container-disciplinas {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 645px) {
  div.container-pagina-turma-pos .container-titulo p {
    width: 80%;
  }
  div.container-pagina-turma-pos .container-disciplinas {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  div.container-pagina-turma-pos img.background {
    height: 350px;
    width: 350px;
  }
}
div.container-cards-cursos-disciplinas-pos {
  position: relative;
  border-radius: 10px;
  height: 350px;
  width: 300px;
  overflow: hidden;
}

div.container-cards-cursos-disciplinas-pos img {
  height: 100%;
  width: 100%;
  border: 1px solid white;
  border-radius: 10px;
  object-fit: cover;
}

div.container-cards-cursos-disciplinas-pos .posicao-disciplina {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 0 10px 10px 10px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #0071f2;
}

div.container-cards-cursos-disciplinas-pos .tag-status {
  position: absolute;
  bottom: 270px;
  left: 80px;
  width: 100%;
  padding: 5px 0;
  opacity: 0.9;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  background-color: #00122c;
  color: #0071f2;
}

div.container-cards-cursos-disciplinas-pos .tag-status.aprovado {
  color: #40ad2b;
}

div.container-cards-cursos-disciplinas-pos .tag-status.reprovado {
  color: red;
}

div.container-cards-cursos-disciplinas-pos .nome-curso {
  position: absolute;
  bottom: 30px;
  right: 0;
  background-color: #fff;
  color: #0d78f2;
  font-weight: bold;
  padding: 8px 12px;
  max-width: 95%;
  box-shadow: -3px 3px 5px rgba(50, 50, 50, 0.77);
}

div.container-cards-cursos-disciplinas-pos .acoes {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50% 12%;
  background-color: transparent;
  opacity: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.425);
  backdrop-filter: blur( 1.5px);
  -webkit-backdrop-filter: blur( 1.5px);
}

div.container-cards-cursos-disciplinas-pos .acoes:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

div.container-cards-cursos-disciplinas-pos .acoes:hover+.nome-curso {
  opacity: 0;
}

div.container-cards-cursos-disciplinas-pos .acoes .btn-acoes {
  text-decoration: none;
  text-align: center;
  color: inherit;
  width: 100%;
  background-color: white;
  border: 2px solid #0071f2;
  border-radius: 10px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #0071f2;
}

div.container-cards-cursos-disciplinas-pos .acoes .btn-acoes:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}
div.container-pagina-curso-avulso {
  position: relative;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  padding: 130px 0 50px;
}

div.container-pagina-curso-avulso img.background-1 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 200px;
  left: -20px;
  opacity: 0.07;
}

div.container-pagina-curso-avulso img.background-2 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.07;
}

div.container-pagina-curso-avulso img.logo-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-pagina-curso-avulso button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-pagina-curso-avulso header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 1.5%;
  background-color: transparent;
}

div.container-pagina-curso-avulso .container-logo a {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

div.container-pagina-curso-avulso .container-logo a p {
  margin-left: 10px;
  font-weight: bold;
  font-size: 20px;
}

div.container-pagina-curso-avulso .container-logo a img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

div.container-pagina-curso-avulso header button {
  border: 3px solid #0466db;
  border-radius: 10px;
  padding: 8px 50px;
  font-weight: bold;
  font-size: 16px;
  color: #0466db;
  background-color: transparent;
}

div.container-pagina-curso-avulso header button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-pagina-curso-avulso form {
  position: relative;
  z-index: 1;
  color: white;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

div.container-pagina-curso-avulso form .titulo {
  width: 100%;
}

div.container-pagina-curso-avulso form .titulo div {
  position: relative;
  margin-bottom: 15px;
}

div.container-pagina-curso-avulso form .titulo div button {
  position: absolute;
  top: -10px;
  background-color: transparent;
  border: none;
}

div.container-pagina-curso-avulso form .titulo div button .icone-voltar {
  height: 40px;
  width: 40px;
  color: #0466db;
}

div.container-pagina-curso-avulso form .titulo div h2 {
  text-align: center;
}

div.container-pagina-curso-avulso form .titulo p.subtitulo {
  padding: 0 8%;
  margin: auto;
  text-align: center;
}

div.container-pagina-curso-avulso form .titulo .dados-compra {
  padding: 0 8%;
  margin-top: 15px;
}

div.container-pagina-curso-avulso form .titulo .dados-compra p {
  margin-bottom: 5px;
}

div.container-pagina-curso-avulso form .titulo .dados-compra div {
  display: flex;
  justify-content: space-between;
}

div.container-pagina-curso-avulso form .titulo .dados-compra div p {
  color: #0466db;
}

div.container-pagina-curso-avulso form .titulo .cartoes {
  padding: 0 8%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

div.container-pagina-curso-avulso form .titulo .cartoes .logo-cartao {
  height: 35px;
  width: 60px;
  margin: 0 3px;
}

div.container-pagina-curso-avulso form .titulo .cartoes .logo-cartao-visa {
  height: 70px;
  width: 70px;
  margin: 0 3px;
}

div.container-pagina-curso-avulso form .titulo .cartoes .logo-cartao-elo {
  height: 45px;
  width: 45px;
  margin: 0 3px;
}

div.container-pagina-curso-avulso form .container-inputs {
  display: flex;
  flex-direction: column;
  padding: 0 8%;
}

div.container-pagina-curso-avulso form .container-inputs label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

div.container-pagina-curso-avulso form .container-inputs label input, 
div.container-pagina-curso-avulso form .container-inputs label select {
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

div.container-pagina-curso-avulso form .container-inputs label input[type="date"] { 
  padding: 13px 10px;
}

div.container-pagina-curso-avulso form .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

div.container-pagina-curso-avulso form .container-inputs button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-pagina-curso-avulso form .container-inputs a {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 15px 0;
}

div.container-pagina-curso-avulso form .container-inputs p {
  padding: 0 10%;
  text-align: center;
  color: #0466db;
}

@media (max-width: 950px) {
  div.container-pagina-curso-avulso form {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  div.container-pagina-curso-avulso form {
    width: 90%;
  }
  div.container-pagina-curso-avulso form .titulo .cartoes {
    padding: 0 2%;
  }
}

@media (max-width: 420px) {
  div.container-pagina-curso-avulso img.logo-whatsapp {
    bottom: 10px;
  }
}

@media (max-width: 340px) {
  div.container-pagina-curso-avulso img.background-1 {
    height: 150px;
    width: 150px;
  }
  div.container-pagina-curso-avulso img.background-2 {
    height: 150px;
    width: 150px;
  }
  div.container-pagina-curso-avulso header {
    flex-direction: column;
    align-items: center;
    padding: 0 1.5% 5px 1.5%;
  }
  div.container-pagina-curso-avulso header button {
    margin-top: 10px;
  }
}
div.container-forum-curso {
  background-size: cover;
  background-attachment: fixed;
}

div.container-forum-curso .container-visualizar-topico-forum-curso {
  background-color: #fff;
  margin: 0 15%;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico {
  padding-bottom: 20px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico .banner {
  width: 100%;
  height: 230px;
  object-fit: cover;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico .container-texto {
  padding: 30px 3%;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico .container-texto .titulo {
  font-size: 24px;
  font-weight: bold;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico .container-texto .data {
  margin: 15px 0 40px 0;
  font-size: 12px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico .container-texto .subtitulo {
  font-weight: bold;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico .container-texto .texto {
  margin-top: 20px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-detalhes-topico hr {
  margin: 40px 0 20px;
  background-color: #c5ddff;
  padding: 3px;
  border: none;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios {
  padding: 0 3%;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .titulo-comentarios {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .titulo-comentarios p {
  font-weight: bold;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .titulo-comentarios .acoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .titulo-comentarios .acoes button .icone {
  height: 18px;
  width: 18px;
  color: gray;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .titulo-comentarios .acoes button:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-novo-comentario {
  display: flex;
  border-radius: 8px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-novo-comentario .editor-texto-comentario {
  flex: 1 1;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-novo-comentario .editor-texto-comentario .editor {
  margin-top: 20px;
  background-color: #eaeaea;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-novo-comentario .foto-perfil {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 10px 0 -20px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios {
  margin-top: 30px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .btn-responder-comentario {
  margin: 10px 0 0 0;
  font-size: 12px;
  text-decoration: underline;
  color: #00244d;
  cursor: pointer;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .btn-responder-comentario:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario {
  display: flex;
  background-color: #c5ddff;
  border-radius: 8px;
  padding: 20px 15px 8px 15px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario img {
  max-width: 100%;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .foto-perfil {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  margin: 0 10px 0 -40px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto {
  flex: 1 1;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor .nome {
  font-weight: bold;
  margin-bottom: 10px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor .data {
  font-size: 10px;
  color: gray;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div {
  display: flex;
  align-items: center;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div p {
  margin: 0;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  padding: 5px;
  background-color: #eaeaea;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes {
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px 0 5px 5px;
  padding: 5px;
  background-color: #eaeaea;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p {
  border: none;
  font-size: 12px;
  background-color: #eaeaea;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p:hover {
  text-decoration: underline;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .comentario .texto .autor div .btn-acoes .submenu-acoes p:last-of-type {
  margin-top: 5px;
  color: red;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-resposta-comentario {
  padding-left: 5px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-resposta-comentario .resposta-comentario {
  margin-top: 5px;
  margin-left: 10px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta {
  display: flex;
  margin-top: 10px;
  border-radius: 8px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .editor-texto-comentario {
  flex: 1 1;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .editor-texto-comentario .editor {
  background-color: #eaeaea;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .foto-perfil {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 10px 0 -20px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  margin-left: 5px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta .icone {
  height: 18px;
  width: 18px;
  color: gray;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-comentarios .container-lista-comentarios .container-comentarios .container-nova-resposta .btn-postar-resposta:hover {
  -webkit-filter: brightness(105%);
          filter: brightness(105%);
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios {
  height: 30px;
  padding: 0 3% 10px 3%;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination {
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > .active > a {
  background-color: #0d78f2;
  color: black;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li > a {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  margin: 0 2px;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > .active > a, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > .active > span, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > .active > a:hover, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > .active > span:hover, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > .active > a:focus, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > .active > span:focus {
  background-color: #0d78f2;
  border-color: #fff;
  outline: none;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li > a, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li > span {
  background-color: #a3b9d8;
  color: black;
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li > a:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li:first-child > a, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li:first-child > span, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li:last-child > a, 
div.container-forum-curso .container-visualizar-topico-forum-curso .container-paginacao-comentarios .pagination > li:last-child > span {
  border-radius: 5px
}

.alert-visualizar-topico-forum-curso {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-visualizar-topico-forum-curso .react-confirm-alert-body {
  color: #1c2732;
}

.alert-visualizar-topico-forum-curso button {
  font-size: 14px;
  font-weight: bold;
}

.alert-visualizar-topico-forum-curso button.botao-alert-visualizar-topico-forum-curso {
  background-color: #0466db;
}

.alert-visualizar-topico-forum-curso button.botao-confirmar {
  background-color: #cc3300;
}


@media (max-width: 720px) {
  div.container-forum-curso .container-visualizar-topico-forum-curso {
    margin: 0 3%;
  }
}
div.container-pagina-gerenciar-perfil-aluno {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
  padding-top: 80px;
}

div.container-pagina-gerenciar-perfil-aluno img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-gerenciar-perfil-aluno img.background {
  position: absolute;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  top: 150px;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

@media (max-width: 480px) {
  div.container-pagina-gerenciar-perfil-aluno img.background {
    height: 300px;
    width: 300px;
  }
}
div.container-meu-perfil {
  position: relative;
  width: 60%;
  margin: 50px auto;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: #071b31;
  z-index: 1;
  padding-bottom: 30px;
}

div.container-meu-perfil .container-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: #0466db;
  border-radius: 19px;
  padding: 0 20px;
}

div.container-meu-perfil .container-topo p {
  flex: 1 1;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

div.container-meu-perfil .container-topo .container-icone-notificacao {
  position: relative;
  display: flex;
  cursor: pointer;
}

div.container-meu-perfil .container-topo .container-icone-notificacao:hover {
  color: red;
}

div.container-meu-perfil .container-topo .container-icone-notificacao .icone-notificacao {
  height: 40px;
  width: 40px;
  color: black;
}

div.container-meu-perfil .container-topo .container-icone-notificacao div {
  position: absolute;
  top: -5px;
  left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: black;
  font-size: 12px;
  font-weight: bold;
}

div.container-meu-perfil .container-topo .container-notificacao-completa {
  display: none;
}

div.container-meu-perfil .container-topo .container-notificacao-completa-aberta {
  position: absolute;
  right: -180px;
  top: -20px;
  width: 200px;
  background-color: #c5ddff;
  color: #0466db;
  padding: 8px;
  border-radius: 10px;
}

div.container-meu-perfil .container-topo .container-notificacao-completa-aberta p {
  font-size: 12px;
}

div.container-meu-perfil .container-informacoes {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 40px 10px 40px;
}

div.container-meu-perfil .container-informacoes .foto-perfil img {
  height: 170px;
  width: 170px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -60px;
}

div.container-meu-perfil .container-informacoes .foto-perfil, 
div.container-meu-perfil .container-informacoes .dados, 
div.container-meu-perfil .container-informacoes .icone-acoes {
  flex: 1 1;
}

div.container-meu-perfil .container-informacoes .dados {
  padding-right: 5px;
  font-size: 16px;
}

div.container-meu-perfil .container-informacoes .dados p {
  font-weight: bold;
  margin: 20px 0;
}

div.container-meu-perfil .container-informacoes .dados p:first-of-type {
  margin-top: 0;
}

div.container-meu-perfil .container-informacoes .icones-acoes {
  display: flex;
  width: 160px;
}

div.container-meu-perfil .container-informacoes .icones-acoes a {
  height: 30px;
}

div.container-meu-perfil .container-informacoes .icones-acoes .icone {
  height: 30px;
  width: 30px;
  margin: 0 2px;
  color: white;
}

div.container-meu-perfil .container-informacoes .icones-acoes .icone:hover {
  cursor: pointer;
  color: #0466db;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro {
  position: relative;
  display: flex;
  height: 30px;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro:hover .msg-atencao{
  text-decoration: underline;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro div {
  position: absolute;
  top: -5px;
  left: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: red;
  font-size: 12px;
  font-weight: bold;
}

div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro .msg-atencao {
  position: absolute;
  top: -15px;
  left: 40px;
  height: auto;
  padding: 8px 10px;
  background-color: #c5ddff;
  white-space: nowrap;
  border-radius: 10px;
  color: #0466db;
  font-weight: bold;
  text-decoration: none;
}

div.container-meu-perfil  .assinatura {
  display: inline-flex;
  align-items: center;
  padding-left: 40px;
  color: #0466db;
}

div.container-meu-perfil  .assinatura .icone-cartao {
  height: 35px;
  width: 35px;
  margin-right: 5px;
  color: white;
}

div.container-meu-perfil  .assinatura p {
  font-size: 16px;
  font-weight: bold;
}

div.container-meu-perfil  .assinatura:hover {
  cursor: pointer;
  color: white;
}

@media (max-width: 980px) {
  div.container-meu-perfil .container-informacoes {
    flex-direction: column;
    align-items: center;
  }
  div.container-meu-perfil .container-topo .container-notificacao-completa-aberta {
    right: 0;
    top: 65px;
  }
  div.container-meu-perfil .container-informacoes .foto-perfil img {
    margin-top: -20px;
  }
  div.container-meu-perfil .container-informacoes .dados {
    margin-top: 10px;
    padding-right: 0;
    text-align: center;
  }
  div.container-meu-perfil .container-informacoes .icones-acoes {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  div.container-meu-perfil {
    width: 80%;
  }
}

@media (max-width: 450px) {
  div.container-meu-perfil .container-informacoes .icones-acoes .container-icone-cadastro .msg-atencao {
    white-space: normal;
  }
  div.container-meu-perfil  .assinatura {
    margin-top: 20px;
    padding-left: 10px;
  }
}
div.container-dados-aluno {
  position: relative;
  width: 60%;
  margin: 50px auto;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: #071b31;
  z-index: 1;
}

div.container-dados-aluno .container-topo {
  display: flex;
  align-items: center;
  height: 90px;
  background-color: #0466db;
  border-radius: 19px;
  padding: 0 20px;
}

div.container-dados-aluno .container-topo p {
  font-size: 24px;
  font-weight: bold;
}

div.container-dados-aluno form {
  padding: 0 40px;
}

div.container-dados-aluno form hr {
  margin: 20px 0;
  border-color: #436a9f;
}

div.container-dados-aluno form button {
  display: block;
  margin: 30px auto;
  width: 45%;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0466db;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

div.container-dados-aluno form button:last-of-type {
  margin: -10px auto 20px auto;
}

div.container-dados-aluno form button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-dados-aluno form label {
  width: 100%;
}

div.container-dados-aluno form label span {
  color: red;
}

div.container-dados-aluno form label input, div.container-dados-aluno form label select {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  margin-top: 3px;
}

div.container-dados-aluno form label input::-webkit-input-placeholder {
  color: #8a8a8a;
  font-style: italic;
}

div.container-dados-aluno form label input:-ms-input-placeholder {
  color: #8a8a8a;
  font-style: italic;
}

div.container-dados-aluno form label input::placeholder {
  color: #8a8a8a;
  font-style: italic;
}

div.container-dados-aluno form label input[type="date"] { 
  padding: 13px 10px;
}

div.container-dados-aluno form label input.senha-check {
  background-color: rgb(184, 255, 228);
}

div.container-dados-aluno form .titulo {
  color: #436a9f;
  font-weight: bold;
  font-size: 16px;
  margin: 30px 0;
}

div.container-dados-aluno form .col-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
}

div.container-dados-aluno form .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
  grid-row-gap: 20px;
  justify-content: space-between;
}

div.container-dados-aluno form .col-2:last-of-type {
  margin-top: 20px;
}

div.container-dados-aluno form .col-1 .label-linkedin .input-linkedin {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  margin-top: 3px;
}

div.container-dados-aluno form .col-1 .label-linkedin .input-linkedin input {
  flex: 1 1;
  margin-top: 0;
}

div.container-dados-aluno form .col-1 .label-linkedin .input-linkedin .icone-linkedin {
  height: 35px;
  width: 35px;
  color: #0466db;
}

div.container-dados-aluno form .col-1 .label-linkedin span {
  font-size: 10px;
  font-style: italic;
  color: #8a8a8a;
}

div.container-dados-aluno form .col-1 .label-linkedin span i {
  font-style: normal;
  font-weight: bold;
  color: #0466db;
}

div.container-dados-aluno form .col-1 label.input-file {
  cursor: pointer;
  width: 220px;
  margin-bottom: 20px;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil {
  display: flex;
  align-items: center;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil .foto {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  background-color: white;
  border: 2px solid #0466db;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil .foto .icone-usuario {
  height: 60px;
  width: 60px;
  color: black;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil p {
  margin-left: 10px;
  color: #8a8a8a;
  font-style: italic;
  cursor: pointer;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil p:hover {
  text-decoration: underline;
}

div.container-dados-aluno form .col-1 label.input-file input {
  display: none;
}

.alert-sucesso-salvar-dados, .alert-erro-salvar-dados {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-sucesso-salvar-dados .react-confirm-alert-body, .alert-erro-salvar-dados .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-salvar-dados button, .alert-erro-salvar-dados button {
  font-size: 14px;
  font-weight: bold;
}

.alert-sucesso-salvar-dados button.botao-confirmar {
  background-color: #cc3300;
}

.alert-sucesso-salvar-dados button.botao-sucesso, .alert-erro-salvar-dados button.botao-erro {
  background-color: #0466db;
}

@media (max-width: 940px) {
  div.container-dados-aluno {
    width: 98%;
  }
  div.container-dados-aluno form .col-1, div.container-dados-aluno form .col-2 {
    grid-column-gap: 3%;
  }
}

@media (max-width: 700px) {
  div.container-dados-aluno form .col-1, div.container-dados-aluno form .col-2 {
    grid-template-columns: 1fr;
  }
  div.container-dados-aluno form button {
    width: 80%;
  }
}

@media (max-width: 370px) {
  div.container-dados-aluno .container-topo {
    padding: 0 10px;
  }
  div.container-dados-aluno form {
    padding: 0 10px;
  }
  div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil {
    flex-direction: column;
  }
  div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil p {
    margin: 10px 0 0 0;
  }
  div.container-dados-aluno form label input[type="date"] { 
    padding: 13px 0px 13px 10px;
  } 
}
div.container-assinatura {
  position: relative;
  width: 60%;
  margin: 50px auto 30px auto;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: #071b31;
  z-index: 1;
}

div.container-assinatura .container-topo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: #0466db;
  border-radius: 19px;
  padding: 0 40px
}

div.container-assinatura .container-topo p {
  flex: 1 1;
  font-size: 24px;
  font-weight: bold;
}

div.container-assinatura .container-topo p:last-of-type {
  margin-left: 20px;
}

div.container-assinatura .container-topo button {
  display: block;
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: #0466db;
  font-size: 18px;
  font-weight: bold;
}

div.container-assinatura .container-topo button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-assinatura .container-topo .icone-topo {
  height: 70px;
  width: 70px;
  color: black;
}

div.container-assinatura .container-dados-assinatura {
  padding: 30px 40px
}

div.container-assinatura .container-dados-assinatura h3 {
  margin-bottom: 5px;
}

div.container-assinatura .container-dados-assinatura p {
  color: #b7cff0;
}

div.container-assinatura .container-dados-assinatura p.subtitulo {
  font-weight: bold;
}

div.container-assinatura .container-dados-assinatura hr {
  width: 12%;
  margin: 25px 0;
  border-color: #0466db;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  justify-content: space-between;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 div {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 div p {
  color: white;
}

div.container-assinatura .container-dados-assinatura .container-dados .col-3 p:not(p:first-of-type) {
  font-weight: bold;
  color: white;
}

div.container-cancelar-renovacao {
  width: 60%;
  margin: 0 auto;
}

div.container-cancelar-renovacao div {
  display: flex;
  align-items: center;
  width: 150px;
  cursor: pointer;
  padding: 5px;
  border: 2px solid #0466db;
  border-radius: 5px;
  color: red;
  font-size: 12px;
}

div.container-assinatura .container-topo button:hover, div.container-cancelar-renovacao div:hover,
div.react-confirm-alert-button-group button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

div.container-cancelar-renovacao .icone-cancelar-renovacao {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.alert-sucesso-cancelar-renovacao {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-sucesso-cancelar-renovacao .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-cancelar-renovacao button {
  font-size: 14px;
  font-weight: bold;
  background-color: #0466db;
}

@media (max-width: 850px) {
  div.container-assinatura, div.container-cancelar-renovacao {
    width: 80%;
  }
}

@media (max-width: 625px) {
  div.container-assinatura {
    width: 80%;
  }
}

@media (max-width: 525px) {
  div.container-assinatura .container-dados-assinatura .container-dados .col-3 {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    justify-content: space-between;
  }
}
div.container-meu-progresso {
  margin-bottom: 70px;
  min-height: calc(100vh - 400px);
}

div.container-meu-progresso .container-topo {
  display: flex;
  align-items: center;
  padding: 60px 5%;
  background-color: #1c2732;
}

div.container-meu-progresso .container-topo div {
  background-color: white;
  height: 40px;
  width: 40px;
  padding: 2px;
  border-radius: 3px;
  margin-right: 25px;
}

div.container-meu-progresso .container-topo div .icone-meu-progresso {
  height: 40px;
  width: 35px;
  color: black;
}

div.container-meu-progresso .container-topo p {
  font-size: 24px;
  font-weight: bold;
}

div.container-meu-progresso .titulo-topico {
  margin: 40px 0;
  text-align: center;
}

div.container-meu-progresso .container-aulas {
  margin-bottom: 30px;
  padding: 0 6%;
}

div.container-meu-progresso hr {
  border-color: gray;
}

@media (max-width: 980px) {
  div.container-meu-progresso .container-topo {
    padding: 60px 2%;
  }
  div.container-meu-progresso .container-aulas {
    padding: 0 2%;
  }
}
div.container-carousel-cards-aulas {
  position: relative;
  z-index: 1;
  cursor: grab;
}

div.container-carousel-cards-aulas .react-multi-carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

div.container-carousel-cards-aulas .container-card-curso {
  max-width: 300px;
}

div.container-carousel-cards-aulas .container-card-curso .titulo-progresso {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}
div.container-central-ajuda .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 8%;
  background-color: #1c2732;
}

div.container-central-ajuda .container-titulo .titulo {
  display: flex;
  align-items: center;
}

div.container-central-ajuda .container-titulo .titulo .icone-titulo {
  height: 45px;
  width: 45px;
  margin-right: 10px;
}

div.container-central-ajuda .container-titulo .titulo p {
  font-size: 26px;
  font-weight: bold;
}

div.container-central-ajuda .container-titulo .subtitulo {
  font-size: 16px;
}

div.container-central-ajuda .container-informacoes {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  z-index: 5;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes {
  width: 70%;
  margin-top: 50px;
  color: white;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes h1 {
  margin-bottom: 50px;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes i {
  color: #0466db;
  font-style: normal;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes .accordion-perguntas-frequetes {
  padding: 0;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes .accordion-perguntas-frequetes h2 {
  color: #b7ceed;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes .accordion-perguntas-frequetes h2:not(:first-child) {
  margin-top: 30px;
}

div.container-central-ajuda .container-informacoes .container-contato {
  margin-left: 10px;
}

div.container-central-ajuda .container-informacoes .container-contato a {
  text-decoration: none;
  color: #fff;
}

div.container-central-ajuda .container-informacoes .container-contato a:hover {
  text-decoration: underline;
}

div.container-central-ajuda .container-informacoes .container-contato .botao-contato div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8% 0;
  height: 150px;
  width: 250px;
  margin: 60px 0;
  border: 3px solid #0466db;
  border-radius: 10px;
}

div.container-central-ajuda .container-informacoes .container-contato .botao-contato div .logo {
  height: 60px;
  width: 60px;
}

div.container-central-ajuda .container-informacoes .container-contato .botao-contato div p {
  font-weight: bold;
  font-size: 22px;
}

@media (max-width: 980px) {
  div.container-central-ajuda .container-titulo {
    padding: 50px 10px;
  }
  div.container-central-ajuda .container-informacoes {
    padding: 0 10px;
  }
  div.container-central-ajuda .container-informacoes .container-perguntas-frequentes {
    text-align: center;
  } 
}

@media (max-width: 800px) {
  div.container-central-ajuda .container-informacoes {
    flex-direction: column;
    align-items: center;
  }
  div.container-central-ajuda .container-informacoes .container-perguntas-frequentes {
    width: 100%;
  }
  div.container-central-ajuda .container-informacoes .container-contato {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-left: 0;
  }
}

@media (max-width: 645px) {
  div.container-central-ajuda .container-titulo p {
    width: 80%;
  }
}

@media (max-width: 550px) {
  div.container-central-ajuda .container-informacoes .container-contato{
    flex-direction: column;
    align-items: center;
  }
  div.container-central-ajuda .container-informacoes .container-contato .botao-contato div {
    margin: 20px 0;
  }
}
div.container-sugestao-critica .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 8%;
  background-color: #1c2732;
}

div.container-sugestao-critica .container-titulo .titulo {
  display: flex;
  align-items: center;
}

div.container-sugestao-critica .container-titulo .titulo .icone-titulo {
  height: 45px;
  width: 45px;
  margin-right: 10px;
}

div.container-sugestao-critica .container-titulo .titulo p {
  font-size: 26px;
  font-weight: bold;
}

div.container-sugestao-critica .container-titulo .subtitulo {
  font-size: 16px;
}

div.container-sugestao-critica .container-quadro-texto {
  position: relative;
  padding: 0 8%;
  z-index: 5;
}

div.container-sugestao-critica .container-quadro-texto .titulo-quadro {
  display: flex;
  align-items: center;
  margin: 50px 0;
}

div.container-sugestao-critica .container-quadro-texto .titulo-quadro .icone {
  height: 60px;
  width: 60px;
  margin-right: 15px;
}

div.container-sugestao-critica .container-quadro-texto .titulo-quadro p {
  font-size: 24px;
  font-weight: bold;
}

div.container-sugestao-critica .container-quadro-texto .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.container-sugestao-critica .container-quadro-texto .quadro textarea {
  width: 100%;
  background-color: #C5DDFF;
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  resize: none;
}

div.container-sugestao-critica .container-quadro-texto .quadro textarea::-webkit-input-placeholder {
  color: #1c2732;
}

div.container-sugestao-critica .container-quadro-texto .quadro textarea:-ms-input-placeholder {
  color: #1c2732;
}

div.container-sugestao-critica .container-quadro-texto .quadro textarea::placeholder {
  color: #1c2732;
}

div.container-sugestao-critica .container-quadro-texto .quadro button {
  margin-top: 40px;
  width: 30%;
  padding: 10px 0;
  border: none;
  border-radius: 10px;
  background-color: #0466db;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

div.container-sugestao-critica .container-quadro-texto .quadro .aviso-erro-envio {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

div.container-sugestao-critica .container-quadro-texto .quadro button:hover {
  -webkit-filter: brightness(140%);
          filter: brightness(140%);
}

.alert-sucesso-enviar-sugestao {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.alert-sucesso-enviar-sugestao .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-enviar-sugestao button {
  font-size: 14px;
  font-weight: bold;
}

.alert-sucesso-enviar-sugestao button.botao-confirmar {
  background-color: #0466db;
}

@media (max-width: 980px) {
  div.container-sugestao-critica .container-titulo {
    padding: 50px 10px;
  }
  div.container-sugestao-critica .container-quadro-texto {
    padding: 0 10px;
  }
}

@media (max-width: 645px) {
  div.container-sugestao-critica .container-titulo p {
    width: 80%;
  }
}

@media (max-width: 420px) {
  .alert-sucesso-enviar-sugestao .react-confirm-alert-body {
    width: 300px;
  }
}
