form.container-esqueci-senha {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  width: 40%;
  margin: 10% auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

form.container-esqueci-senha .titulo {
  width: 100%;
}

form.container-esqueci-senha .titulo {
  display: flex;
  flex-direction: column;
  text-align: center;
}

form.container-esqueci-senha .titulo h2 {
  margin-bottom: 10px;
}

form.container-esqueci-senha .titulo p.msg-credenciais {
  margin-top: 10px;
}

form.container-esqueci-senha .container-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8%;
  margin-top: 10px;
}

form.container-esqueci-senha .container-inputs label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

form.container-esqueci-senha .container-inputs label input {
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  background-color: #c5ddff;
  margin-top: 3px;
}

form.container-esqueci-senha .container-inputs .aviso-erro-cadastro {
  margin: 10px 0 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

form.container-esqueci-senha .container-inputs button {
  width: 100%;
  margin: 30px auto 0;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

.alert-erro-enviar-email {
  animation-duration: 0.2s;
}

.alert-erro-enviar-email .react-confirm-alert-body {
  color: #1c2732;
}

.alert-erro-enviar-email button {
  font-size: 14px;
  font-weight: bold;
}

.alert-erro-enviar-email button.botao-erro {
  background-color: #0466db;
}

.container-loading {
  height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.container-loading h1 {
  color: #fff;
  font-size: 45px;
  margin-bottom: 40px;
}

@media (max-width: 950px) {
  form.container-esqueci-senha {
    width: 70%;
  }
}

@media (max-width: 550px) {
  form.container-esqueci-senha {
    width: 98%;
    margin: 30% auto;
  }
  form.container-esqueci-senha .titulo .botoes-login, form.container-esqueci-senha .container-inputs {
    padding: 0;
  }
}