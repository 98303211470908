div.container-card {
  position: relative;
  width: 300px;
  border: 1px solid #0466db;
  border-radius: 10px;
  background: linear-gradient(rgba(149, 154, 162, 255) 50%, transparent);
  background-color: #dcdee0;
  margin: 15px;
  z-index: 2;
}

div.container-card .titulo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0466db;
  border-radius: 9px 9px 0 0;
  text-align: center;
  padding: 10px 0;
  color: white;
}

div.container-card .titulo .icone-titulo {
  height: 30px;
  width: 30px;
  color: black;
  margin-right: 15px;
  margin-left: -45px;
}

div.container-card .separador {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px;
}

div.container-card .separador hr {
  flex: 1;
  background-color: #333;
  border: 1px solid #333;
}

div.container-card .separador p {
  margin: 0px 10px;
  font-weight: bold;
  font-size: 18px;
}

div.container-card .descricao-inclusao {
  margin: 0 30px;
}

div.container-card .descricao-inclusao div {
  display: flex;
  align-items: center;
  margin: 25px 0;
}

div.container-card .descricao-inclusao div .icone-checkbox {
  height: 20px;
  width: 20px;
  color: #0466db;
  margin-right: 5px;
}

div.container-card .preco {
  text-align: center;
  margin: 25px 0;
}

div.container-card .preco h1 {
  color: #0466db;
}

div.container-card .preco p {
  color: #00244d;
}

div.container-card .preco p.msg-continuar-gratis {
  color: red;
  font-weight: bold;
  margin-top: 5px;
}

div.container-card .botao {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

div.container-card .botao button {
  padding: 12px 45px;
  border-radius: 5px;
  border: none;
  background-color: #00244d;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.alert-sucesso-assinar-plano {
  animation-duration: 0.2s;
}

.alert-sucesso-assinar-plano .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-assinar-plano button {
  font-size: 14px;
  font-weight: bold;
  background-color: #0466db;
}