div.container-erro-login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
}

div.container-erro-login img.background-1 {
  height: 350px;
  width: 350px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  top: 8%;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

div.container-erro-login img.background-2 {
  height: 500px;
  width: 500px;
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  bottom: 0px;
  right: 0px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811);
}

div.container-erro-login img.logo-whatsapp {
  position: fixed;
  bottom: 30px;
  right: 3%;
  height: 60px;
  width: 60px;
  z-index: 5;
}

div.container-erro-login .quadro-erro {
  position: relative;
  z-index: 1;
  color: white;
  width: 50%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

div.container-erro-login .quadro-erro .detalhes-plano {
  width: 300px;
  border: 1px solid #0466db;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 10px;
}

div.container-erro-login .quadro-erro .detalhes-plano .titulo {
  font-weight: bold;
  font-size: 20px;
  margin: -1px -1px 5px 0;
  padding: 8px 0;
  background-color: #0466db;
  border-radius: 10px 10px 0 0;
}

div.container-erro-login .quadro-erro .detalhes-plano .subtitulo {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0 5px 0;
}

div.container-erro-login .quadro-erro .detalhes-plano .itens {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.container-erro-login .quadro-erro .detalhes-plano .itens .icone-checkbox {
  height: 18px;
  width: 18px;
  color: #0466db;
  margin-right: 5px;
}

div.container-erro-login .quadro-erro div h1 {
  text-align: center;
}

div.container-erro-login .quadro-erro div .mensagem {
  margin: 20px 0 30px 0;
  text-align: center;
}

div.container-erro-login .quadro-erro div .botoes {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}

div.container-erro-login .quadro-erro div .botoes button {
  width: 180px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

div.container-erro-login .quadro-erro div .botoes button:hover {
  filter: brightness(140%);
}

@media (max-width: 830px) {
  div.container-erro-login .quadro-erro {
    width: 98%;
  }
}

@media (max-width: 690px) {
  div.container-erro-login img.background-1 {
    height: 400px;
    width: 400px;
  }
  div.container-erro-login img.background-2 {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 420px) {
  div.container-erro-login .quadro-erro div .botoes {
    flex-direction: column;
  }
  
  div.container-erro-login .quadro-erro div .botoes button {
    width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 400px) {
  div.container-erro-login img.background-1 {
    height: 320px;
    width: 320px;
  }
  div.container-erro-login img.background-2 {
    height: 200px;
    width: 200px;
  }
  div.container-erro-login img.logo-whatsapp {
    bottom: 10px;
  }
}