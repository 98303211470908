div.container-pagina-cursos {
  background: linear-gradient(rgba(0, 15, 32, 255) 50%, transparent);
  background-color: #000f20;
  color: white;
}

div.container-pagina-cursos img.logo-whatsapp {
  bottom: 40px;
}

div.container-pagina-cursos img.background {
  height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 50%;
  top: 60;
  left: -20px;
  opacity: 0.05;
  background: linear-gradient( to right, #1d8aa7 40%, #000811 );
}

div.container-pagina-cursos .container-titulo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 8%;
  background-color: #1c2732;
}

div.container-pagina-cursos .container-titulo .btn-search {
  display: inline-flex;
  align-items: center;
  background-color: white;
  text-decoration: none;
  border-radius: 80px;
  height: 50px;
  overflow: hidden;
  width: auto;
  max-width: 50px;
  cursor: pointer;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

div.container-pagina-cursos .container-titulo .btn-search:hover, 
div.container-pagina-cursos .container-titulo .btn-search.open {
  max-width: 300px;
}

div.container-pagina-cursos .container-titulo .btn-search .icon {
  font-size: 30px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

div.container-pagina-cursos .container-titulo .btn-search .input-search {
  margin-right: 10px;
  border: none;
  border-bottom: 1px solid;
}

div.container-pagina-cursos .container-abas {
  position: relative;
  z-index: 2;
  background-color: #071b31;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 15px 8%;
}

div.container-pagina-cursos .container-abas .opcoes-abas {
  display: flex;
}

div.container-pagina-cursos .container-abas .opcoes-abas p {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

div.container-pagina-cursos .container-abas .opcoes-abas p:hover {
  text-decoration: underline;
}

div.container-pagina-cursos .container-abas .opcoes-abas p.aba-selecionada {
  color: #0466db;
}

div.container-pagina-cursos .container-abas .opcoes-abas hr {
  height: 20px;
  margin: 0 10px;
}

div.container-pagina-cursos .container-cursos {
  padding: 40px 8%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: auto;
  align-self: center;
  justify-items: center;
  min-height: calc(100vh - 585px);
}

div.container-pagina-cursos .container-trilhas-de-conhecimento h1 {
  padding: 0 8%;
  margin: 30px 0;
}

div.container-pagina-cursos .container-trilhas-de-conhecimento h1 i {
  color: #0466db;
  font-style: normal;
}

div.container-pagina-cursos .container-assine-agora-fixo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 8% 0 8%;
  background-color: black;
  color: #fff;
  padding: 30px 20px;
}

div.container-pagina-cursos .container-assine-agora-fixo p.preco {
  font-size: 18px;
}

div.container-pagina-cursos .container-assine-agora-fixo p.preco i {
  font-style: normal;
  color: #0466db;
}

div.container-pagina-cursos .container-assine-agora-fixo div {
  width: 60%;
}

div.container-pagina-cursos .container-assine-agora-fixo div p {
  font-size: 18px;
  font-weight: bold;
}

div.container-pagina-cursos .container-assine-agora-fixo button {
  padding: 12px 25px;
  border-radius: 5px;
  border: 1px solid #fff;
  background-color: #0d78f2;
  font-size: 16px;
  font-weight: bold;
}

div.container-pagina-cursos .container-assine-agora-fixo button a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-pagina-cursos .container-assine-agora-fixo button:hover {
  filter: brightness(140%);
}

@media (max-width: 1100px) {
  div.container-pagina-cursos .container-cursos {
    padding: 40px 10px;
  }
}

@media (max-width: 980px) {
  div.container-pagina-cursos .container-titulo {
    padding: 50px 10px;
  }
  div.container-pagina-cursos .container-abas {
    padding: 15px 10px;
  }
}

@media (max-width: 860px) {
  div.container-pagina-cursos .container-assine-agora-fixo {
    flex-direction: column;
    text-align: center;
  }
  div.container-pagina-cursos .container-assine-agora-fixo p.preco {
    margin: 15px;
  }
  div.container-pagina-cursos .container-assine-agora-fixo div {
    width: 100%;
  }
}

@media (max-width: 960px) {
  div.container-pagina-cursos .container-cursos {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 645px) {
  div.container-pagina-cursos .container-titulo p {
    width: 80%;
  }
  div.container-pagina-cursos .container-cursos {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  div.container-pagina-cursos img.background {
    height: 350px;
    width: 350px;
  }
}