div.container-central-ajuda .container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 8%;
  background-color: #1c2732;
}

div.container-central-ajuda .container-titulo .titulo {
  display: flex;
  align-items: center;
}

div.container-central-ajuda .container-titulo .titulo .icone-titulo {
  height: 45px;
  width: 45px;
  margin-right: 10px;
}

div.container-central-ajuda .container-titulo .titulo p {
  font-size: 26px;
  font-weight: bold;
}

div.container-central-ajuda .container-titulo .subtitulo {
  font-size: 16px;
}

div.container-central-ajuda .container-informacoes {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 8%;
  z-index: 5;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes {
  width: 70%;
  margin-top: 50px;
  color: white;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes h1 {
  margin-bottom: 50px;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes i {
  color: #0466db;
  font-style: normal;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes .accordion-perguntas-frequetes {
  padding: 0;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes .accordion-perguntas-frequetes h2 {
  color: #b7ceed;
}

div.container-central-ajuda .container-informacoes .container-perguntas-frequentes .accordion-perguntas-frequetes h2:not(:first-child) {
  margin-top: 30px;
}

div.container-central-ajuda .container-informacoes .container-contato {
  margin-left: 10px;
}

div.container-central-ajuda .container-informacoes .container-contato a {
  text-decoration: none;
  color: #fff;
}

div.container-central-ajuda .container-informacoes .container-contato a:hover {
  text-decoration: underline;
}

div.container-central-ajuda .container-informacoes .container-contato .botao-contato div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8% 0;
  height: 150px;
  width: 250px;
  margin: 60px 0;
  border: 3px solid #0466db;
  border-radius: 10px;
}

div.container-central-ajuda .container-informacoes .container-contato .botao-contato div .logo {
  height: 60px;
  width: 60px;
}

div.container-central-ajuda .container-informacoes .container-contato .botao-contato div p {
  font-weight: bold;
  font-size: 22px;
}

@media (max-width: 980px) {
  div.container-central-ajuda .container-titulo {
    padding: 50px 10px;
  }
  div.container-central-ajuda .container-informacoes {
    padding: 0 10px;
  }
  div.container-central-ajuda .container-informacoes .container-perguntas-frequentes {
    text-align: center;
  } 
}

@media (max-width: 800px) {
  div.container-central-ajuda .container-informacoes {
    flex-direction: column;
    align-items: center;
  }
  div.container-central-ajuda .container-informacoes .container-perguntas-frequentes {
    width: 100%;
  }
  div.container-central-ajuda .container-informacoes .container-contato {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-left: 0;
  }
}

@media (max-width: 645px) {
  div.container-central-ajuda .container-titulo p {
    width: 80%;
  }
}

@media (max-width: 550px) {
  div.container-central-ajuda .container-informacoes .container-contato{
    flex-direction: column;
    align-items: center;
  }
  div.container-central-ajuda .container-informacoes .container-contato .botao-contato div {
    margin: 20px 0;
  }
}