div.container-sucesso-cadastro {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  color: white;
  text-align: center;
  width: 40%;
  margin: auto;
  padding: 20px 15px;
  border: 1px solid #0466db;
  border-radius: 15px;
  background-color: #071b31;
}

div.container-sucesso-cadastro img {
  height: 100px;
  width: 100px;
  margin: 30px 0;
}

div.container-sucesso-cadastro p {
  font-size: 18px;
  width: 70%;
}

div.container-sucesso-cadastro p.aviso-participante {
  margin: 20px 0;
}

div.container-sucesso-cadastro p.aviso-participante i {
  font-style: normal;
  color: #ff0000;
}

div.container-sucesso-cadastro p.aviso-participante a {
  color:#0466db;
  text-decoration: underline;
}

div.container-sucesso-cadastro a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}

div.container-sucesso-cadastro a button {
  /* width: 70%; */
  width: 200px;
  margin: 70px 0 30px;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0d78f2;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 950px) {
  div.container-sucesso-cadastro {
    width: 70%;
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  div.container-sucesso-cadastro {
    width: 98%;
  }
  div.container-sucesso-cadastro p, div.container-sucesso-cadastro button {
    width: 90%;
  }
}