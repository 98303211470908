@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}