div.accordion-assistir-aulas {
  margin-bottom: 10px;
  text-align: start;
}

div.accordion-assistir-aulas .accordion-titulo {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  background-color: #0466db;
  padding: 5px 25px;
  border-radius: 10px;
  z-index: 2;
}

div.accordion-assistir-aulas .accordion-titulo:hover {
  filter: brightness(140%);
}

div.accordion-assistir-aulas .accordion-titulo .icone-accordion {
  height: 40px;
  width: 40px;
  color: #fff;
}

div.accordion-assistir-aulas .accordion-titulo p {
  font-weight: bold;
  color: #fff;
  font-size: 16px;
}

div.accordion-assistir-aulas .accordion-conteudo {
  padding: 10px 20px;
  background-color: #052c58;
  margin-top: -5px;
  border-radius: 0 0 10px 10px;
}

div.accordion-assistir-aulas .accordion-conteudo hr {
  color: gray;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0;
  padding: 0 20px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento {
  align-self: flex-end;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento.incompleto {
  visibility: hidden;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento .icone-andamento {
  height: 20px;
  width: 20px;
  margin-right: 3px;
  color: greenyellow;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .andamento p {
  color: #0466db;
  font-weight: bold;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula {
  display: flex;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video {
  position: relative;
  overflow: hidden;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .vimeo {
  height: 100%;
  width: 280px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .vimeo .player {
  height: 100%;
  width: 100%;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .vimeo .player iframe {
  height: 100%;
  width: 100%;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .status-aula {
  position: absolute;
  bottom: 80px;
  left: 100px;
  width: 250px;
  padding: 5px 0;
  background-color: rgba(0, 15, 32, 0.900);
  transform: rotate(45deg);
  font-weight: bold;
  font-size: 16px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .status-aula p {
  color: green;
  text-align: center;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento {
  display: flex;
}

div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento button,
div.accordion-assistir-aulas-pos .accordion-conteudo .container-aula .container-documento a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rebeccapurple;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: 110px;
  cursor: pointer;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-documento:hover {
  filter: brightness(140%);
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-documento .icone-documento {
  height: 50px;
  width: 50px;
  color: #fff;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-pdf {
  display: flex;
  margin-left: 110px;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-pdf .icone-pdf {
  height: 70px;
  width: 70px;
  color: red;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula {
  width: 50%;
  text-align: center;
}

div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula .descricao {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-artigo {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  overflow: auto;
  z-index: 99;
}

.modal-artigo .fechar-modal {
  position: fixed;
  right: 60px;
}

.modal-artigo .fechar-modal button {
  height: 20px;
  width: 20px;
  border: none;
  background-color: #fff;
  color: gray;
}

.modal-artigo .fechar-modal button:hover {
  color: black;
}

.modal-artigo .titulo {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
}

.modal-artigo .conteudo-modal {
  margin-top: 40px;
}

.modal-artigo::-webkit-scrollbar {
  width: 10px;
}

.modal-artigo::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey; 
}

.modal-artigo::-webkit-scrollbar-thumb {
  background: #3f4c5a; 
}

.modal-artigo::-webkit-scrollbar-thumb:hover {
  background: #343c46; 
}

.overlay-modal-artigo {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.596);
  z-index: 99;
}

@media (max-width: 990px) {
  div.accordion-assistir-aulas .accordion-conteudo {
    padding: 5px 0;
  }
}

@media (max-width: 845px) {
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula {
    flex-direction: column;
    align-items: center;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .andamento {
    align-self: auto;
    margin-left: 0;
    margin-top: 5px;
  }
}

@media (max-width: 750px) {
  .modal-artigo {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }
}

@media (max-width: 715px) {
  div.accordion-assistir-aulas .accordion-conteudo .container-aula {
    flex-direction: column;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula {
    width: 100%;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-conteudo-aula p:first-child {
    margin: 10px 0;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-documento, 
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-pdf {
    margin-left: 0;
  }
}

@media (max-width: 350px) {
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video {
    height: 170px;
    width: 250px;
  }
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .player {
    height: 170px;
    width: 250px;
  } 
  div.accordion-assistir-aulas .accordion-conteudo .container-aula .container-video-aula .video .status-aula {
    bottom: 90px;
    left: 50px;
  }
}