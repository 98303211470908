div.container-dados-aluno {
  position: relative;
  width: 60%;
  margin: 50px auto;
  border: 1px solid gray;
  border-radius: 20px;
  background-color: #071b31;
  z-index: 1;
}

div.container-dados-aluno .container-topo {
  display: flex;
  align-items: center;
  height: 90px;
  background-color: #0466db;
  border-radius: 19px;
  padding: 0 20px;
}

div.container-dados-aluno .container-topo p {
  font-size: 24px;
  font-weight: bold;
}

div.container-dados-aluno form {
  padding: 0 40px;
}

div.container-dados-aluno form hr {
  margin: 20px 0;
  border-color: #436a9f;
}

div.container-dados-aluno form button {
  display: block;
  margin: 30px auto;
  width: 45%;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #0466db;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

div.container-dados-aluno form button:last-of-type {
  margin: -10px auto 20px auto;
}

div.container-dados-aluno form button:hover {
  filter: brightness(140%);
}

div.container-dados-aluno form label {
  width: 100%;
}

div.container-dados-aluno form label span {
  color: red;
}

div.container-dados-aluno form label input, div.container-dados-aluno form label select {
  width: 100%;
  padding: 15px 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  margin-top: 3px;
}

div.container-dados-aluno form label input::placeholder {
  color: #8a8a8a;
  font-style: italic;
}

div.container-dados-aluno form label input[type="date"] { 
  padding: 13px 10px;
}

div.container-dados-aluno form label input.senha-check {
  background-color: rgb(184, 255, 228);
}

div.container-dados-aluno form .titulo {
  color: #436a9f;
  font-weight: bold;
  font-size: 16px;
  margin: 30px 0;
}

div.container-dados-aluno form .col-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
}

div.container-dados-aluno form .col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10%;
  grid-row-gap: 20px;
  justify-content: space-between;
}

div.container-dados-aluno form .col-2:last-of-type {
  margin-top: 20px;
}

div.container-dados-aluno form .col-1 .label-linkedin .input-linkedin {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  margin-top: 3px;
}

div.container-dados-aluno form .col-1 .label-linkedin .input-linkedin input {
  flex: 1;
  margin-top: 0;
}

div.container-dados-aluno form .col-1 .label-linkedin .input-linkedin .icone-linkedin {
  height: 35px;
  width: 35px;
  color: #0466db;
}

div.container-dados-aluno form .col-1 .label-linkedin span {
  font-size: 10px;
  font-style: italic;
  color: #8a8a8a;
}

div.container-dados-aluno form .col-1 .label-linkedin span i {
  font-style: normal;
  font-weight: bold;
  color: #0466db;
}

div.container-dados-aluno form .col-1 label.input-file {
  cursor: pointer;
  width: 220px;
  margin-bottom: 20px;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil {
  display: flex;
  align-items: center;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil .foto {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  background-color: white;
  border: 2px solid #0466db;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil .foto .icone-usuario {
  height: 60px;
  width: 60px;
  color: black;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil p {
  margin-left: 10px;
  color: #8a8a8a;
  font-style: italic;
  cursor: pointer;
}

div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil p:hover {
  text-decoration: underline;
}

div.container-dados-aluno form .col-1 label.input-file input {
  display: none;
}

.alert-sucesso-salvar-dados, .alert-erro-salvar-dados {
  animation-duration: 0.2s;
}

.alert-sucesso-salvar-dados .react-confirm-alert-body, .alert-erro-salvar-dados .react-confirm-alert-body {
  color: #1c2732;
}

.alert-sucesso-salvar-dados button, .alert-erro-salvar-dados button {
  font-size: 14px;
  font-weight: bold;
}

.alert-sucesso-salvar-dados button.botao-confirmar {
  background-color: #cc3300;
}

.alert-sucesso-salvar-dados button.botao-sucesso, .alert-erro-salvar-dados button.botao-erro {
  background-color: #0466db;
}

@media (max-width: 940px) {
  div.container-dados-aluno {
    width: 98%;
  }
  div.container-dados-aluno form .col-1, div.container-dados-aluno form .col-2 {
    grid-column-gap: 3%;
  }
}

@media (max-width: 700px) {
  div.container-dados-aluno form .col-1, div.container-dados-aluno form .col-2 {
    grid-template-columns: 1fr;
  }
  div.container-dados-aluno form button {
    width: 80%;
  }
}

@media (max-width: 370px) {
  div.container-dados-aluno .container-topo {
    padding: 0 10px;
  }
  div.container-dados-aluno form {
    padding: 0 10px;
  }
  div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil {
    flex-direction: column;
  }
  div.container-dados-aluno form .col-1 label.input-file .container-foto-perfil p {
    margin: 10px 0 0 0;
  }
  div.container-dados-aluno form label input[type="date"] { 
    padding: 13px 0px 13px 10px;
  } 
}